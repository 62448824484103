import React, { useCallback, useMemo } from 'react';
import { styled, typography } from 'utils/styles';
import { LayoutProps } from 'styled-system';
import {
	Select,
	components,
	OptionProps,
	SingleValueProps,
	Props,
	SelectOption,
	SelectGroupedOption
} from './Select';
import { useTranslation } from 'utils/i18next';
import { Currency, CurrencyCodes } from 'types';
import { currencyCodes, currencyCodesG10, disabledCurrencies, metalCodes } from 'common/currencies';
import { CurrencyCode, CurrencySymbol, Flex } from 'components/common';
import { YesThick } from 'components/icons';
import { includes, toPairs } from 'lodash';

type IS_MULTI = false;
type CurrencySelectType = 'ccyFrom' | 'ccyTo';

interface CurrencySelectProps extends Omit<Props<SelectOption<Currency>, IS_MULTI>, 'value'> {
	type?: CurrencySelectType;
	value: Currency | null;
	excludeValue: Currency | null;
}

interface CurrencySelectOptionProps extends LayoutProps {
	code: Currency;
	label: string;
}

const StyledCurrency = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	${typography.s5}
`;

const CurrencySelectOption = ({ code, label, ...layout }: CurrencySelectOptionProps) => (
	<StyledCurrency>
		<CurrencySymbol ccyCode={code} marginRight="4px" {...layout} />
		<CurrencyCode>{code}</CurrencyCode>
		<span>{` - ${label}`}</span>
	</StyledCurrency>
);

const Option = (props: OptionProps<SelectOption<Currency>, IS_MULTI>) => (
	<components.Option {...props}>
		<Flex gridGap="large" justifyContent="space-between" alignItems="center">
			<CurrencySelectOption code={props.data.value} label={props.data.label} />
			{props.isSelected && <YesThick size="small" />}
		</Flex>
	</components.Option>
);

const SingleValue = (props: SingleValueProps<SelectOption<Currency>, IS_MULTI>) => (
	<components.SingleValue {...props}>
		<CurrencySelectOption code={props.data.value} label={props.data.label} width="auto" />
	</components.SingleValue>
);

const disableAllOptions = (option: SelectOption<Currency>) => ({ ...option, isDisabled: true });
const disableSelectedOption =
	(selected: Currency, type?: CurrencySelectType) => (option: SelectOption<Currency>) => {
		if (type) {
			const disabled =
				type === 'ccyTo'
					? disabledCurrencies[selected]
					: toPairs(disabledCurrencies)
							.map(([ccy, disabled]) => (disabled.includes(selected) ? ccy : null))
							.filter(v => v);

			if (disabled) {
				disabled.push(selected);

				return disabled.includes(option.value) ? { ...option, isDisabled: true } : option;
			}
		}

		return option.value === selected ? { ...option, isDisabled: true } : option;
	};

const disableOptions = (options: Currency[]) => (option: SelectOption<Currency>) =>
	options.includes(option.value) ? { ...option, isDisabled: true } : option;

export const CurrencySelect = React.memo(function CurrencySelect(props: CurrencySelectProps) {
	const { value, excludeValue, type, ...selectProps } = props;
	const { t } = useTranslation();

	const createCurrencyOptions = useCallback(
		(ccys: CurrencyCodes) =>
			ccys.map(ccy => ({ value: ccy, label: t(ccy, { ns: 'currencies' }) })),
		[t]
	);

	const { currencyOptionsG10, currencyOptions, metalOptions } = useMemo(
		() => ({
			currencyOptionsG10: createCurrencyOptions(currencyCodesG10),
			currencyOptions: createCurrencyOptions(currencyCodes),
			metalOptions: createCurrencyOptions(metalCodes)
		}),
		[createCurrencyOptions]
	);

	const getQuickListOptions = useCallback(
		(selected: Currency | null) => {
			if (selected) {
				return currencyOptionsG10.map(disableSelectedOption(selected, type));
			}

			if (selected === 'XAU' || selected === 'XAG') {
				return currencyOptionsG10.map(disableOptions(['NOK', 'SEK', 'HKD', 'INR', 'CNH']));
			} else if (selected === 'XPD' || selected === 'XPT') {
				return currencyOptionsG10.map(
					disableOptions(['JPY', 'NOK', 'SEK', 'NZD', 'HKD', 'INR', 'CNH'])
				);
			}

			return currencyOptionsG10;
		},
		[currencyOptionsG10, type]
	);

	const getAllCurrenciesOptions = useCallback(
		(selected: Currency | null) => {
			if (selected && includes(metalCodes, selected)) {
				return currencyOptions.map(disableAllOptions);
			} else if (selected) {
				return currencyOptions.map(disableSelectedOption(selected, type));
			}

			return currencyOptions;
		},
		[currencyOptions, type]
	);

	const getMetalsOptions = useCallback(
		(selected: Currency | null) => {
			if (selected && (includes(currencyCodes, selected) || includes(metalCodes, selected))) {
				return metalOptions.map(disableAllOptions);
			}

			return metalOptions;
		},
		[metalOptions]
	);

	const getSelectedCurrency = useCallback(
		(selected: Currency | null): SelectOption<Currency> | undefined =>
			selected
				? [...currencyOptionsG10, ...currencyOptions, ...metalOptions].find(
						({ value }) => value === selected
					)
				: undefined,
		[currencyOptions, currencyOptionsG10, metalOptions]
	);

	const getCurrencies = useCallback(
		(selected: Currency | null): SelectGroupedOption<Currency>[] => [
			{
				label: t('labels.quickList'),
				options: getQuickListOptions(selected)
			},
			{
				label: t('labels.allCurrencies'),
				options: getAllCurrenciesOptions(selected)
			},
			{
				label: t('labels.metals'),
				options: getMetalsOptions(selected)
			}
		],
		[getAllCurrenciesOptions, getMetalsOptions, getQuickListOptions, t]
	);

	return (
		<Select<SelectOption<Currency>, IS_MULTI>
			{...selectProps}
			value={getSelectedCurrency(value)}
			options={getCurrencies(excludeValue)}
			components={{ Option, SingleValue }}
		/>
	);
});
