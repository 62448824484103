import facepaint from 'facepaint';
import { css, keyframes } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

export { default as styled } from '@emotion/styled';
export { default as emotionNormalize } from 'emotion-normalize';
export * from '@emotion/react';
export * from 'emotion-rgba';

export const breakpoints = ['480px', '760px', '960px', '1120px'];
export const mq = facepaint(breakpoints.map(bp => `@media (min-width: ${bp})`));

const colors = {
	redHSBC: '#DB0011',
	red1: '#E31E22',
	red2: '#BA1110',
	red3: '#730014',
	grey1: '#F3F3F3',
	grey2: '#EDEDED',
	grey3: '#D7D8D6',
	grey4: '#767676',
	grey5: '#333333',
	black: '#000000',
	white: '#FFFFFF',
	typography: '#333333',
	background: {
		white: '#FFFFFF',
		grey: '#F3F3F3'
	},
	data: {
		blue1: '#2D6980',
		blue2: '#347893',
		blue3: '#3D8EAE',
		blue4: '#469CBE',
		green1: '#3E701A',
		green2: '#4C7C27',
		green3: '#5D9438',
		green4: '#739F56'
	},
	rag: {
		red: '#A8000B',
		redTint: '#F9F2F3',
		amber: '#FFBB33',
		amberTint: '#FFF8EA',
		green: '#00847F',
		greenTing: '#E5F2F2',
		blue: '#305A85',
		blueTint: '#EBEFF4'
	}
};

const notifications = {
	error: {
		color: colors.white,
		backgroundColor: colors.rag.red,
		'svg, button svg': {
			fill: `${colors.white}`
		}
	},
	warning: {
		color: colors.grey5,
		backgroundColor: colors.rag.amber,
		'svg, button svg': {
			fill: `${colors.grey5}`
		}
	},
	success: {
		color: colors.white,
		backgroundColor: colors.rag.green,
		'svg, button svg': {
			fill: `${colors.white}`
		}
	},
	info: {
		color: colors.white,
		backgroundColor: colors.rag.blue,
		'svg, button svg': {
			fill: `${colors.white}`
		}
	}
};

export const typography = {
	s1: mq({
		fontSize: ['2.063rem', null, null, '2.750rem'],
		fontWeight: 300,
		lineHeight: [1.2, null, null, 1.15]
	}),
	s2: mq({
		fontSize: ['1.75rem', null, null, '2.188rem'],
		fontWeight: 300,
		lineHeight: [1.3, null, null, 1.2]
	}),
	s3: mq({
		fontSize: ['1.438rem', null, null, '1.750rem'],
		fontWeight: 300,
		lineHeight: 1.3
	}),
	s4: mq({
		fontSize: ['1.188rem', null, null, '1.375rem'],
		fontWeight: 300,
		lineHeight: 1.4
	}),
	s5: mq({
		fontSize: '1rem',
		fontWeight: 400,
		lineHeight: 1.5
	}),
	s6: mq({
		fontSize: '0.875rem',
		fontWeight: 400,
		lineHeight: 1.4
	}),
	s7: mq({
		fontSize: '0.75rem',
		fontWeight: 400,
		lineHeight: 1.3
	})
};

export type ThemeScale<Type, Aliases extends string> = Array<Type> & Record<Aliases, Type>;
export type BreakpointAlias = 'mobile' | 'tablet' | 'desktop';
export type SpaceAlias = 'none' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
export type SizesAlias = 'none' | 'small' | 'medium' | 'large';
export type FontSizeAlias = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
export type LineHeightAlias = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
export type FontWeightAlias = 'light' | 'normal' | 'bold';

export interface HSBCTheme {
	breakpoints: ThemeScale<string, BreakpointAlias>;
	space: ThemeScale<string, SpaceAlias>;
	sizes: ThemeScale<string, SizesAlias>;
	fontSize: ThemeScale<string, FontSizeAlias>;
	lineHeight: ThemeScale<number, LineHeightAlias>;
	fontWeight: ThemeScale<number, FontWeightAlias>;
	colors: typeof colors;
	notifications: typeof notifications;
}

export const hsbcTheme = {
	colors,
	notifications
} as HSBCTheme;

// ALIASES
hsbcTheme.space = [0, '4px', '8px', '12px', '18px', '24px', '32px'] as ThemeScale<
	string,
	SpaceAlias
>;
hsbcTheme.space.none = hsbcTheme.space[0];
hsbcTheme.space.xsmall = hsbcTheme.space[1];
hsbcTheme.space.small = hsbcTheme.space[2];
hsbcTheme.space.medium = hsbcTheme.space[3];
hsbcTheme.space.large = hsbcTheme.space[4];
hsbcTheme.space.xlarge = hsbcTheme.space[5];
hsbcTheme.space.xxlarge = hsbcTheme.space[6];

hsbcTheme.sizes = [0, '8px', '14px', '18px'] as ThemeScale<string, SizesAlias>;
hsbcTheme.sizes.none = hsbcTheme.sizes[0];
hsbcTheme.sizes.small = hsbcTheme.sizes[1];
hsbcTheme.sizes.medium = hsbcTheme.sizes[2];
hsbcTheme.sizes.large = hsbcTheme.sizes[3];

hsbcTheme.fontSize = ['0.75rem', '0.875rem', '1rem', '1.75rem', '2rem'] as ThemeScale<
	string,
	FontSizeAlias
>;
hsbcTheme.fontSize.xsmall = hsbcTheme.fontSize[0];
hsbcTheme.fontSize.small = hsbcTheme.fontSize[1];
hsbcTheme.fontSize.medium = hsbcTheme.fontSize[2];
hsbcTheme.fontSize.large = hsbcTheme.fontSize[3];
hsbcTheme.fontSize.xlarge = hsbcTheme.fontSize[4];

hsbcTheme.lineHeight = [1.3, 1.4, 1.5, 1.3, 1.2] as ThemeScale<number, LineHeightAlias>;
hsbcTheme.lineHeight.xsmall = hsbcTheme.lineHeight[0];
hsbcTheme.lineHeight.small = hsbcTheme.lineHeight[1];
hsbcTheme.lineHeight.medium = hsbcTheme.lineHeight[2];
hsbcTheme.lineHeight.large = hsbcTheme.lineHeight[3];
hsbcTheme.lineHeight.xlarge = hsbcTheme.lineHeight[4];

hsbcTheme.fontWeight = [300, 400, 700] as ThemeScale<number, FontWeightAlias>;
hsbcTheme.fontWeight.light = hsbcTheme.fontWeight[0];
hsbcTheme.fontWeight.normal = hsbcTheme.fontWeight[1];
hsbcTheme.fontWeight.bold = hsbcTheme.fontWeight[2];

hsbcTheme.breakpoints = breakpoints as ThemeScale<string, BreakpointAlias>;

declare module '@emotion/react' {
	interface Theme extends HSBCTheme {}
}

export const visuallyHidden = css`
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
`;

export const globalStyle = css`
	${emotionNormalize}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	@font-face {
		font-family: UniversNext;
		font-weight: ${hsbcTheme.fontWeight.light};
		src:
			url('/assets/fonts/universnextforhsbc-light.woff') format('woff'),
			url('/currency-zone/assets/fonts/universnextforhsbc-light.woff') format('woff'),
			url('/assets/fonts/universnextforhsbc-light.ttf') format('truetype'),
			url('/currency-zone/assets/fonts/universnextforhsbc-light.ttf') format('truetype');
	}

	@font-face {
		font-family: UniversNext;
		font-weight: ${hsbcTheme.fontWeight.normal};
		src:
			url('/assets/fonts/universnextforhsbc-regular.woff') format('woff'),
			url('/currency-zone/assets/fonts/universnextforhsbc-regular.woff') format('woff'),
			url('/assets/fonts/universnextforhsbc-regular.ttf') format('truetype'),
			url('/currency-zone/assets/fonts/universnextforhsbc-regular.ttf') format('truetype');
	}

	@font-face {
		font-family: UniversNext;
		font-weight: ${hsbcTheme.fontWeight.bold};
		src:
			url('/assets/fonts/universnextforhsbc-medium.woff') format('woff'),
			url('/currency-zone/assets/fonts/universnextforhsbc-medium.woff') format('woff'),
			url('/assets/fonts/universnextforhsbc-medium.ttf') format('truetype'),
			url('/currency-zone/assets/fonts/universnextforhsbc-medium.ttf') format('truetype');
	}

	#root {
		height: 100vh;
		display: flex;
		flex-direction: column;
	}

	body {
		font-family: UniversNext, 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-size: ${hsbcTheme.fontSize.medium};
		font-weight: ${hsbcTheme.fontWeight.normal};
		line-height: ${hsbcTheme.lineHeight.medium};
		margin: 0;
		color: ${colors.typography};
		background-color: ${colors.background.white};

		button {
			font-family: UniversNext, 'Helvetica Neue', Helvetica, Arial, sans-serif;
			color: ${colors.typography};
		}

		input,
		textarea,
		select {
			font-family: inherit;
		}

		a:hover {
			text-decoration: underline;
		}

		p {
			margin: 0.75rem 0;
		}
	}
`;

export const animations = {
	flash: keyframes`
		50% {
			opacity: 0;
		}
	`
};

export const flashAnimation = ({ flash }: { flash: boolean }) => css`
	animation-duration: 300ms;
	animation-name: ${flash ? animations.flash : 'none'};
`;
