import React from 'react';
import { NavBarMenuItem } from 'types';
import { styled, typography } from 'utils/styles';
import { Flex } from 'components/common';

interface NavGroupMenuProps {
	menuItems: NavBarMenuItem[];
	activeMenuItem?: string;
	onClick: (id: string) => void;
}

const List = styled.ul`
	margin: 0;
	padding: 0;
`;

const ListItem = styled.li<{ active: boolean }>`
	display: block;
	margin: ${({ theme: { space } }) => space.large} 0;

	button {
		display: inline-block;
		border: none;
		text-align: left;
		background-color: transparent;
		padding: 0;
		cursor: pointer;
		color: ${({ active }) => (active ? 'white' : '#929292')};
		transition:
			transform 0.2s ease,
			color 0.2s ease;
		transform: ${({ active }) => `translate3d(${active ? '28px' : ''}, 0, 0)`};
		${typography.s4};
	}

	&:first-of-type {
		margin-top: 0;
	}

	&:hover button {
		transform: translate3d(28px, 0, 0);
	}
`;

export const NavGroupMenu = (props: NavGroupMenuProps) => {
	const { menuItems, activeMenuItem, onClick } = props;
	return (
		<Flex flex={1} justifyContent={['flex-start', 'flex-start', 'center']}>
			<List>
				{menuItems.map(({ title }) => (
					<ListItem
						key={title}
						active={activeMenuItem === title}
						onClick={() => onClick(title)}
					>
						<button>{title}</button>
					</ListItem>
				))}
			</List>
		</Flex>
	);
};
