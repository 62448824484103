import React from 'react';
import { SVG, SVGProps } from './SVG';

export const ChartIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M15.61,1.54,8.4,8.76,5.63,6,2.4,9.22v1.69L5.63,7.69,8.4,10.45l7.21-7.21L18,5.64V3.94ZM1.2,0H0V18H18V16.8H1.2Z" />
		</g>
	</SVG>
);
