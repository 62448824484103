import { Range } from 'types';
import { Language } from 'utils/i18next';

export const peggedCurrencies = [
	'VNDUSD',
	'BSDUSD',
	'USDBSD',
	'BHDUSD',
	'USDBHD',
	'BBDUSD',
	'USDBBD',
	'BZDUSD',
	'USDBZD',
	'BMDUSD',
	'USDBMD',
	'BNDSGD',
	'SGDBND',
	'BGNEUR',
	'EURBGN',
	'XCDUSD',
	'USDXCD',
	'KYDUSD',
	'USDKYD',
	'XOFEUR',
	'EURXOF',
	'XAFEUR',
	'EURXAF',
	'HRKEUR',
	'EURHRK',
	'DKKEUR',
	'EURDKK',
	'XCDUSD',
	'USDXCD',
	'HKDUSD',
	'USDHKD',
	'JODUSD',
	'USDJOD',
	'LSLZAR',
	'ZARLSL',
	'MOPHKD',
	'HKDMOP',
	'NADZAR',
	'ZARNAD',
	'OMRUSD',
	'USDOMR',
	'QARUSD',
	'USDQAR',
	'SARUSD',
	'USDSAR',
	'SZLZAR',
	'ZARSZL',
	'AEDUSD',
	'USDAED'
];

export const createChartLabel = (
	timestamp: number,
	range: Range,
	locale: string = Language.ENGLISH
): string => {
	const setTimeFormatting = (range: Range): Intl.DateTimeFormatOptions => {
		const options: Intl.DateTimeFormatOptions = {};
		if (range === Range.YEAR) {
			options.day = 'numeric';
			options.month = 'short';
		} else if (range === Range.TWO_YEARS) {
			options.month = 'short';
			options.year = 'numeric';
		} else if (range === Range.MONTH) {
			options.day = 'numeric';
			options.month = 'short';
		} else if (range === Range.WEEK) {
			options.day = 'numeric';
			options.month = 'short';
			options.hour = 'numeric';
			options.minute = 'numeric';
		} else if (range === Range.DAY) {
			options.hour = 'numeric';
			options.minute = 'numeric';
		}
		return options;
	};
	const date = new Date(timestamp);
	return date.toLocaleString(locale, setTimeFormatting(range));
};
