import React from 'react';
import { space, SpaceProps, typography, TypographyProps } from 'styled-system';
import { styled, css, rgba } from 'utils/styles';
import { StyledNotification } from './Notification';

type Size = 'small' | 'regular';
type Variant = 'primary' | 'secondary' | 'tertiary' | 'icon';

export interface ButtonProps
	extends React.HTMLAttributes<HTMLButtonElement>,
		SpaceProps,
		TypographyProps {
	className?: string;
	size?: Size;
	type?: 'button' | 'reset' | 'submit';
	variant?: Variant;
	disabled?: boolean;
}

type ColorState = {
	color: string;
	borderColor: string;
	backgroundColor: string;
};

type Colors = {
	[variant in Variant]: {
		default: ColorState;
		hover: ColorState;
		active: ColorState;
		disabled: ColorState;
	};
};

const colors: Colors = {
	primary: {
		default: {
			color: '#FFFFFF',
			borderColor: '#DB0011',
			backgroundColor: '#DB0011'
		},
		hover: {
			color: '#FFFFFF',
			borderColor: '#AF000D',
			backgroundColor: '#AF000D'
		},
		active: {
			color: '#FFFFFF',
			borderColor: '#83000A',
			backgroundColor: '#83000A'
		},
		disabled: {
			color: rgba('#333333', 0.3),
			borderColor: rgba('#333333', 0.15),
			backgroundColor: rgba('#333333', 0.15)
		}
	},
	secondary: {
		default: {
			color: '#FFFFFF',
			borderColor: '#000000',
			backgroundColor: '#000000'
		},
		hover: {
			color: '#FFFFFF',
			borderColor: '#404040',
			backgroundColor: '#404040'
		},
		active: {
			color: '#FFFFFF',
			borderColor: '#767676',
			backgroundColor: '#767676'
		},
		disabled: {
			color: rgba('#333333', 0.3),
			borderColor: rgba('#333333', 0.15),
			backgroundColor: rgba('#333333', 0.15)
		}
	},
	tertiary: {
		default: {
			color: '#333333',
			borderColor: '#333333',
			backgroundColor: 'transparent'
		},
		hover: {
			color: '#333333',
			borderColor: '#333333',
			backgroundColor: '#F3F3F3'
		},
		active: {
			color: '#333333',
			borderColor: '#333333',
			backgroundColor: '#EDEDED'
		},
		disabled: {
			color: rgba('#333333', 0.3),
			borderColor: rgba('#333333', 0.3),
			backgroundColor: 'none'
		}
	},
	icon: {
		default: {
			color: '#333333',
			borderColor: 'transparent',
			backgroundColor: 'transparent'
		},
		hover: {
			color: '#929292',
			borderColor: 'transparent',
			backgroundColor: 'transparent'
		},
		active: {
			color: '#929292',
			borderColor: 'transparent',
			backgroundColor: 'transparent'
		},
		disabled: {
			color: rgba('#333333', 0.3),
			borderColor: 'transparent',
			backgroundColor: 'transparent'
		}
	}
};

export const StyledButton = styled.button<ButtonProps>(
	({ theme, size = 'regular', variant = 'primary', color }) => css`
		display: inline-block;
		border-width: 1px;
		border-style: solid;
		white-space: nowrap;
		line-height: 1.5;
		padding: ${variant === 'icon' ? '8px' : size === 'regular' ? '11px 20px' : '7px 15px'};
		cursor: pointer;
		text-decoration: none;
		font-size: ${size === 'regular' ? theme.fontSize.medium : theme.fontSize.small};
		color: ${color || colors[variant].default.color};
		border-color: ${colors[variant].default.borderColor};
		background: ${colors[variant].default.backgroundColor};

		svg {
			fill: ${color || colors[variant].default.color};
		}

		&:hover {
			text-decoration: none;
			color: ${colors[variant].hover.color};
			border-color: ${colors[variant].hover.borderColor};
			background: ${colors[variant].hover.backgroundColor};

			svg {
				fill: ${colors[variant].hover.color};
			}
		}
		&:active {
			color: ${colors[variant].active.color};
			border-color: ${colors[variant].active.borderColor};
			background: ${colors[variant].active.backgroundColor};

			svg {
				fill: ${colors[variant].active.color};
			}
		}
		&:disabled {
			color: ${colors[variant].disabled.color};
			border-color: ${colors[variant].disabled.borderColor};
			background: ${colors[variant].disabled.backgroundColor};

			svg {
				fill: ${colors[variant].disabled.color};
			}
		}

		${StyledNotification} & {
			padding: 0;
			align-self: baseline;
		}
	`,
	space,
	typography
);

export const Button = ({ type = 'button', ...props }: ButtonProps) => (
	<StyledButton type={type} {...props} />
);
