import { ReactNode } from 'react';
import { useFeatureToggleEnv } from 'context/featureToggle';
import { FeatureToggleEnv } from 'types';

interface FeatureToggleProps {
	children: ReactNode;
	envs?: FeatureToggleEnv[];
	withDevEnvs?: boolean;
}

// dev environments enabled by default
const defaultEnvs: FeatureToggleEnv[] = ['currencyzone-internal-dev', 'currencyzone-external-dev'];

export const FeatureToggle = (props: FeatureToggleProps) => {
	const { envs = [], withDevEnvs = true } = props;
	const availableEnvs = withDevEnvs ? [...defaultEnvs, ...envs] : envs;
	const env = useFeatureToggleEnv();

	return availableEnvs.includes(env) ? props.children : null;
};
