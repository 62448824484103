import React from 'react';
import { FxTrendsCcy } from 'types';
import { useTranslation } from 'utils/i18next';
import { styled, mq, css } from 'utils/styles';

interface FxTrendsOverviewProps {
	ccy: FxTrendsCcy;
}

const OverviewContainer = styled.div(
	({ theme: { colors } }) => css`
		flex-direction: column;
		border-bottom: 1px solid ${colors.grey3};
	`,
	mq({
		display: ['flex', 'flex', 'flex', 'block']
	})
);

const Overview = styled.p`
	text-align: left;
`;

export const FxTrendsOverview = (props: FxTrendsOverviewProps) => {
	const { ccy } = props;
	const { t } = useTranslation('research');

	return (
		<OverviewContainer>
			<Overview data-testid="fx-trends-overview">{t(`fxTrends.overview.${ccy}`)}</Overview>
		</OverviewContainer>
	);
};
