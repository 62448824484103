import React from 'react';
import { css, styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';

const StyledBrief = styled.div`
	ul {
		padding: 0 18px;
	}
`;

const BriefHeading = styled.h5(
	({ theme: { fontSize, space } }) => css`
		font-size: ${fontSize.medium};
		margin: ${space.medium} 0;
	`
);

export const FxTrendsBrief = () => {
	const { t } = useTranslation('research');

	return (
		<StyledBrief>
			<BriefHeading>{t('fxTrends.brief.heading')}</BriefHeading>
			{t('fxTrends.brief.content')}
		</StyledBrief>
	);
};
