const logMetadata = {
	appName: 'Currency-zone',
	userSession: `cz-${Date.now()}-${Math.random()}`,
	timeStamp: Date.now()
};

const mergeData = message => ({
	...message,
	...logMetadata
});

const addMetadataToMessage = message => ({
	then: cb => {
		const log = () => cb(mergeData(message));
		log();
	}
});

// takes a logging function and ensures it's only called once metadata is available
const wrap = logger => message =>
	addMetadataToMessage(message).then(data => {
		logger(data);
	});

export default wrap;
