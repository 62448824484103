import { css, styled } from 'utils/styles';
import { flexbox, FlexboxProps } from 'styled-system';

export const ViewOptions = styled.fieldset<FlexboxProps>`
	display: flex;
	padding: 0;
	border: 1px solid ${({ theme: { colors } }) => colors.grey5};

	${flexbox};
`;

export const ViewOption = styled.button(
	({ theme: { colors, fontSize, fontWeight } }) => css`
		text-decoration: none;
		background: none;
		color: ${colors.grey5};
		text-align: center;
		font-weight: ${fontWeight.bold};
		font-size: ${fontSize.small};
		cursor: pointer;
		border: none;
		padding: 8px 20px;
		position: relative;
		overflow: hidden;
		flex: 1;
		white-space: nowrap;

		& + & {
			border-left: 1px solid ${colors.grey5};
		}

		&[aria-pressed='true']::after {
			content: '';
			position: absolute;
			bottom: -3px;
			left: 50%;
			width: 6px;
			height: 6px;
			background: ${colors.grey5};
			transform: rotate(45deg);
		}

		&[aria-pressed='false']:hover {
			background: ${colors.grey1};
		}

		&[aria-pressed='true'] {
			background: ${colors.grey2};
		}
	`
);
