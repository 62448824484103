import React from 'react';
import { SVG, SVGProps } from './SVG';

export const AlertIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M17.29,13.29,15,11V6.25A6.17,6.17,0,0,0,9.18,0H9A6,6,0,0,0,3,6v5L.71,13.29A1,1,0,0,0,1.41,15H6.56a2.73,2.73,0,0,0-.06.5,2.5,2.5,0,0,0,5,0,2.73,2.73,0,0,0-.06-.5h5.15A1,1,0,0,0,17.29,13.29Zm-7,2.21a1.3,1.3,0,0,1-2.6,0,1.19,1.19,0,0,1,.12-.5h2.36A1.19,1.19,0,0,1,10.3,15.5ZM1.9,13.8l2-2,.35-.35V6A4.8,4.8,0,0,1,9,1.2h.15A5,5,0,0,1,13.8,6.25V11.5l.35.35,2,2Z" />
		</g>
	</SVG>
);
