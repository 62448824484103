import React from 'react';
import { useField } from 'formik';
import { Field, Label, ErrorMessage, Input, InputProps } from 'components/form';

interface InputFieldProps extends InputProps {
	id: string;
	name: string;
	label: string;
	className?: string;
}

export const InputField = ({ id, label, className, ...props }: InputFieldProps) => {
	const [field, meta] = useField(props.name);
	const isError = !!(meta.touched && meta.error);

	return (
		<Field className={className}>
			<Label htmlFor={id}>{label}</Label>
			<Input id={id} {...field} {...props} isError={isError} />
			{isError && <ErrorMessage marginTop="xsmall">{meta.error}</ErrorMessage>}
		</Field>
	);
};
