import React, { ReactNode, useEffect, useState } from 'react';

interface ClientOnlyProps {
	children: ReactNode;
}

export const ClientOnly = ({ children }: ClientOnlyProps) => {
	const [hasMounted, setHasMounted] = useState<boolean>(false);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	if (!hasMounted) {
		return null;
	}

	return <>{children}</>;
};
