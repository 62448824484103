import React, { useEffect, useState } from 'react';
import { HeaderContext, HeaderRegion } from 'types';
import { mq, styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { SendEvent } from 'utils/logging/types';
import { Button, ButtonLink, Flex, Grid } from 'components/common';
import { CloseIcon } from 'components/icons';
import { useHeaderTranslation } from 'hooks';
import { NavGroupHighlight } from './NavGroupHighlight';
import { NavGroupMenu } from './NavGroupMenu';
import { NavGroupPromo } from './NavGroupPromo';
import { useNavGroupMenu } from './useNavGroupMenu';

interface NavGroupProps {
	context: HeaderContext;
	onClose: () => void;
	region: HeaderRegion;
	onRegionChange: (location: HeaderRegion) => void;
	sendEvent: SendEvent;
}

const NavGroupContainer = styled.div`
	position: absolute;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	left: 0;
	right: 0;
	top: 71px;
	background: #1e1e1e;
	color: #fff;
	z-index: 1000;

	${mq({
		height: ['calc(100vh - 68px)', 'auto']
	})}
`;

const NavGroupGrid = styled(Grid)`
	position: relative;
	margin: 0 auto;

	${mq({
		padding: ['16px 16px 48px', '18px', '18px', '18px 24px 24px']
	})};
`;

const NavGroupHeader = styled.div(({ theme: { space } }) =>
	mq({
		display: 'flex',
		marginBottom: [space.medium],
		justifyContent: ['space-between', null]
	})
);

const HighlightContainer = styled.div(({ theme: { colors } }) =>
	mq({
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		borderBottom: `1px solid ${colors.white}`,
		borderWidth: ['1px', null, 0]
	})
);

const NavGroupContent = styled.div(({ theme: { space } }) =>
	mq({
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: ['column', 'column', 'row'],
		gridGap: [space.large, null, space.small, space.xlarge, space.xxlarge]
	})
);

export const NavGroup = (props: NavGroupProps) => {
	const { context, onClose, region, onRegionChange, sendEvent } = props;
	const { t } = useTranslation();
	const tHeader = useHeaderTranslation(region);
	const [activeMenuItem, setActiveMenuItem] = useState<string>();
	const menuItems = useNavGroupMenu(context, region);

	useEffect(() => {
		if (menuItems[0]) {
			setActiveMenuItem(menuItems[0].title);
		}
	}, [menuItems]);

	return (
		<NavGroupContainer>
			<NavGroupGrid>
				<NavGroupHeader>
					<ButtonLink
						color="#929292"
						onClick={() => {
							onRegionChange('OTHER');
							onClose();
						}}
					>
						{tHeader('wrongRegion')}
					</ButtonLink>
					<Button
						variant="icon"
						onClick={onClose}
						color="white"
						aria-label={t('aria.close')}
					>
						<CloseIcon />
					</Button>
				</NavGroupHeader>
				<NavGroupContent>
					<HighlightContainer>
						<NavGroupHighlight
							region={region}
							context={context}
							sendEvent={sendEvent}
						/>
					</HighlightContainer>
					<Flex
						flex={2}
						flexDirection={['column', 'row']}
						gridGap={['large', 'large', 'small', 'xlarge', 'xxlarge']}
					>
						<NavGroupMenu
							menuItems={menuItems}
							activeMenuItem={activeMenuItem}
							onClick={setActiveMenuItem}
						/>
						<NavGroupPromo
							sendEvent={sendEvent}
							menuItem={menuItems.find(({ title }) => title === activeMenuItem)}
						/>
					</Flex>
				</NavGroupContent>
			</NavGroupGrid>
		</NavGroupContainer>
	);
};
