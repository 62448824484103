import React from 'react';
import { SVG, SVGProps } from './SVG';

export const AddIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<polygon points="18 8.4 9.6 8.4 9.6 0 8.4 0 8.4 8.4 0 8.4 0 9.6 8.4 9.6 8.4 18 9.6 18 9.6 9.6 18 9.6 18 8.4" />
		</g>
	</SVG>
);
