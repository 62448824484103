import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { isCurrencyZoneDomain } from 'utils/dom/window';
import { ExternalLink, Header, Page } from 'components/common';

export const TermsAndConditions = () => (
	<Page id={'page'}>
		<Helmet title="Terms and Conditions" />
		<Header>
			<h1>Terms and conditions</h1>
		</Header>
		<div>
			<p>
				This document sets out the Terms and Privacy Statement ('Terms') of{' '}
				<Link to={`/`}>
					{isCurrencyZoneDomain() ? 'currencyzone.hsbc.com' : 'evolve.hsbc/currency-zone'}
				</Link>{' '}
				(the 'Site'). This Site is operated by HSBC Bank plc, which is owned by HSBC
				Holdings plc. By accessing this Site, you agree to be bound by the Terms. Before
				using this Site, you should read the Terms carefully, as well as our{' '}
				<Link to="/cookie-notice">Cookie Notice</Link>.
			</p>
			<p>
				HSBC Bank plc is authorised by the Prudential Regulation Authority and regulated by
				the Financial Conduct Authority and the Prudential Regulation Authority. It is
				listed with the registration number 114216. HSBC Bank plc is a company incorporated
				under the laws of England and Wales with company registration number 14259 and its
				registered office at 8 Canada Square, London E14 5HQ. HSBC Bank plc's registered VAT
				Number is GB 365684514.
			</p>
			<p>
				HSBC Bank plc is a member of the Financial Ombudsman Scheme and subscribes to the
				Banking Code and the Business Banking Code which may be accessed at{' '}
				<ExternalLink href="http://www.lendingstandardsboard.org.uk">
					http://www.lendingstandardsboard.org.uk
				</ExternalLink>
				.
			</p>
			<table>
				<thead>
					<tr>
						<th colSpan={3}>In these provisions:</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>'Content'</td>
						<td>
							means all pages, screens, information and materials included in or
							accessible through this Site (including any content available on any
							email or SMS services);
						</td>
					</tr>
					<tr>
						<td>'HSBC Group'</td>
						<td>
							means HSBC Holdings plc, its subsidiaries and associated companies from
							time to time;
						</td>
					</tr>
					<tr>
						<td>'we', 'us' and 'our'</td>
						<td>means HSBC Bank plc; and</td>
					</tr>
					<tr>
						<td>'you', 'your' and 'yours'</td>
						<td>
							means you, the person(s) accessing this Site and the party on whose
							behalf you are doing so.
						</td>
					</tr>
				</tbody>
			</table>

			<h2>Site Terms</h2>
			<p>
				These Terms cover your use of this Site. All products and services provided by
				members of the HSBC Group have their own terms and conditions (the 'Product Terms').
				You must read both the Product Terms and these Terms. To the extent that the Product
				Terms conflict with these Terms then the Product Terms will prevail.
			</p>

			<h2>Privacy Statement</h2>
			<p>
				Our <Link to="/privacy-policy">Privacy Policy</Link> sets out our current policies
				and demonstrates our commitment to your financial privacy. We may change the content
				or services found on our Site at any time without notice, and consequently our
				privacy statement and the Terms may change at any time in the future. You should
				also read our Cookie Notice to find out more about how HSBC and our trusted partners
				use cookies, which is relevant to your online security: we use cookies to make our
				website more secure and easier to use.
			</p>
			<p>
				HSBC Bank plc provides financial products and services through its affiliated
				companies and branches located worldwide. Privacy and personal data protection
				principles vary from one country to another. When you access or link to any other
				website, please read the privacy and data protection statements or agreements issued
				by such other website to determine the policies that apply to information or data
				maintained by that website.
			</p>
			<p>
				HSBC's business has been built on trust between us and our customers. To preserve
				the confidentiality of all information you provide to us:
			</p>
			<p>
				HSBC will only collect information that it believes to be relevant and required to
				understand the customer's financial needs and to conduct HSBC's business.
			</p>
			<p>
				HSBC will use customer information to provide customers with better customer
				services and products.
			</p>
			<p>
				HSBC may pass customer information to other HSBC Group companies or agents, as
				permitted by law.
			</p>
			<p>
				HSBC will not disclose customer information to any external organisation unless HSBC
				have customer consent or are required by law or have previously informed the
				customer.
			</p>
			<p>
				HSBC may be required, from time to time, to disclose customer information to
				Governmental or judicial bodies or agencies or HSBC's regulators but HSBC will only
				do so under proper authority.
			</p>
			<p>HSBC aim to keep customer information up-to-date.</p>
			<p>
				HSBC maintain strict security systems designed to prevent unauthorised access to
				customer information by anyone, including HSBC staff.
			</p>
			<p>
				All HSBC group companies, or HSBC staff and all third parties with permitted access
				to customer information are specifically required to observe HSBC's confidentiality
				obligations.
			</p>
			<p>
				By maintaining our commitment to these principles, we will ensure that we respect
				the inherent trust that you place in HSBC.
			</p>

			<h2>Information not recommendation</h2>
			<p>
				Information or opinions contained on this Site do not constitute an offer to sell or
				a solicitation, or offer to buy, any securities or financial instruments or
				investment advice or any advice or recommendation in respect of such securities or
				other financial instruments.
			</p>
			<p>
				Should you seek to rely in any way whatsoever upon any content contained on this
				Site, you do so at your own risk.
			</p>
			<p>
				Before you make any decision or take any action that might affect you or your
				personal finances or business, you should always consult a suitably qualified
				professional adviser(s) to obtain appropriate financial, legal, accounting, tax and
				other advice.
			</p>

			<h2>Users of the site</h2>
			<p>
				The Site is currently intended to comply with the laws of the United Kingdom.
				Because of this we cannot guarantee that the Site or the information thereon
				complies with law or regulation, or is appropriate for use, in other places.
			</p>
			<p>
				You are wholly responsible for use of the Site by any person using your computer and
				you must ensure that any such person complies with these Terms.
			</p>
			<p>
				Products or services on this Site do not constitute an offer or recommendation of
				any products or services provided by any of the HSBC Group members. The information
				provided on this Site is not intended for distribution to, or use by, any person in
				any jurisdiction where such distribution or use would be contrary to law or
				regulation. This Site should not be considered as communicating any invitation or
				inducement to engage in banking or investment activity or any offer to buy or sell
				any securities or other instruments outside Authorised Countries which are any
				jurisdictions where HSBC Bank plc is licensed or authorised to perform such
				activities.
			</p>
			<p>
				Not all the products and services that you may be able to view via this Site may be
				available in all geographical locations served by HSBC Group members. You should
				access only the local site(s) associated with your country of residence (“Your Local
				Site”). If you own products that are not discussed on Your Local Site, information
				about such products may be accessible on another HSBC website. Products and services
				discussed outside Your Local Site may not be available in your jurisdiction of
				residence or in the Authorised Countries and may not be appropriate for you. Your
				use of this Site is subject to the applicable laws and regulations of any relevant
				jurisdiction. HSBC Group members may decline to provide you with products and
				services subject to status.
			</p>
			<p>
				Products discussed on this Site may not have been registered or authorised by any
				central bank, governmental or regulatory authority in the Authorised Countries or in
				the country of your residence. Accordingly, you may not have the benefit of
				protection from the securities laws, banking laws and other relevant laws and
				regulations of your country of residence with respect to products or services
				referred to on or through this Site.
			</p>
			<p>
				No representation is given that any securities, products, or services discussed in
				or accessible through this Site are suitable for you or any particular person. You
				acknowledge that your use of this Site and any requests for information you have
				made as a result of visiting this Site are not the result of an offer made by HSBC
				Bank plc or any of its affiliates and that the provision of any information through
				this Site shall not constitute or be considered investment or financial advice.
			</p>

			<h2>No warranties</h2>
			<p>
				The following clauses exclude or limit our legal liability for the Site. You should
				read them carefully. They all apply only as far as the law permits, and in
				particular we do not exclude or restrict our duties and liabilities to you under the
				Financial Services and Markets Act 2000 (the "Act"), or the rules of the FCA for the
				conduct of business.
			</p>
			<p>
				While we have taken reasonable steps to ensure the accuracy, currency, availability
				correctness and completeness of the information contained on the Site, information
				is provided on an "as is", "as available" basis and we do not give or make any
				warranty or representation of any kind, whether express or implied. The use of the
				Site is at your sole risk. We shall not be liable for any loss or damage whatsoever
				and howsoever arising as a result of your use of or reliance on the information
				contained on the Site to the maximum extent permitted by law.
			</p>
			<p>
				We do not represent or warrant that the Site will be available and meet your
				requirements, that access will be uninterrupted, that there will be no delays,
				failures, errors or omissions or loss of transmitted information, that no viruses or
				other contaminating or destructive properties will be transmitted or that no damage
				will occur to your computer system. You have sole responsibility for adequate
				protection and back up of data and/or equipment and for undertaking reasonable and
				appropriate precautions to scan for computer viruses or other destructive
				properties.
			</p>
			<p>
				We make no representations or warranties regarding the accuracy, functionality or
				performance of any third party software that may be used in connection with the
				Site.
			</p>

			<h2>Third party sites and endorsements</h2>
			<p>
				Where we provide hypertext links to other locations on the Internet, we do so for
				information purposes only. We are not responsible for the content of any other
				websites or pages linked from or linking to this Site. We have not verified the
				content of any such websites. Following links to any other websites or pages shall
				be at your own risk and we shall not be responsible or liable for any damages or in
				other way in connection with linking. Links to downloadable software sites are for
				convenience only and we are not responsible or liable for any difficulties or
				consequences associated with downloading the software. Use of any downloaded
				software is governed by the terms of the license agreement, if any, which
				accompanies or is provided with the software.
			</p>
			<p>
				No endorsement or approval of any third parties or their advice, opinions,
				information, products or services is expressed or implied by any information on our
				Site.
			</p>

			<h2>Trade marks and copyright</h2>
			<p>
				Copyright in the pages screens, information and material in their arrangement
				included in this Site is owned by or licensed to HSBC Bank plc unless otherwise
				noted. You may imprint, copy, download or temporarily store extracts from our Site
				for your personal information or when you use our products and services. You must
				not alter anything. Any other use is prohibited unless you first get our written
				permission. In particular no one may use a part of our Site on any other website, or
				link any other website to our Site, without our prior written permission.
			</p>

			<h2>Internet e-mail</h2>
			<p>
				Messages sent over the Internet cannot be guaranteed to be completely secure as they
				are subject to possible interception or loss or possible alteration. We are not
				responsible for them and will not be liable to you or anyone else for any damages or
				otherwise in connection with any message sent by you to the Bank or any message sent
				by the Bank to you over the Internet.
			</p>

			<h2>Social Media</h2>
			<p>
				HSBC operates channels, pages and accounts on some social media sites to inform,
				assist and engage with customers. Unless stated otherwise, these are intended for UK
				customers. We monitor and record comments and posts made on these channels about
				HSBC so that we can improve our services.
			</p>
			<p>
				HSBC is not responsible for any information posted on those sites other than
				information we have posted ourselves. We do not endorse the social media sites
				themselves, or any information posted on them by third parties or other users.
			</p>
			<p>
				HSBC doesn't give investment, tax or other professional advice via social media
				sites. You should always seek independent advice before making such decisions.
			</p>
			<p>
				When you engage with HSBC via social media your personal data may be stored on that
				social media site's servers, which are outside the control of HSBC and may be in the
				US or elsewhere outside the EU. Any information posted to such sites may be accessed
				and read by the general public. It is not an appropriate forum to discuss customers'
				products or financial arrangements. HSBC will not ask you to share personal, account
				or security information via social media sites, and you should not post such
				information on those channels.
			</p>
			<p>
				HSBC regularly updates and monitors our social media accounts and welcomes feedback
				and ideas submitted via these channels. HSBC endeavours to join the conversation
				whenever possible, however cannot guarantee to read or reply to all messages sent to
				official HSBC social media accounts.
			</p>
			<p>
				Emerging themes and helpful suggestions are passed to the relevant people within
				HSBC for consideration, but no guarantee is made that any proposals will be acted
				upon.
			</p>

			<h2>Governing law</h2>
			<p>
				The Terms are governed by and interpreted in accordance with the laws of England and
				Wales and the courts of the above jurisdiction will have non-exclusive jurisdiction
				in respect of any dispute, which may arise.
			</p>

			<h2>Your queries</h2>
			<p>
				Questions and comments regarding these Terms and Conditions are welcomed and should
				be addressed to us via email (
				<a
					href="mailto:globalbankingmarkets.webmaster@hsbcib.com?Subject=GBM Privacy policy query"
					accessKey="p"
				>
					globalbankingmarkets.webmaster@hsbcib.com
				</a>
				).
			</p>

			<h2>Contact us</h2>
			<p>
				To discuss service offerings from Global Banking and Markets, please speak to your
				relationship manager.
			</p>
		</div>
	</Page>
);

export default TermsAndConditions;
