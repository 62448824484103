import React, { useEffect } from 'react';
import { useField } from 'formik';
import { mq, styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { Input } from 'components/form';
import { ErrorMessage } from '../../form/ErrorMessage'; // todo: fix styled components imports

export interface RateAlertValue {
	rateAlertValue: string;
	onRateAlertValueChange: (value: string) => void;
}

interface RateInputFieldProps extends RateAlertValue {
	id: string;
	name: string;
	ccyFrom: string;
}

const StyledLabel = styled.label`
	white-space: nowrap;
	align-self: center;
`;

const StyledInput = styled(Input)(
	mq({
		gridColumn: ['1/2', 'auto'],
		gridRow: ['2/3', 'auto']
	})
);

const StyledErrorMessage = styled(ErrorMessage)(
	mq({
		gridColumn: ['1/2', '2/3', '2/3', '2/3'],
		gridRow: ['3/4', '2/3', '2/3', '2/3']
	})
);

export const RateAlertInputField = (props: RateInputFieldProps) => {
	const { id, name, ccyFrom, rateAlertValue, onRateAlertValueChange, ...inputProps } = props;
	const { t } = useTranslation();
	const [{ onBlur, ...field }, meta, helper] = useField(name);
	const isError = !!(meta.touched && meta.error);

	useEffect(() => {
		helper.setValue(rateAlertValue);
	}, [helper, rateAlertValue]);

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		onRateAlertValueChange(field.value);
		onBlur(e);
	};

	return (
		<>
			<StyledLabel htmlFor="rate-alert-value">
				{t('labels.alertWhen')}
				<b>{`1 ${ccyFrom} `}</b>=
			</StyledLabel>
			<StyledInput id={id} onBlur={handleBlur} isError={isError} {...inputProps} {...field} />
			{isError && <StyledErrorMessage>{meta.error}</StyledErrorMessage>}
		</>
	);
};
