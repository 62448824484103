import { useEffect, useRef } from 'react';

export const useOutsideClick = <T extends HTMLElement>(
	callback: () => void,
	options?: boolean | AddEventListenerOptions
) => {
	const ref = useRef<T>(null);

	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (ref.current && !ref.current.contains(e.target as Node)) {
				callback();
			}
		};

		document.addEventListener('click', handleClick, options);

		return () => {
			document.removeEventListener('click', handleClick, options);
		};
	}, [callback, options, ref]);

	return ref;
};
