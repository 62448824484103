import serverLog from './logger-network';
import eventLog from './logger-activity-events';
import { messageTypes } from './types';
import { isLocalhost } from '../dom/window';

let logCount = 0;
const logIdSuffix = Date.now();
const rand = Math.random();
const createLogId = () => {
	logCount += 1;
	return `${logIdSuffix}-${rand}-${logCount}`;
};

const createMessageObject = (type, message, data) => ({
	logId: createLogId(),
	type,
	message,
	data
});

const localLog = message => {
	if (!global || !global.console) {
		return;
	}
	const actions = {
		[messageTypes.info]: (...args) => globalThis.console.log(...args),
		[messageTypes.warning]: (...args) => globalThis.console.warn(...args),
		[messageTypes.event]: (...args) => globalThis.console.log(...args),
		[messageTypes.error]: (...args) => globalThis.console.error(...args)
	};
	const log = actions[message.type];
	if (typeof log === 'function') {
		log(
			`[local log] type="${message.type}"`,
			`logId="${message.logId}"`,
			`message="${JSON.stringify(message.message)}"`,
			`data="${JSON.stringify(message.data)}"`
		);
	}
};

const logMessage = message => {
	const isEvent = message.type === messageTypes.event;
	if (isLocalhost()) {
		localLog(message);
		return;
	}
	if (isEvent) {
		eventLog(message);
	} else {
		serverLog(message);
	}
};

const createLoggerType = type => (message, json) => {
	const messageObj = createMessageObject(type, message, json || null);
	logMessage(messageObj);
};

const defaultErrorLogger = createLoggerType(messageTypes.error);

const errorLogger = (message, json) => {
	const error = message instanceof Error ? message : null;
	if (error) {
		const messageObj = createMessageObject(messageTypes.error, error.message, error.stack);
		serverLog(messageObj);
		window.console.error(error);
	} else if (typeof message === 'string') {
		defaultErrorLogger(message, json);
	}
};

const createLogger = () => {
	return {
		log: createLoggerType(messageTypes.info),
		warn: createLoggerType(messageTypes.warning),
		event: createLoggerType(messageTypes.event),
		error: errorLogger
	};
};

export default createLogger;
