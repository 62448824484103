import get from 'lodash/get';
import { APP_URL } from '../../../../server/constants/url';

const getOriginUrl = () => {
	return get(globalThis, 'location.origin', '');
};

export const isLocalhost = () => {
	return getOriginUrl() === 'http://localhost:8080' || getOriginUrl() === 'http://localhost:8085';
};

export const isDev = () => {
	return getOriginUrl() === 'http://localhost:8080';
};

export const isCurrencyZoneDomain = () => {
	const host = get(globalThis, 'location.host', '').toLowerCase();
	return host.includes('currencyzone.hsbc.com');
};

export const getBaseUrl = () => {
	if (isDev()) {
		return '';
	}
	return isCurrencyZoneDomain() ? `` : `/${APP_URL}`;
};
