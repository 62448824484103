import { css } from '@emotion/react';
import { Grid } from 'components/common';
import { mq, styled } from 'utils/styles';

export const Header = styled.div`
	max-width: 100%;

	${mq({
		marginBottom: ['1.5rem', null, null, '2.5rem'],
		lineHeight: ['2.5rem', null, null, '3.125rem']
	})};

	h1 {
		font-weight: 300;
		font-style: normal;
		margin-top: 0;
		margin-bottom: 0.5rem;
		${mq({
			fontSize: ['2.0625rem', null, null, '2.75rem'],
			lineHeight: ['2.5rem', null, null, '3.125rem']
		})};
	}
`;

export const Strong = styled.strong(
	({ theme }) => css`
		font-weight: ${theme.fontWeight.bold};
	`
);

export const Page = styled(Grid)(
	({ theme }) => css`
		${mq({
			padding: ['2.5rem 12px', null, null, '4rem 12px']
		})};

		font-weight: ${theme.fontWeight.light};

		h1 {
			font-weight: 300;
			font-style: normal;
			margin-top: 0;
			margin-bottom: 40px;
			line-height: 1;
			letter-spacing: -0.025em;
			${mq({
				fontSize: ['2.0625rem', null, null, '2.75rem'],
				lineHeight: ['2.5rem', null, null, '3.125rem']
			})};
		}

		h2 {
			margin: 1rem 0;
			font-weight: 300;
			${mq({
				fontSize: ['1.1875rem', null, null, '1.375rem'],
				lineHeight: ['1.6875rem', null, null, '1.9375rem']
			})};

			&:first-of-type {
				margin-top: 0;
			}
		}

		h3 {
			margin: 1rem 0;
			font-weight: 300;
			${mq({
				fontSize: ['1.1875rem', null, null, '1.375rem'],
				lineHeight: ['1.6875rem', null, null, '1.9375rem']
			})};
		}

		h4 {
			margin-top: 42px;
			margin-bottom: 20px;
			display: block;
			font-weight: 700;
			${mq({
				fontSize: [18, 22]
			})};
		}

		p {
			margin: 0.75rem 0;
		}

		a {
			color: #333;
			font-weight: 400;
		}

		table {
			width: 100%;
			margin: 2.5rem 0;
			border-collapse: collapse;
			border-spacing: 0;

			thead {
				th {
					background-color: #d7d8d6;
					border: 1px solid #a6a6a6;
					font-weight: ${theme.fontWeight.bold};
					text-align: center;
					font-size: 0.875rem;
					line-height: 1.25rem;
					padding: 1.5rem;
					vertical-align: middle;
				}
			}

			tr {
				td {
					border: 1px solid #d7d8d6;
					text-align: left;
					font-size: 0.875rem;
					line-height: 1.25rem;
					font-weight: ${theme.fontWeight.normal};
					padding: 1.5rem;
					vertical-align: middle;
				}
			}

			caption {
				width: 1px;
				height: 1px;
				margin: -1px;
				padding: 0;
				overflow: hidden;
				clip: rect(0, 0, 0, 0);
				border: 0;
			}
		}

		ul {
			padding: 0;
			margin: 0.75rem 0 0.75rem 1.5rem;
			font-weight: ${theme.fontWeight.normal};

			li {
				margin: 0.5rem 0;
			}
		}
	`
);
