/* eslint-disable no-console */

import wrap from './logger-wrap';
import { batchToStrings } from './logger-batcher';

const logMechanism = () => {
	// fetch('v1/log', {
	// 	method: 'POST',
	// 	credentials: 'include',
	// 	headers: { 'content-type': 'text/plain' },
	// 	body: string
	// });
	return Promise.resolve('yeah');
	// console.log(string);
};

const log = batchToStrings(logMechanism);

export const createStringFromMessageObject = message =>
	JSON.stringify({
		...message,
		timestamp: new Date().toISOString()
	});

const queue = message => {
	try {
		const messageString = createStringFromMessageObject(message);
		log(messageString);
	} catch (e) {
		console.warn(e);
	}
};

const wrappedLogger = wrap(queue);

export default wrappedLogger;
