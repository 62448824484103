import React from 'react';
import { BankingSolution as IBankingSolution } from 'types';
import { typography } from 'utils';
import { LinkButton } from 'components/common';
import { NewWindow } from 'components/icons';
import { styled } from 'utils/styles';

const ArticleContainer = styled.article`
	flex: 1;
	display: flex;
	flex-direction: column;
	color: ${({ theme }) => theme.colors.typography};
	background-color: ${({ theme }) => theme.colors.white};
`;

const ArticleImage = styled('div', { shouldForwardProp: prop => prop !== 'src' })<{ src: string }>`
	background-image: url('${({ src }) => src}');
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	aspect-ratio: 16 / 9;
`;

const ArticleContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: justify;
	background-color: ${({ theme }) => theme.colors.white};
`;

const Title = styled.h3`
	&& {
		${typography.s4}
		font-weight: ${({ theme }) => theme.fontWeight.normal};
		margin: 14px 0;
	}
`;

export const BankingSolution = (props: IBankingSolution) => {
	const { title, paragraph1, paragraph2, link, imgUrl } = props;

	return (
		<ArticleContainer>
			<ArticleImage src={imgUrl} />
			<ArticleContent>
				<div>
					<Title>{title}</Title>
					<p>{paragraph1}</p>
					<p>{paragraph2}</p>
				</div>
				<LinkButton href={link.href} variant="tertiary" size="small" textAlign="center">
					<NewWindow size="small" marginRight="small" verticalAlign="text-top" />
					{link.label}
				</LinkButton>
			</ArticleContent>
		</ArticleContainer>
	);
};
