import React, { useState } from 'react';
import { styled } from 'utils/styles';
import { Trans, useTranslation } from 'utils/i18next';
import { InfoIcon } from 'components/icons';
import { Disclaimer, ButtonLink } from 'components/common';

const StyledDisclaimer = styled(Disclaimer)`
	display: flex;
	gap: ${({ theme }) => theme.space.small};

	svg {
		flex-shrink: 0;
		fill: ${({ theme }) => theme.colors.rag.blue};
	}

	p:nth-of-type(1) {
		margin-top: 0;
	}
`;

export const MarketCloseDisclaimer = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { t } = useTranslation();

	return (
		<StyledDisclaimer>
			<InfoIcon size="medium" />
			<div>
				<p>
					<Trans
						i18nKey="disclaimers.marketClose.excerpt"
						values={{
							expandLabel: t(isOpen ? 'labels.readLess' : 'labels.readMore')
						}}
					>
						Please note: The global foreign exchange market is generally closed on
						weekends and global bank holidays and prices will not update during these
						periods.
						<ButtonLink
							id="market-close-disclaimer-btn"
							aria-expanded={isOpen}
							aria-controls="market-close-disclaimer-content"
							onClick={() => setIsOpen(isOpen => !isOpen)}
						>
							Read more
						</ButtonLink>
					</Trans>
				</p>
				{isOpen && (
					<div
						id="market-close-disclaimer-content"
						aria-labelledby="market-close-disclaimer-btn"
					>
						<p>{t('disclaimers.marketClose.content')}</p>
					</div>
				)}
			</div>
		</StyledDisclaimer>
	);
};
