import React from 'react';
import { useTranslation } from 'utils/i18next';

export const FxTrendsPubDate = ({ children }: { children: React.ReactNode }) => {
	const { t } = useTranslation('research');

	return (
		<p>
			{t('fxTrends.published')}
			<b>
				<time>{children}</time>
			</b>
		</p>
	);
};
