import React from 'react';
import { FxRate } from 'types';
import { styled, mq } from 'utils/styles';
import { convertCurrency, Decimal, isMetal, splitCcyPair } from 'utils/currency';
import { formatAmount, formatCurrency } from 'utils/formatters';
import { EmptyState, EmptyLine, Time, MidRateSimpleDisclaimer, Flex } from './common';

interface CurrencyRateResultProps {
	amount: number;
	isLoading: boolean;
	fxRate: FxRate | null;
	expandDisclaimer: () => void;
}

const CurrencyRateResultContainer = styled(Flex)`
	flex-direction: column;
	justify-content: space-evenly;

	p {
		line-height: 1.4;
	}

	${mq({
		height: [250, 220, 240, 220]
	})}
`;

const StyledAmount = styled.p`
	font-size: 18px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	margin: 0;
`;

const StyledResult = styled.p`
	font-size: 38px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	margin: 0;

	span {
		color: #929292;
	}
`;

const StyledMid = styled.p`
	margin: 4px 0 0;

	&:first-of-type {
		font-weight: ${({ theme }) => theme.fontWeight.bold};
	}
`;

export const CurrencyRateResult = (props: CurrencyRateResultProps) => {
	const { amount, isLoading, fxRate, expandDisclaimer } = props;

	if (isLoading || !fxRate || fxRate.dataSet.length === 0) {
		return (
			<CurrencyRateResultContainer>
				<EmptyState flex={1}>
					<EmptyLine width={80} height="small" />
					<EmptyLine width={200} height="large" />
					<EmptyLine width={150} height="small" />
					<EmptyLine width={150} height="small" />
					<EmptyLine width={200} height="small" />
					<EmptyLine width={260} height="small" />
				</EmptyState>
			</CurrencyRateResultContainer>
		);
	}

	const [ccyFrom, ccyTo] = splitCcyPair(fxRate.ccyPair);
	const [result, rest] = convertCurrency(amount, fxRate);
	const rate = fxRate.latest?.open || 0;
	const invertedRate = fxRate.latest?.invertedOpen || 0;
	const timestamp = fxRate.latest?.timestamp;

	return (
		<CurrencyRateResultContainer data-testid="currency-rate-result-container">
			<div>
				<div>
					<StyledAmount data-testid="currency-rate-result-ccy-from-amount">
						{formatAmount(amount) + ' ' + ccyFrom + ' = '}
					</StyledAmount>
					<StyledResult data-testid="currency-rate-result-ccy-to-amount">
						{amount === 1 ? (
							<>
								{result}
								{rest && <span>{rest}</span>}
								{` ${ccyTo}`}
							</>
						) : isMetal(ccyTo) ? (
							`${formatAmount(new Decimal(amount).times(new Decimal(rate)))} ${ccyTo}`
						) : (
							`${formatCurrency(
								new Decimal(amount).times(new Decimal(rate)).toString(),
								ccyTo
							)}`
						)}
					</StyledResult>
				</div>
				{!!rate && amount !== 1 && (
					<StyledMid data-testid="currency-rate-result-mid-rate">
						{`1 ${ccyFrom} = ${formatAmount(rate, fxRate.decimalPlaces)} ${ccyTo} `}
					</StyledMid>
				)}
				{!!invertedRate && (
					<StyledMid data-testid="currency-rate-result-mid-inverted-rate">
						{`1 ${ccyTo} = ${formatAmount(
							invertedRate,
							fxRate.decimalPlacesForInverted
						)} ${ccyFrom}`}
					</StyledMid>
				)}
				{!!timestamp && (
					<Time
						data-testid="currency-rate-result-mid-rate-timestamp"
						ts={timestamp}
						my="small"
					/>
				)}
			</div>
			<MidRateSimpleDisclaimer onClick={() => expandDisclaimer()} />
		</CurrencyRateResultContainer>
	);
};
