import React from 'react';
import { debounce } from 'lodash';
import { FxTrendsCcy } from 'types';
import { RadioButton, RadioGroup } from 'components/form';
import { styled, visuallyHidden } from 'utils/styles';
import { useTranslation } from 'utils/i18next';

interface FxTrendsCurrenciesProps {
	ccy: FxTrendsCcy;
	ccys: FxTrendsCcy[];
	onCcyChange: (value: FxTrendsCcy) => void;
}

const HiddenLegend = styled.legend(visuallyHidden);

export const FxTrendsCurrencies = (props: FxTrendsCurrenciesProps) => {
	const { ccys, onCcyChange } = props;
	const { t } = useTranslation();
	const handleChange = debounce(onCcyChange, 100);

	return (
		<RadioGroup>
			<HiddenLegend>{t('timeRangeLegend')}</HiddenLegend>
			{ccys.map(ccy => (
				<RadioButton
					id={ccy}
					label={ccy}
					key={ccy}
					value={ccy}
					name="research-component-button"
					onChange={() => handleChange(ccy)}
					checked={props.ccy === ccy}
				/>
			))}
		</RadioGroup>
	);
};
