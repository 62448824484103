import React from 'react';
import { SVG, SVGProps } from './SVG';

export const ArrowUpIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<polygon points="16 7.15 9 0.15 2 7.15 2 8.85 8.4 2.45 8.4 18 9.6 18 9.6 2.45 16 8.85 16 7.15" />
		</g>
	</SVG>
);
