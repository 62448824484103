import React from 'react';
import { SVG, SVGProps } from './SVG';

export const WarningIcon = (props: SVGProps) => (
	<SVG focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect width="18" height="18" />
			<path
				fill="#fff"
				d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM8.1,3.75H9.9v6H8.1ZM9,14.25A1.25,1.25,0,1,1,10.25,13,1.25,1.25,0,0,1,9,14.25Z"
			/>
			<path
				fill="#333"
				d="M9,11.75A1.25,1.25,0,1,1,7.75,13,1.25,1.25,0,0,1,9,11.75Zm-.9-2H9.9v-6H8.1Z"
			/>
		</g>
	</SVG>
);
