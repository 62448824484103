import React, { CanvasHTMLAttributes, useEffect, useRef } from 'react';
import { ChartConfiguration } from 'chart.js';
import ChartJS from 'chart.js/auto';

export interface ChartProps extends CanvasHTMLAttributes<HTMLCanvasElement> {
	config: ChartConfiguration<'line'>;
}

export const Chart = ({ config, ...props }: ChartProps) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (canvasRef.current) {
			const chart = new ChartJS(canvasRef.current, config);
			return () => chart.destroy();
		}
	}, [config]);

	return <canvas ref={canvasRef} {...props} />;
};
