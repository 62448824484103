import React, { useEffect, useState } from 'react';
import { typography, styled } from 'utils/styles';
import { Trans, useTranslation } from 'utils/i18next';
import { Flex } from './Grid';

interface CountdownIndicatorProps {
	ms: number;
	enabled?: boolean;
}

const StyledCountdown = styled.div<CountdownIndicatorProps>`
	height: 30px;
	width: 30px;
	border-radius: 15px;
	position: relative;
	background-color: ${({ theme }) => theme.colors.grey5};

	&::before,
	&::after {
		content: '';
		width: 15px;
		height: 30px;
		position: absolute;
		left: 0;
		border-radius: 15px 0 0 15px;
		transform-origin: center right;
		animation-duration: ${({ ms }) => ms}ms;
		animation-iteration-count: infinite;
	}

	&::before {
		z-index: 1;
		background-color: ${({ theme }) => theme.colors.grey5};
		animation-name: ${({ enabled }) => (enabled ? 'mask' : 'none')};
		animation-timing-function: steps(1);
	}

	&::after {
		background-color: ${({ theme }) => theme.colors.grey2};
		animation-name: ${({ enabled }) => (enabled ? 'rotate' : 'none')};
		animation-timing-function: linear;
	}

	@keyframes rotate {
		to {
			transform: rotate(1turn);
		}
	}

	@keyframes mask {
		50%,
		100% {
			background-color: ${({ theme }) => theme.colors.grey2};
			transform: rotate(0.5turn);
		}
	}
`;

const Label = styled.p`
	${typography.s7}
	min-width: 175px;
`;

export const CountdownIndicator = (props: CountdownIndicatorProps) => {
	const { ms, enabled = true } = props;
	const { t } = useTranslation();
	const [remainingTime, setRemainingTime] = useState(0);
	const s = ms / 1000;
	const minDigits = s.toString().length;

	useEffect(() => {
		if (enabled) {
			setRemainingTime(s);
			const interval = setInterval(() => setRemainingTime(time => time - 1), 1000);

			return () => {
				clearInterval(interval);
			};
		}
	}, [enabled, s]);

	return (
		<Flex alignItems="center" gridGap="small">
			<StyledCountdown ms={ms} enabled={enabled} />
			<Label>
				{enabled ? (
					<Trans
						i18nKey="watchlist.countdown"
						count={remainingTime}
						tOptions={{ minDigits }}
					>
						<b>60 seconds</b> until rates refresh
					</Trans>
				) : (
					t('watchlist.refreshing')
				)}
			</Label>
		</Flex>
	);
};
