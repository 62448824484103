import { useLocation } from 'react-router-dom';
import { parseQueryParams } from '../../utils/url';
import React, { ReactNode } from 'react';

interface ClientOnlyProps {
	children: ReactNode;
}

const externalApps = ['je-expat-m', 'my-malaysia-m'];
export const HiddenForExternalApps = ({ children }: ClientOnlyProps) => {
	const { search } = useLocation();
	const { hsbc_source } = parseQueryParams(search);

	const isExternalApp = hsbc_source && externalApps.includes(hsbc_source);

	return <>{!isExternalApp && children}</>;
};
