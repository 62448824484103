import React from 'react';
import { SVG, SVGProps } from './SVG';

export const SuccessIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect width="18" height="18" />
			<path d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM7.22,13.55,3.59,9.92,4.86,8.65,7.22,11l5.92-5.93,1.27,1.27Z" />
		</g>
	</SVG>
);
