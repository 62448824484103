import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink, Grid } from 'components/common';
import { event } from 'utils/logger';
import { css, mq, styled } from 'utils/styles';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { useTranslation } from 'utils/i18next';

interface FooterLink {
	label: string;
	href: string;
	isExternal?: boolean;
}

const StyledFooter = styled.footer(
	({ theme: { space, fontSize, colors } }) => css`
		background-color: ${colors.black};

		ul {
			padding: 0;
			margin: 0;

			li {
				& > a {
					font-size: ${fontSize.small};
					color: ${colors.white};
					text-decoration: none;
				}

				${mq({
					display: ['block', 'block', 'inline-block'],
					textAlign: ['center', 'center', 'left'],
					marginBottom: [space.large, null, 0]
				})}
			}

			li + li {
				${mq({
					marginLeft: [0, 0, space.large]
				})}
			}
		}
	`
);

const StyledGrid = styled(Grid)(
	({ theme: { space } }) => css`
		display: flex;
		align-items: center;

		box-sizing: border-box;
		padding: ${space.large} ${space.medium};

		${mq({
			flexDirection: ['column', 'column', 'row'],
			justifyContent: ['center', 'center', 'space-between']
		})};
	`
);

interface FooterProps {
	sendEvent?: SendEvent;
}

export const Footer = ({ sendEvent = event }: FooterProps) => {
	const { t } = useTranslation();

	const bottomLinks: FooterLink[] = [
		{
			label: t('footer.terms'),
			href: `/terms-conditions`
		},
		{
			label: t('footer.privacy'),
			href: `/privacy-policy`
		},
		{
			label: t('footer.cookies'),
			href: `/cookie-notice`
		},
		{
			label: t('footer.accessibility'),
			href: `https://www.hsbc.com/accessibility`,
			isExternal: true
		},
		{
			label: t('footer.complaint'),
			href: 'https://www.gbm.hsbc.com/financial-regulation/complaint-handling',
			isExternal: true
		}
	];

	return (
		<StyledFooter data-component="Footer" data-testid="footer">
			<StyledGrid>
				<nav>
					<ul>
						{bottomLinks.map(link => (
							<li key={link.href}>
								{link.isExternal ? (
									<ExternalLink href={link.href}>{link.label}</ExternalLink>
								) : (
									<Link
										to={link.href}
										onClick={() =>
											sendEvent(
												EVENTS.PREDEFINED_EVENTS.FOOTER_NAVIGATE_TO(
													link.label,
													'onsite'
												)
											)
										}
									>
										{link.label}
									</Link>
								)}
							</li>
						))}
					</ul>
				</nav>
			</StyledGrid>
		</StyledFooter>
	);
};
