import React from 'react';
import dateFormat from 'dateformat';
import { FxRate, Range } from 'types';
import { useTranslation } from 'utils/i18next';
import { Change, Loader } from 'components/common';
import { formatAmount } from 'utils/formatters';
import { formatTime } from 'utils/date';
import { css, flashAnimation, mq, styled } from 'utils/styles';

interface CurrencyRateChartTableProps {
	isLoading: boolean;
	flash: boolean;
	fxRate: FxRate | null;
}

const TableContainer = styled.div`
	position: relative;
	overflow-x: auto;
	min-height: 300px;
	margin: ${({ theme }) => theme.space.xlarge} auto;

	${flashAnimation}
	${mq({
		width: ['100%', 460]
	})}
`;

const Table = styled.table(
	({ theme: { colors, space } }) => css`
		width: 100%;
		border-collapse: collapse;
		white-space: nowrap;
		margin: 0 auto;
		border: 1px solid ${colors.grey1};

		thead {
			border-bottom: 6px solid ${colors.grey1};
		}

		tbody tr {
			border-bottom: 2px solid ${colors.grey1};
		}

		td,
		th {
			text-align: right;
			width: 100px;
			padding: ${space.small};
		}
	`
);

function extractUTCHours(date: Date, hours: number): number {
	return date.setUTCHours(date.getUTCHours() - hours);
}

function extractUTCMonths(date: Date, months: number): number {
	return date.setUTCMonth(date.getUTCMonth() - months);
}

function getPrevRateTimestamp(date: Date, range: Range): number {
	switch (range) {
		case Range.DAY:
			return extractUTCHours(date, 1);

		case Range.WEEK:
			return extractUTCHours(date, 24);

		case Range.MONTH:
			return extractUTCHours(date, 24);

		case Range.YEAR:
			return extractUTCMonths(date, 1);

		case Range.TWO_YEARS:
			return extractUTCMonths(date, 3);
	}
}

function sampleRates({ dataSet, range }: FxRate) {
	const lastRate = dataSet[dataSet.length - 1];
	const lastRateDate = new Date(lastRate.timestamp);
	const sampledRates = [lastRate];
	const sampleCount = {
		day: 24,
		week: 7,
		month: 30,
		year: 12,
		'2y': 8
	};

	for (let i = 1; i < sampleCount[range]; i++) {
		const prevRateTimestamp = getPrevRateTimestamp(lastRateDate, range);
		const rate = dataSet.find(({ timestamp }) => prevRateTimestamp === timestamp);
		sampledRates.push(rate || { open: 0, timestamp: prevRateTimestamp });
	}

	return sampledRates.reverse();
}

function calcRatesChange(rate: number | null, prevRate: number | null) {
	return rate && prevRate ? ((rate - prevRate) / prevRate) * 100 : null;
}

export const CurrencyRateChartTable = React.memo(function CurrencyRateChartTable(
	props: CurrencyRateChartTableProps
) {
	const { fxRate, flash, isLoading } = props;
	const { t } = useTranslation();
	const isRangeWithTimeColumn = fxRate && fxRate.range !== 'year' && fxRate.range !== '2y';

	if (!fxRate) {
		return null;
	}

	return (
		<TableContainer flash={flash}>
			{isLoading ? (
				<Loader textDelay={3000} />
			) : (
				<Table>
					<thead>
						<tr>
							<th>{t('labels.date')}</th>
							{isRangeWithTimeColumn && <th>{t('labels.time')}</th>}
							<th>{fxRate.ccyPair}</th>
							<th>{`% ${t('labels.change')}`}</th>
						</tr>
					</thead>
					<tbody>
						{sampleRates(fxRate).map(({ open, timestamp }, idx, sampledDataSet) => {
							const { open: prevOpen } = sampledDataSet[idx - 1] || {};
							const change = calcRatesChange(open, prevOpen);

							return (
								<tr key={timestamp}>
									<td>{dateFormat(new Date(timestamp), 'dd/mm/yyyy ')}</td>
									{isRangeWithTimeColumn && <td>{formatTime(timestamp)}</td>}
									<td>
										{open
											? formatAmount(open, fxRate?.decimalPlaces)
											: t('labels.na')}
									</td>
									<td>
										<Change change={change} />
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			)}
		</TableContainer>
	);
});
