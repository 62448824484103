import { useEffect, useState } from 'react';
import { MenuItem, HeaderContext } from 'types';
import { EVENTS } from 'utils/logging/types';
import { useTranslation } from 'utils/i18next';
import { useBreakpoint } from './useBreakpoint';

export const useHeaderMenuItems = (context: HeaderContext | null): MenuItem[] => {
	const { t } = useTranslation();
	const { bp } = useBreakpoint();
	const [links, setLinks] = useState<MenuItem[]>([]);

	useEffect(() => {
		const personalMenu: MenuItem = {
			label: t('header.personal'),
			submenu: [
				{
					label: t('header.externalLinks.onlineBanking'),
					href: 'https://www.hsbc.co.uk/online/dashboard',
					trackingEvent: EVENTS.PREDEFINED_EVENTS.LOG_ON_PERSONAL_ONLINE_BANKING
				}
			]
		};

		const businessMenu: MenuItem = {
			label: t('header.business'),
			submenu: [
				{
					label: t('header.externalLinks.businessInternetBanking'),
					href: 'https://online-banking.business.hsbc.co.uk/portalserver/hsbc/login',
					trackingEvent: EVENTS.PREDEFINED_EVENTS.LOG_ON_BUSINESS_ONLINE_BANKING
				},
				{
					label: t('header.externalLinks.hsbcNET'),
					href: 'https://www.hsbcnet.com/',
					trackingEvent: EVENTS.PREDEFINED_EVENTS.LOG_ON_BUSINESS_HSBC_NET
				},
				{
					label: t('header.externalLinks.hsbcKinetic'),
					href: 'https://secure.hsbckinetic.co.uk/login',
					trackingEvent: EVENTS.PREDEFINED_EVENTS.LOG_ON_BUSINESS_HSBC_KINETIC
				},
				{
					label: t('header.externalLinks.accountOpeningPortal'),
					href: 'https://apply.business.hsbc.com/pws-cd/login.htm',
					trackingEvent: EVENTS.PREDEFINED_EVENTS.LOG_ON_BUSINESS_HSBC_OPENING_PORTAL
				},
				{
					label: t('header.externalLinks.customerInformation'),
					href: 'https://apply.business.hsbc.com/pwsg/servlet/SmartForm.html?formCode=cdd-kyc-pf-gb-en',
					trackingEvent:
						EVENTS.PREDEFINED_EVENTS.LOG_ON_BUSINESS_CUSTOMER_INFORMATION_REVIEW
				}
			]
		};

		if (context === 'personal' && personalMenu.submenu) {
			setLinks(personalMenu.submenu);
		} else if (context === 'business' && businessMenu.submenu) {
			setLinks(businessMenu.submenu);
		} else {
			setLinks([personalMenu, businessMenu]);
		}
	}, [bp, context, t]);

	return links;
};
