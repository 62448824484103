interface CountryOption {
	name: string;
	supported: boolean;
	localWebsite?: string;
}

type CountryMap = {
	[country: string]: CountryOption;
};

const countryMap: CountryMap = {
	Algeria: {
		name: 'Algeria',
		supported: false,
		localWebsite: 'https://www.business.algeria.hsbc.com/fr-dz/dz/generic/contact-us'
	},
	Argentina: { name: 'Argentina', supported: true },
	Armenia: {
		name: 'Armenia',
		supported: false,
		localWebsite: 'https://www.hsbc.am/en-am/help/contact/'
	},
	Australia: { name: 'Australia', supported: true },
	Bahrain: {
		name: 'Bahrain',
		supported: true,
		localWebsite: 'https://www.business.hsbc.com.bh/en-gb/bh/generic/contact-us'
	},
	Bangladesh: {
		name: 'Bangladesh',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.bd/en-gb/bd/generic/contact-us'
	},
	Belgium: { name: 'Belgium', supported: true },
	Bermuda: { name: 'Bermuda', supported: true },
	Canada: { name: 'Canada', supported: true },
	'Channel Islands and Isle of Man': { name: 'Channel Islands and Isle of Man', supported: true },
	'Czech Republic': { name: 'Czech Republic', supported: true },
	Egypt: {
		name: 'Egypt',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.eg/en-gb/eg/generic/contact-us'
	},
	France: { name: 'France', supported: true },
	Germany: { name: 'Germany', supported: true },
	Greece: {
		name: 'Greece',
		supported: true,
		localWebsite: 'https://www.business.hsbc.gr/en-gb/gr/generic/contact-us'
	},
	'Hong Kong': {
		name: 'Hong Kong',
		supported: true,
		localWebsite: 'https://www.business.hsbc.com.hk/en-gb/arrange-a-call-back-general'
	},
	India: { name: 'India', supported: true },
	Indonesia: {
		name: 'Indonesia',
		supported: false,
		localWebsite: 'https://www.business.hsbc.co.id/en-gb/contact-us'
	},
	Ireland: { name: 'Ireland', supported: true },
	Israel: { name: 'Israel', supported: true },
	Italy: { name: 'Italy', supported: true },
	Kuwait: { name: 'Kuwait', supported: true },
	Macau: {
		name: 'Macau',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.mo/en-gb/mo/generic/contact-us'
	},
	'Mainland China': {
		name: 'Mainland China',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.cn/en-gb/contact-us'
	},
	Malaysia: { name: 'Malaysia', supported: true },
	Maldives: {
		name: 'Maldives',
		supported: false,
		localWebsite: 'https://www.business.maldives.hsbc.com/en-gb/mv/generic/contact-us'
	},
	Malta: { name: 'Malta', supported: true },
	Mauritius: {
		name: 'Mauritius',
		supported: false,
		localWebsite: 'https://www.business.hsbc.co.mu/en-gb/mu/generic/contact-us'
	},
	Mexico: { name: 'Mexico', supported: true },
	Netherlands: { name: 'Netherlands', supported: true },
	'New Zealand': {
		name: 'New Zealand',
		supported: false,
		localWebsite: 'https://www.business.hsbc.co.nz/en-gb/nz/generic/contact-us'
	},
	Oman: {
		name: 'Oman',
		supported: false,
		localWebsite: 'https://www.business.hsbc.co.om/en-gb/om/generic/contact-us'
	},
	Philippines: {
		name: 'Philippines ',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.ph/en-gb/ph/generic/contact-us'
	},
	Poland: { name: 'Poland', supported: true },
	Qatar: {
		name: 'Qatar ',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.qa/en-gb/qa/generic/contact-us'
	},
	Russia: {
		name: 'Russia',
		supported: false,
		localWebsite: 'https://www.business.hsbc.ru/en-gb/ru/generic/contact-us'
	},
	Singapore: { name: 'Singapore', supported: true },
	'South Africa': {
		name: 'South Africa',
		supported: false,
		localWebsite: 'https://www.business.hsbc.co.za/en-gb/za/generic/contact-us'
	},
	Spain: { name: 'Spain', supported: true },
	'Sri Lanka': {
		name: 'Sri Lanka',
		supported: false,
		localWebsite: 'https://www.business.hsbc.lk/en-gb/email-us'
	},
	Switzerland: { name: 'Switzerland', supported: true },
	Taiwan: {
		name: 'Taiwan',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.tw/en-gb/tw/generic/contact-us'
	},
	Thailand: {
		name: 'Thailand',
		supported: false,
		localWebsite: 'https://www.business.hsbc.co.th/en-gb/contact-us'
	},
	Turkey: {
		name: 'Turkey',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.tr/en-gb/tr/generic/contact-us'
	},
	'United Arab Emirates': { name: 'United Arab Emirates', supported: true },
	'United Kingdom': { name: 'United Kingdom', supported: true },
	'United States': { name: 'United States', supported: true },
	Vietnam: {
		name: 'Vietnam',
		supported: false,
		localWebsite: 'https://www.business.hsbc.com.vn/en-gb/vn/generic/contact-us'
	}
};

export const countries = ['Please select', ...Object.keys(countryMap)];
