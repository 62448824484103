import React from 'react';
import { useTranslation } from 'utils/i18next';
import { splitCcyPair } from 'utils/currency';
import { CurrencySymbol } from './CurrencySymbol';
import { SwitchIcon } from '../icons';
import { Button } from './Button';
import { Flex } from './Grid';
import { CurrencyCode } from './CurrencyCode';

interface CurrencyPairProps {
	ccyPair: string;
	onSwitch: React.MouseEventHandler;
	switchDisabled: boolean;
}

export const CurrencyPair = (props: CurrencyPairProps) => {
	const { ccyPair, onSwitch, switchDisabled } = props;
	const [ccyCodeFrom, ccyCodeTo] = splitCcyPair(ccyPair);
	const { t } = useTranslation();

	return (
		<Flex alignItems="center">
			<CurrencySymbol ccyCode={ccyCodeFrom} mx="4px" fontWeight="400" />
			<CurrencyCode>{ccyCodeFrom}</CurrencyCode>
			<CurrencySymbol ccyCode={ccyCodeTo} mx="4px" fontWeight="400" />
			<CurrencyCode>{ccyCodeTo}</CurrencyCode>
			<Button
				variant="icon"
				aria-label={t('aria.invertCcyPair')}
				onClick={onSwitch}
				disabled={switchDisabled}
			>
				<SwitchIcon size="medium" />
			</Button>
		</Flex>
	);
};
