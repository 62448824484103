import React from 'react';
import { mq, styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { CloseIcon } from 'components/icons';
import { Button } from 'components/common';

export const StyledModalHeading = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	grid-gap: ${({ theme }) => theme.space.medium};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};

	h2 {
		margin: 0;
		font-weight: 400;

		${mq({
			fontSize: ['22px', '34px']
		})}
	}

	button {
		margin-top: -8px;
		margin-right: -8px;
	}

	margin-bottom: 35px;
	padding-bottom: 35px;
`;

interface ModalHeadingProps {
	children: React.ReactNode;
	onClose: () => void;
}

export const ModalHeading = (props: ModalHeadingProps) => {
	const { children, onClose } = props;
	const { t } = useTranslation();

	const handleClose = (e: React.MouseEvent) => {
		e.preventDefault();
		onClose();
	};

	return (
		<StyledModalHeading>
			<h2>{children}</h2>
			<Button aria-label={t('aria.close')} variant="icon" onClick={handleClose}>
				<CloseIcon />
			</Button>
		</StyledModalHeading>
	);
};
