import React from 'react';
import { SVG, SVGProps } from './SVG';

export const InfoIcon = (props: SVGProps) => (
	<SVG focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect width="18" height="18" />
			<path d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0Zm.9,14.25H8.1v-6H9.9Zm-.9-8A1.25,1.25,0,1,1,10.25,5,1.25,1.25,0,0,1,9,6.25Z" />
		</g>
	</SVG>
);
