import React, { useState } from 'react';
import { FxRateMap } from 'types';
import { styled } from 'utils';
import { useTranslation } from 'utils/i18next';
import { AddIcon } from 'components/icons';
import {
	Button,
	Flex,
	Section,
	CountdownIndicator,
	NetworkErrorNotification,
	Time
} from 'components/common';
import { LiveExchangeRatesNewRow } from './LiveExchangeRatesNewRow';
import { LiveExchangeRatesTable } from './LiveExchangeRatesTable';

const AddRowBtn = styled(Button)`
	border: none;
`;

interface LiveExchangeRatesProps {
	ccyPairs: string[];
	fxRates: FxRateMap;
	flash: boolean;
	isTimerEnabled: boolean;
	loadingState: string;
	error: string;
	onErrorCancel: () => void;
	onRateClick: (ccyPair: string) => void;
	onRateAdd: (ccyPair: string) => void;
	onRateInvert: (ccyPair: string) => void;
	onRateRemove: (ccyPair: string) => void;
	onDataRefresh: () => Promise<void>;
	refreshRatesInterval: number;
	initialRates?: string[];
	maxListSize?: number;
}

export const LiveExchangeRates = (props: LiveExchangeRatesProps) => {
	const {
		ccyPairs,
		fxRates,
		flash,
		isTimerEnabled,
		loadingState,
		error,
		onErrorCancel,
		onRateAdd,
		onRateInvert,
		onRateRemove,
		onRateClick,
		onDataRefresh,
		refreshRatesInterval,
		maxListSize = Number.MAX_VALUE
	} = props;
	const [newRowEntryModeOn, setNewRowEntryMode] = useState(false);
	const { t } = useTranslation();

	const getLastUpdateTimestamp = () => {
		const firstCcyPair = ccyPairs[0];
		const fxRate = fxRates[firstCcyPair];

		if (fxRate?.latest?.timestamp) {
			return fxRate.latest.timestamp;
		}

		return 0;
	};

	const lastUpdateTimestamp = getLastUpdateTimestamp();

	return (
		<Section data-testid="live-exchange-rates" data-nosnippet>
			<Flex justifyContent="space-between" flexWrap="wrap" gridGap="large">
				<h2>{t('watchlist.title')}</h2>
				<CountdownIndicator ms={refreshRatesInterval} enabled={isTimerEnabled} />
			</Flex>
			<p>{t('watchlist.heading')}</p>
			{error && (
				<NetworkErrorNotification onCancel={onErrorCancel} onRefresh={onDataRefresh}>
					{error}
				</NetworkErrorNotification>
			)}
			<LiveExchangeRatesTable
				flash={flash}
				fxRates={fxRates}
				ccyPairs={ccyPairs}
				loadingState={loadingState}
				rowOnClick={onRateClick}
				invertBtnOnClick={onRateInvert}
				deleteBtnOnClick={onRateRemove}
			/>
			<Flex justifyContent="space-between" flexWrap="wrap" gridGap="large">
				{newRowEntryModeOn ? (
					<LiveExchangeRatesNewRow
						addRate={ccyPair => {
							setNewRowEntryMode(false);
							onRateAdd(ccyPair);
						}}
					/>
				) : (
					ccyPairs.length < maxListSize && (
						<AddRowBtn
							data-testid="live-exchange-rates-add-row-btn"
							variant="tertiary"
							onClick={() => setNewRowEntryMode(true)}
						>
							<Flex gridGap="small" alignItems="center">
								<AddIcon size="small" />
								{t('watchlist.addRowBtn')}
							</Flex>
						</AddRowBtn>
					)
				)}
				{!!lastUpdateTimestamp && (
					<Time
						data-testid="live-exchange-rates-last-update-timestamp"
						ts={lastUpdateTimestamp}
					/>
				)}
			</Flex>
		</Section>
	);
};
