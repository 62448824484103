import React, { useState } from 'react';
import { useTranslation } from 'utils/i18next';
import { FxTrendsCcy, FxTrendsCcyDirections } from 'types';
import { Flex } from '../common';
import { ResearchSection } from './ResearchSection';
import { FxTrendsPubDate } from './FxTrendsPubDate';
import { FxTrendsTitle } from './FxTrendsTitle';
import { FxTrendsBrief } from './FxTrendsBrief';
import { FxTrendsCurrencies } from './FxTrendsCurrencies';
import { FxTrendsDirections } from './FxTrendsDirections';
import { FxTrendsOverview } from './FxTrendsOverview';
import { FxTrendsAccordions } from './FxTrendsAccordions';

const fxTrends: FxTrendsCcyDirections = {
	USD: {
		direction: 'DXY^',
		current: 'up',
		previous: 'up'
	},
	EUR: {
		direction: 'EUR-USD',
		current: 'down',
		previous: 'down'
	},
	GBP: {
		direction: 'GBP-USD',
		current: 'down',
		previous: 'sideways'
	},
	JPY: {
		direction: 'USD-JPY',
		current: 'up',
		previous: 'up'
	},
	CHF: {
		direction: 'USD-CHF',
		current: 'up',
		previous: 'up'
	},
	CAD: {
		direction: 'USD-CAD',
		current: 'sideways',
		previous: 'down'
	},
	AUD: {
		direction: 'AUD-USD',
		current: 'sideways',
		previous: 'up'
	},
	NZD: {
		direction: 'NZD-USD',
		current: 'down',
		previous: 'up'
	}
};

export const FxTrends = () => {
	const { t } = useTranslation('research');
	const [selectedCcy, setSelectedCcy] = useState<FxTrendsCcy>('USD');
	const { direction, current, previous } = fxTrends[selectedCcy];
	const ccys = Object.keys(fxTrends) as FxTrendsCcy[];

	return (
		<ResearchSection
			flex={1}
			heading={t('fxTrends.heading')}
			tooltip={t('fxTrends.headingTooltip')}
			tooltipAriaLabel={t('fxTrends.headingTooltipAriaLabel')}
		>
			<FxTrendsPubDate>21 October 2024</FxTrendsPubDate>
			<FxTrendsTitle>{t('fxTrends.title')}</FxTrendsTitle>
			<FxTrendsBrief />
			<p>{t('fxTrends.selectCurrency')}</p>
			<FxTrendsCurrencies ccy={selectedCcy} ccys={ccys} onCcyChange={setSelectedCcy} />
			<Flex gridGap="xxlarge">
				<FxTrendsDirections
					ccy={selectedCcy}
					current={current}
					previous={previous}
					direction={direction}
				/>
				<Flex>{/*	todo: month button */}</Flex>
			</Flex>
			<FxTrendsOverview ccy={selectedCcy} />
			<FxTrendsAccordions />
		</ResearchSection>
	);
};
