import { CurrencyConfig } from 'types';

export const currencyCodesG10 = [
	'USD',
	'GBP',
	'EUR',
	'JPY',
	'CAD',
	'CHF',
	'NOK',
	'SEK',
	'AUD',
	'NZD',
	'HKD',
	'INR',
	'CNH'
] as const;

export const disabledCurrencies = {
	ARS: ['AED', 'INR', 'PHP'],
	BGN: ['GBP'],
	CZK: ['BRL', 'CNY', 'PEN'],
	HKD: ['PEN'],
	HRK: ['CAD'],
	HUF: ['ARS', 'BRL', 'CNY', 'INR', 'PEN', 'PHP', 'TWD'],
	IDR: ['RUB'],
	JPY: ['HRK', 'BGN', 'RON'],
	KRW: ['RUB'],
	MXN: ['PEN'],
	NOK: ['PEN'],
	RSD: ['CAD', 'CHF', 'EUR', 'GBP', 'USD'],
	RUB: ['BRL', 'PEN'],
	SEK: ['PEN'],
	THB: ['BRL', 'PEN'],
	TRY: ['BRL', 'PEN'],
	VND: ['AED'],
	ZAR: ['BRL', 'PEN']
};

export const currencyCodes = [
	'AED',
	'ALL',
	'AMD',
	'ARS',
	'BBD',
	'BDT',
	'BGN',
	'BHD',
	'BMD',
	'BND',
	'BRL',
	'BSD',
	'BWP',
	'CLP',
	'CNY',
	'COP',
	'CZK',
	'DKK',
	'DZD',
	'EGP',
	'FJD',
	'GHS',
	'HRK',
	'HUF',
	'IDR',
	'ILS',
	'JMD',
	'JOD',
	'KES',
	'KRW',
	'KWD',
	'KYD',
	'KZT',
	'LKR',
	'LSL',
	'MAD',
	'MOP',
	'MUR',
	'MWK',
	'MXN',
	'MYR',
	'MZN',
	'NAD',
	'NGN',
	'OMR',
	'PEN',
	'PHP',
	'PKR',
	'PLN',
	'QAR',
	'RON',
	'RSD',
	'RUB',
	'SAR',
	'SCR',
	'SGD',
	'SZL',
	'THB',
	'TND',
	'TRY',
	'TTD',
	'TWD',
	'TZS',
	'UGX',
	'UYU',
	'VND',
	'XAF',
	'XCD',
	'XOF',
	'ZAR',
	'ZMW'
] as const;

export const metalCodes = ['XAU', 'XAG', 'XPT', 'XPD'] as const;

export const availableCurrencies = [...currencyCodesG10, ...currencyCodes, ...metalCodes];

export const ccyConfig: CurrencyConfig = {
	GBP: {
		symbol: '£',
		decimals: 2
	},
	EUR: {
		symbol: '€',
		decimals: 2
	},
	USD: {
		symbol: '$',
		decimals: 2
	},
	JPY: {
		symbol: '¥',
		decimals: 0
	},
	CAD: {
		symbol: 'CA$',
		decimals: 2
	},
	CHF: {
		symbol: '₣',
		decimals: 2
	},
	NOK: {
		symbol: 'kr',
		decimals: 2
	},
	SEK: {
		symbol: 'kr',
		decimals: 2
	},
	PEN: {
		symbol: 'S/',
		decimals: 2
	},
	AUD: {
		symbol: 'A$',
		decimals: 2
	},
	NZD: {
		symbol: 'NZ$',
		decimals: 2
	},
	HKD: {
		symbol: 'HK$',
		decimals: 2
	},
	INR: {
		symbol: '₹',
		decimals: 2
	},
	CNH: {
		symbol: '¥',
		decimals: 2
	},
	AED: {
		symbol: 'د.إ',
		decimals: 2
	},
	ALL: {
		symbol: 'L',
		decimals: 2
	},
	AMD: {
		symbol: '֏',
		decimals: 2
	},
	ARS: {
		symbol: '$',
		decimals: 2
	},
	BBD: {
		symbol: 'Bds$',
		decimals: 2
	},
	BDT: {
		symbol: '৳',
		decimals: 2
	},
	BGN: {
		symbol: 'Лв.',
		decimals: 2
	},
	BHD: {
		symbol: '.د.ب',
		decimals: 3
	},
	BMD: {
		symbol: 'BD$',
		decimals: 2
	},
	BND: {
		symbol: 'B$',
		decimals: 2
	},
	BRL: {
		symbol: 'R$',
		decimals: 2
	},
	BSD: {
		symbol: 'B$',
		decimals: 2
	},
	BWP: {
		symbol: 'P',
		decimals: 2
	},
	CLP: {
		symbol: '$',
		decimals: 0
	},
	CNY: {
		symbol: '¥',
		decimals: 2
	},
	COP: {
		symbol: '$',
		decimals: 2
	},
	CZK: {
		symbol: 'Kč',
		decimals: 2
	},
	DKK: {
		symbol: 'kr',
		decimals: 2
	},
	DZD: {
		symbol: 'دج',
		decimals: 2
	},
	EGP: {
		symbol: 'E£',
		decimals: 2
	},
	FJD: {
		symbol: 'FJ$',
		decimals: 2
	},
	GHS: {
		symbol: 'GH￠',
		decimals: 2
	},
	HRK: {
		symbol: 'kn',
		decimals: 2
	},
	HUF: {
		symbol: 'Ft',
		decimals: 2
	},
	IDR: {
		symbol: 'Rp',
		decimals: 2
	},
	ILS: {
		symbol: '₪',
		decimals: 2
	},
	JMD: {
		symbol: 'J$',
		decimals: 2
	},
	JOD: {
		symbol: 'د.ا',
		decimals: 3
	},
	KES: {
		symbol: 'KSh',
		decimals: 2
	},
	KRW: {
		symbol: '₩',
		decimals: 0
	},
	KWD: {
		symbol: 'KD',
		decimals: 3
	},
	KYD: {
		symbol: 'CI$',
		decimals: 2
	},
	KZT: {
		symbol: '₸',
		decimals: 2
	},
	LKR: {
		symbol: 'Rs',
		decimals: 2
	},
	LSL: {
		symbol: 'L',
		decimals: 2
	},
	MAD: {
		symbol: 'DH',
		decimals: 2
	},
	MOP: {
		symbol: 'MOP$',
		decimals: 2
	},
	MUR: {
		symbol: 'Rs',
		decimals: 2
	},
	MWK: {
		symbol: 'MK',
		decimals: 2
	},
	MXN: {
		symbol: '$',
		decimals: 2
	},
	MYR: {
		symbol: 'RM',
		decimals: 2
	},
	MZN: {
		symbol: 'MT',
		decimals: 2
	},
	NAD: {
		symbol: 'N$',
		decimals: 2
	},
	NGN: {
		symbol: '₦',
		decimals: 2
	},
	OMR: {
		symbol: 'ر.ع.',
		decimals: 3
	},
	PHP: {
		symbol: '₱',
		decimals: 2
	},
	PKR: {
		symbol: 'Rs',
		decimals: 2
	},
	PLN: {
		symbol: 'zł',
		decimals: 2
	},
	QAR: {
		symbol: 'ر.ق',
		decimals: 2
	},
	RON: {
		symbol: 'lei',
		decimals: 2
	},
	RSD: {
		symbol: 'din',
		decimals: 2
	},
	RUB: {
		symbol: '₽',
		decimals: 2
	},
	SAR: {
		symbol: '﷼',
		decimals: 2
	},
	SCR: {
		symbol: 'Rs',
		decimals: 2
	},
	SGD: {
		symbol: 'S$',
		decimals: 2
	},
	SZL: {
		symbol: 'E',
		decimals: 2
	},
	THB: {
		symbol: '฿',
		decimals: 2
	},
	TND: {
		symbol: 'د.ت',
		decimals: 3
	},
	TRY: {
		symbol: '₺',
		decimals: 2
	},
	TTD: {
		symbol: 'TT$',
		decimals: 2
	},
	TWD: {
		symbol: 'NT$',
		decimals: 2
	},
	TZS: {
		symbol: 'TSh',
		decimals: 2
	},
	UGX: {
		symbol: 'USh',
		decimals: 0
	},
	UYU: {
		symbol: '$U',
		decimals: 2
	},
	VND: {
		symbol: '₫',
		decimals: 0
	},
	XAF: {
		symbol: 'FCFA',
		decimals: 0
	},
	XCD: {
		symbol: 'EC$',
		decimals: 2
	},
	XOF: {
		symbol: 'CFA',
		decimals: 0
	},
	ZAR: {
		symbol: 'R',
		decimals: 2
	},
	ZMW: {
		symbol: 'ZK',
		decimals: 2
	},
	XAU: {
		symbol: 'oz'
	},
	XAG: {
		symbol: 'oz'
	},
	XPT: {
		symbol: 'oz'
	},
	XPD: {
		symbol: 'oz'
	}
};
