export * from 'utils/styles';

// @ts-ignore
const getDOMRect = (id: string) => document.getElementById(id).getBoundingClientRect();

export const scrollTo = (id: string, offset = 30) => {
	window.scrollTo({
		behavior: 'smooth',
		top: getDOMRect(id).top + window.scrollY - offset
	});
};
