import React from 'react';
import { styled } from 'utils/styles';
import { variant } from 'styled-system';
import { useTranslation } from 'utils/i18next';
import { CloseIcon } from 'components/icons';
import { ErrorIcon } from 'components/icons/ErrorIcon';
import { WarningIcon } from 'components/icons/WarningIcon';
import { SuccessIcon } from 'components/icons/SuccessIcon';
import { InfoIcon } from 'components/icons/InfoIcon';
import { Button } from './Button';
import { Flex } from './Grid';

type NotificationType = 'error' | 'warning' | 'success' | 'info';
type NotificationIcon = {
	[key in NotificationType]: React.ReactNode;
};

export interface NotificationProps {
	children: React.ReactNode;
	onCancel?: () => void;
	type?: NotificationType;
}

export const StyledNotification = styled(Flex)(
	variant({
		prop: 'type',
		scale: 'notifications'
	})
);

const notificationIcon: NotificationIcon = {
	error: <ErrorIcon />,
	warning: <WarningIcon />,
	success: <SuccessIcon />,
	info: <InfoIcon />
};

export const Notification = (props: NotificationProps) => {
	const { type = 'info', children, onCancel } = props;
	const { t } = useTranslation();

	return (
		<StyledNotification
			type={type}
			role="status"
			alignItems="center"
			justifyContent="space-between"
			px="large"
			py="medium"
			my="small"
		>
			{notificationIcon[type]}
			<Flex gridGap="medium" flex={1} marginX="medium" justifyContent="space-between">
				{children}
			</Flex>
			{onCancel && (
				<Button aria-label={t('aria.close')} variant="icon" onClick={onCancel}>
					<CloseIcon verticalAlign="middle" />
				</Button>
			)}
		</StyledNotification>
	);
};
