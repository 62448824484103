import React from 'react';
import ReactSelect, {
	components,
	DropdownIndicatorProps,
	GroupBase,
	Props as ReactSelectProps
} from 'react-select';
import { useTheme } from 'utils/styles';
import { ChevronIcon } from 'components/icons';

export * from 'react-select';

export type SelectOption<T extends string = string> = {
	value: T;
	label: string;
};

export type SelectGroupedOption<T extends string> = GroupBase<SelectOption<T>>;

const DropdownIndicator = <Option extends SelectOption, IsMulti extends boolean = false>(
	props: DropdownIndicatorProps<Option, IsMulti>
) => (
	<components.DropdownIndicator {...props}>
		<ChevronIcon size="small" />
	</components.DropdownIndicator>
);

export const Select = <Option extends SelectOption = SelectOption, IsMulti extends boolean = false>(
	props: ReactSelectProps<Option, IsMulti>
) => {
	const hsbcTheme = useTheme();

	return (
		<ReactSelect
			{...props}
			styles={{
				control: (provided, state) => ({
					...provided,
					height: '100%',
					boxShadow: 'none',
					cursor: 'pointer',
					outline: state.isFocused ? '1px solid auto' : 'none',
					outlineStyle: state.isFocused ? 'auto' : 'none'
				}),
				groupHeading: provided => ({
					...provided,
					color: hsbcTheme.colors.typography
				}),
				menuList: provided => ({
					...provided,
					padding: 0
				}),
				menu: provided => ({
					...provided,
					margin: 0,
					border: `1px solid ${hsbcTheme.colors.grey4}`,
					boxShadow: 'none'
				}),
				option: (provided, state) => ({
					...provided,
					cursor: 'pointer',
					color: state.isDisabled ? hsbcTheme.colors.grey4 : hsbcTheme.colors.typography,
					borderBottom: `1px solid ${hsbcTheme.colors.grey2}`,
					backgroundColor: state.isFocused ? hsbcTheme.colors.grey1 : 'transparent',

					':hover': {
						backgroundColor: hsbcTheme.colors.grey1
					}
				}),
				indicatorSeparator: () => ({
					display: 'none'
				}),
				...props.styles
			}}
			theme={theme => ({
				...theme,
				borderRadius: 0,
				borderWidth: '1px',
				colors: {
					...theme.colors,
					primary: 'transparent',
					primary25: 'transparent',
					primary50: 'transparent',
					primary75: hsbcTheme.colors.grey3,
					neutral10: hsbcTheme.colors.typography,
					neutral20: hsbcTheme.colors.grey5,
					neutral30: hsbcTheme.colors.grey5,
					neutral50: hsbcTheme.colors.typography
				}
			})}
			components={{ DropdownIndicator, ...props.components }}
		/>
	);
};
