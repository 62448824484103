import React from 'react';
import { SVG, SVGProps } from './SVG';

export const ChevronThickIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect width="18" height="18" />
			<polygon points="3.07 18 12.07 9 3.07 0 5.93 0 14.93 9 5.93 18 3.07 18" />
		</g>
	</SVG>
);
