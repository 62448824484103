import React from 'react';
import { css, styled, typography } from 'utils/styles';
import { ChevronIcon } from 'components/icons';

interface AccordionProps {
	summary: React.ReactNode;
	children: React.ReactNode;
}

const StyledAccordion = styled.details(
	({ theme: { colors } }) => css`
		border-bottom: 1px solid ${colors.grey3};

		p {
			${typography.s6}
			text-align: justify;
			margin: 0.75rem 0;

			svg {
				width: 14px;
				height: 14px;
			}
		}

		h4 {
			margin: 20px 0 0 0;
		}

		ol {
			${typography.s6}
			padding-left: 15px;
		}
	`
);

const AccordionSummary = styled.summary(
	({ theme: { colors } }) => css`
		cursor: pointer;
		padding: 10px 0 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1,
		h2,
		h3,
		h4,
		h5 {
			margin: inherit;
			font-size: inherit;
			font-weight: inherit;
		}

		&:active {
			background: ${colors.grey2};
		}
		&:hover,
		&:focus-within {
			background: ${colors.grey1};
		}

		svg {
			margin-right: 4px;
			transition: transform 0.5s;
			transform: rotate(0);
		}

		${StyledAccordion}[open] & svg {
			transform: rotate(180deg);
		}

		&::marker {
			content: '';
		}

		&::-webkit-details-marker {
			display: none;
		}
	`
);

export const Accordion = (props: AccordionProps) => {
	const { summary, children } = props;

	return (
		<StyledAccordion>
			<AccordionSummary>
				{summary}
				<ChevronIcon size="medium" />
			</AccordionSummary>
			{children}
		</StyledAccordion>
	);
};
