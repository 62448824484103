import React from 'react';
import { styled, css, mq } from 'utils/styles';
import { BankingRelationship, UserLocation } from 'types';
import { useBankingSolutions } from 'hooks/useBankingSolutions';
import { BankingSolution } from './BankingSolution';

interface BankingSolutionsProps {
	bankingRelationship: BankingRelationship;
	userLocation: UserLocation;
}

const GridContainer = styled.div(
	({ theme: { space } }) => css`
		display: grid;
		gap: 40px 20px;
		grid-auto-flow: row;

		label {
			white-space: nowrap;
		}

		${mq({
			gridTemplateColumns: [
				'repeat(1, minmax(150px, 1fr))',
				'repeat(2, minmax(150px, 1fr))',
				'repeat(3, minmax(150px, 1fr))'
			],
			marginTop: [space.large],
			marginBottom: [space.large, space.none]
		})}
	`
);

export const BankingSolutions = (props: BankingSolutionsProps) => {
	const { bankingRelationship, userLocation } = props;
	const solutions = useBankingSolutions(bankingRelationship, userLocation);

	if (solutions.length === 0) {
		return null;
	}

	return (
		<>
			<h2>Take a look at some of our featured solutions</h2>
			<GridContainer>
				{solutions.map(item => (
					<BankingSolution key={item.title} {...item} />
				))}
			</GridContainer>
		</>
	);
};
