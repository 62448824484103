import { EVENTS } from './types';

const logMechanism = message => {
	try {
		if (message.message === EVENTS.LIFECYCLE.APP_LOAD) {
			globalThis?.TMS?.trackView({
				page_name: 'currency-zone',
				page_url: '/'
			});
		}
		if (message?.message?.hasOwnProperty('event_category')) {
			globalThis?.TMS?.trackEvent(message.message);
		} else {
			globalThis?.TMS?.trackEvent({
				event_category: 'content',
				event_action: message.message,
				event_content: message.data
			});
		}
	} catch (e) {
		console.error('Problem with activity logger', e);
	}
};

export default logMechanism;
