import React from 'react';
import { styled } from 'utils';
import { layout, typography, LayoutProps, TypographyProps, SpaceProps, space } from 'styled-system';
import { ccyConfig } from 'common/currencies';
import { Currency } from 'types';

const symbolFontSize = ['1.1875rem', '1.1875rem', '1rem', '0.8125rem', '0.6875rem'];

interface CurrencySymbolProps extends LayoutProps, TypographyProps, SpaceProps {
	ccyCode: Currency;
}

const Symbol = styled.span<LayoutProps & TypographyProps>`
	display: inline-block;
	width: 32px;
	text-align: right;
	box-sizing: content-box;

	${space}
	${layout}
	${typography}
`;

export const CurrencySymbol = (props: CurrencySymbolProps) => {
	const { ccyCode, ...styles } = props;
	const { symbol } = ccyConfig[ccyCode];
	const fontSize = symbolFontSize[symbol?.length];

	return (
		<Symbol fontSize={fontSize} {...styles}>
			{symbol || ''}
		</Symbol>
	);
};
