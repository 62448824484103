import React, { useEffect } from 'react';
import { css, mq, styled } from 'utils/styles';

const ModalContainer = styled.div`
	z-index: 1001;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: left;
`;

const ModalOverlay = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: block;
	left: 0;
`;

const ModalWindow = styled.div(
	({ theme: { colors } }) => css`
		max-width: 970px;
		position: fixed;
		margin: 0;
		top: 50%;
		left: 50%;
		overflow: auto;
		max-height: 100vh;
		transform: translate(-50%, -50%);
		z-index: 3002;
		color: ${colors.typography};
		border-radius: 3px;
		background: ${colors.white};
		display: block;
		padding: 35px;
		${mq({
			width: ['100%', '100%', '70%', '70%']
		})};
	`
);

export interface ModalProps {
	children: React.ReactNode;
	maxWidth?: string;
}

export const Modal = (props: ModalProps) => {
	const { children, maxWidth = '100%' } = props;

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'auto';
		};
	});

	return (
		<ModalContainer data-component="Modal" data-testid="modal">
			<ModalOverlay aria-hidden={false} data-testid="modal-overlay">
				<ModalWindow style={{ maxWidth }}>{children}</ModalWindow>
			</ModalOverlay>
		</ModalContainer>
	);
};
