import React, { createContext, ReactNode, useContext } from 'react';
import { Env, EnvGroup, FeatureToggleEnv } from 'types';

interface FeatureToggleProviderProps {
	children: ReactNode;
	env?: Env;
	envGroup?: EnvGroup;
}

export interface FeatureToggleContext {
	env: Env;
	envGroup: EnvGroup;
}

const featureToggleContext = createContext<FeatureToggleContext>({
	env: 'dev',
	envGroup: 'currencyzone-internal'
});

export const FeatureToggleProvider = (props: FeatureToggleProviderProps) => {
	const { env: _env = 'dev', envGroup: _envGroup = 'currencyzone-internal' } =
		global.initial_state || {};
	const { children, env = _env, envGroup = _envGroup } = props;

	return (
		<featureToggleContext.Provider value={{ env, envGroup }}>
			{children}
		</featureToggleContext.Provider>
	);
};

export const useFeatureToggleEnv = (): FeatureToggleEnv => {
	const { env, envGroup } = useContext(featureToggleContext) || {};

	if (!env || !envGroup) {
		throw new Error('FeatureToggleContext was not provided');
	}

	return [envGroup, env].join('-') as FeatureToggleEnv;
};
