import React, { useState } from 'react';
import { css, mq, styled } from 'utils/styles';
import { SendEvent } from 'utils/logging/types';
import { useBreakpoint, useOutsideClick } from 'hooks';
import { layout, LayoutProps } from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Button } from 'components/common';
import { NewWindow, SVGProps, ChevronIcon } from '../icons';
import { MenuItem } from 'types';

interface DropdownMenuItemProps {
	item: MenuItem;
	depthLevel: number;
	sendEvent: SendEvent;
}

const StyledDropdownMenuItem = styled.li<{ ref: React.RefObject<HTMLElement> }>`
	position: relative;
`;

const DropdownButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	padding: 0.7rem 1rem;
	width: 100%;

	&:hover {
		background-color: ${({ theme }) => theme.colors.grey1};
	}
`;

const DropdownLink = styled.a`
	display: block;
	white-space: nowrap;
	color: ${({ theme }) => theme.colors.grey5};
	padding: 0.7rem 1rem;
	text-decoration: none;

	&:hover {
		background-color: ${({ theme }) => theme.colors.grey1};
	}
`;

const DropdownMenuItem = (props: DropdownMenuItemProps) => {
	const { item, depthLevel, sendEvent } = props;
	const [dropdown, setDropdown] = useState(false);
	const dropdownRef = useOutsideClick(() => setDropdown(false), true);
	const { bp } = useBreakpoint();

	return (
		<StyledDropdownMenuItem
			// @ts-ignore
			ref={dropdownRef}
			onMouseEnter={() => bp !== 'mobile' && bp !== 'tablet' && setDropdown(true)}
			onMouseLeave={() => bp !== 'mobile' && bp !== 'tablet' && setDropdown(false)}
		>
			{!item.href && item.submenu ? (
				<div id="menu-id" role="menu">
					<DropdownButton
						type="button"
						aria-haspopup="menu"
						aria-expanded={dropdown ? 'true' : 'false'}
						onClick={() => setDropdown(prev => !prev)}
					>
						{item.label}
					</DropdownButton>
					{dropdown && (
						<DropdownMenuList
							depthLevel={depthLevel}
							items={item.submenu}
							sendEvent={sendEvent}
						/>
					)}
				</div>
			) : (
				<DropdownLink
					href={item.href}
					target="_blank"
					onClick={() => {
						if (item.trackingEvent) {
							sendEvent(item.trackingEvent);
						}
					}}
				>
					<NewWindow size="small" mr="small" />
					{item.label}
				</DropdownLink>
			)}
		</StyledDropdownMenuItem>
	);
};

interface DropdownMenuListProps {
	items: MenuItem[];
	depthLevel: number;
	sendEvent: SendEvent;
}

const StyledDropdownMenuList = styled.ul<{ depthLevel: number }>(
	({ depthLevel }) => css`
		position: absolute;
		top: ${depthLevel > 1 ? '0' : '100%'};
		box-shadow:
			0 10px 15px -3px rgba(46, 41, 51, 0.08),
			0 4px 6px -2px rgba(71, 63, 79, 0.16);
		font-size: 0.875rem;
		z-index: 9999;
		min-width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		background-color: #fff;

		${mq({
			right: [0, 0, depthLevel > 1 ? '100%' : 'auto']
		})}
	`
);

const DropdownMenuList = (props: DropdownMenuListProps) => {
	const { items, sendEvent, depthLevel = 0 } = props;

	return (
		<StyledDropdownMenuList depthLevel={depthLevel + 1}>
			{items.map((item, idx) => (
				<DropdownMenuItem
					key={idx}
					item={item}
					depthLevel={depthLevel + 1}
					sendEvent={sendEvent}
				/>
			))}
		</StyledDropdownMenuList>
	);
};

interface DropdownMenuProps extends LayoutProps {
	menuItems: MenuItem[];
	sendEvent: SendEvent;
}

const StyledChevronIcon = styled(ChevronIcon)<{ dropdown: boolean & SVGProps }>`
	transition: transform 0.5s;
	transform: ${({ dropdown }) => (dropdown ? 'rotate(180deg)' : 'rotate(0)')};
`;

const StyledDropdownMenu = styled('div', { shouldForwardProp })<{
	ref: React.RefObject<HTMLDivElement>;
}>`
	position: relative;
	margin: auto 0;

	${layout}
`;

export const DropdownMenu = (props: DropdownMenuProps) => {
	const { menuItems, sendEvent, ...layoutProps } = props;
	const [dropdown, setDropdown] = useState(false);
	const dropdownRef = useOutsideClick<HTMLDivElement>(() => setDropdown(false), true);

	return (
		<div>
			<StyledDropdownMenu ref={dropdownRef} {...layoutProps}>
				<Button
					size="small"
					aria-expanded={dropdown ? 'true' : 'false'}
					onClick={() => setDropdown(prev => !prev)}
				>
					Log on
					<StyledChevronIcon
						size="small"
						marginLeft="small"
						verticalAlign="middle"
						dropdown={dropdown}
					/>
				</Button>
				{dropdown && (
					<DropdownMenuList items={menuItems} depthLevel={0} sendEvent={sendEvent} />
				)}
			</StyledDropdownMenu>
		</div>
	);
};
