import React from 'react';
import { css, styled } from 'utils/styles';

const StyledCaptcha = styled.div(
	({ theme: { space } }) => css`
		border: 1px solid black;
		padding: ${space.medium};
		width: 150px;
		align-items: center;
		margin: ${space.large} 0;
	`
);
export const RateAlertCaptcha = () => <StyledCaptcha>I'm not a robot</StyledCaptcha>;
