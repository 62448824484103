import React, { useState } from 'react';
import { Input, InputProps } from './Input';
import { formatAmount } from 'utils/formatters';
import { Decimal } from 'utils/currency';

const MAX_AMOUNT = new Decimal(1e12);

interface AmountInputProps extends Omit<InputProps, 'onChange'> {
	value: number;
	onChange: (value: number) => void;
	onValidate?: (error: boolean) => void;
}

const INPUT_FORMAT = /^\d+(?:,\d{3})*(?:\.\d+)?[btkm]?$/i;
const DECIMAL_ZERO = /^\d+(?:,\d{3})*\.0$/i;
const amountShortCodes: { [key: string]: Decimal } = {
	b: new Decimal(1e9),
	m: new Decimal(1e6),
	k: new Decimal(1e3),
	t: new Decimal(1e3),
	B: new Decimal(1e9),
	M: new Decimal(1e6),
	K: new Decimal(1e3),
	T: new Decimal(1e3)
};

const convertAmountShortCode = (value: string): Decimal => {
	const amount = value.replace(/,/g, '');
	const shortCode = amount.slice(-1);

	if (Object.keys(amountShortCodes).includes(shortCode)) {
		return parseAmount(amount.substring(0, amount.length - 1)).times(
			amountShortCodes[shortCode]
		);
	}

	return parseAmount(amount);
};

const parseAmount = (value: string): Decimal => {
	const amountDecimal = new Decimal(value.replace(/,/g, ''));
	if (amountDecimal.greaterThan(MAX_AMOUNT)) {
		return MAX_AMOUNT;
	}
	return amountDecimal;
};

const AmountInput = ({ onValidate, onChange, ...props }: AmountInputProps) => {
	const [value, setValue] = useState<string>(props.value ? formatAmount(props.value) : '');
	const [error, setError] = useState<boolean>(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const isInputValid = INPUT_FORMAT.test(value);
		const isDecimalZero = DECIMAL_ZERO.test(value);

		if (isInputValid && !isDecimalZero) {
			const amount = convertAmountShortCode(value);
			setValue(amount.toString());
			onChange(amount.toNumber());
		} else {
			setValue(value);
		}

		setError(!isInputValid);
		onValidate && onValidate(!isInputValid);
	};

	const handleBlur = () => {
		!error && setValue(formatAmount(parseAmount(value)));
	};

	return (
		<Input
			{...props}
			type="text"
			value={value}
			isError={error}
			onBlur={handleBlur}
			onChange={handleChange}
		/>
	);
};

export default AmountInput;
