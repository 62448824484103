import React, { useEffect, useRef, useState } from 'react';
import { formatDateTime } from 'utils/date';
import { formatAmount } from 'utils/formatters';
import { useTranslation } from 'utils/i18next';
import { FxRate, Rate } from 'types';
import { Chart, EmptyBox, EmptyLine, EmptyState, Loader } from 'components/common';
import { createChartLabel, peggedCurrencies } from 'common/chart';
import { ChartRangeSelector } from './common/ChartRangeSelector';
import { splitCcyPair } from 'utils/currency';
import { flashAnimation, mq, styled, useTheme } from 'utils/styles';

interface CurrencyRateChartProps {
	isLoading: boolean;
	flash: boolean;
	fxRate: FxRate | null;
}

const ChartContainer = styled.div`
	position: relative;
	margin-top: ${({ theme }) => theme.space.medium};

	${flashAnimation}
	${mq({
		height: ['200px', '250px', '270px']
	})}
`;

export const CurrencyRateChart = React.memo((props: CurrencyRateChartProps) => {
	const { isLoading, flash, fxRate } = props;
	const chartRef = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	const { t, i18n } = useTranslation();
	const [dataSet, setDataSet] = useState<Rate[]>([]);
	const [ccyFrom, ccyTo] = splitCcyPair(fxRate?.ccyPair || '');

	useEffect(() => {
		if (fxRate) {
			setDataSet(fxRate.dataSet);
		}
	}, [fxRate]);

	const renderChart = () => {
		if (isLoading) {
			return <Loader textDelay={3000} />;
		} else if (!fxRate || fxRate.dataSet.length === 0) {
			return (
				<EmptyState width="100%" height="100%" justifyContent="center">
					<EmptyBox width="100%" height="90%" />
				</EmptyState>
			);
		} else {
			return (
				<Chart
					id="currency-rate-chart"
					data-testid="currency-rate-chart"
					role="img"
					config={{
						type: 'line',
						data: {
							labels: dataSet.map(({ timestamp }) =>
								createChartLabel(timestamp, fxRate.range, i18n.language)
							),
							datasets: [
								{
									data: dataSet.map(({ open }) => open),
									tension: 0.4,
									fill: true,
									backgroundColor: '#469cbe5c',
									borderColor: theme.colors.data.blue4,
									pointHoverBackgroundColor: theme.colors.data.blue4,
									pointHoverBorderWidth: 4,
									spanGaps: true
								}
							]
						},
						options: {
							animation: false,
							responsive: true,
							maintainAspectRatio: false,
							scales: {
								xAxis: {
									grid: {
										borderWidth: 1,
										borderColor: theme.colors.typography,
										tickColor: theme.colors.typography,
										drawOnChartArea: false,
										tickWidth: 1,
										tickLength: 8
									},
									ticks: {
										source: 'labels',
										autoSkipPadding: 24,
										align: 'start',
										padding: 4,
										maxRotation: 0,
										minRotation: 0,
										color: theme.colors.typography
									}
								},
								yAxis: {
									position: 'left',
									min: peggedCurrencies.find(ccy => ccy === fxRate?.ccyPair)
										? 0
										: undefined,
									suggestedMin: fxRate.low || undefined,
									suggestedMax: fxRate.high || undefined,
									grid: {
										borderDash: [7, 5],
										color: theme.colors.grey4,
										tickBorderDash: [],
										drawTicks: false,
										drawBorder: false,
										lineWidth: 1
									},
									ticks: {
										align: 'end',
										labelOffset: -4,
										mirror: true,
										count: 3,
										color: theme.colors.typography,
										backdropPadding: 100,
										callback: value =>
											formatAmount(
												typeof value === 'string' ? parseInt(value) : value,
												fxRate?.decimalPlaces
											)
									}
								}
							},
							elements: {
								point: {
									radius: 0
								}
							},
							plugins: {
								tooltip: {
									backgroundColor: theme.colors.white,
									bodyColor: theme.colors.typography,
									titleFont: {
										size: 16
									},
									titleMarginBottom: 0,
									titleColor: theme.colors.typography,
									footerFont: {
										size: 12,
										weight: 'normal'
									},
									footerColor: theme.colors.typography,
									footerMarginTop: 6,
									displayColors: false,
									yAlign: 'top',
									xAlign: 'left',
									borderWidth: 1,
									borderColor: theme.colors.grey4,
									caretPadding: 15,
									caretSize: 0,
									cornerRadius: 0,
									padding: 12,
									callbacks: {
										title: ctx => `1 ${ccyFrom} = ${ctx[0].raw} ${ccyTo}`,
										label: () => '',
										footer: ctx =>
											formatDateTime(
												dataSet[ctx[0].dataIndex].timestamp,
												i18n.language
											)
									}
								},
								legend: {
									display: false
								}
							},
							interaction: {
								intersect: false,
								mode: 'nearest',
								axis: 'x'
							}
						},
						plugins: [
							{
								id: 'tooltipLine',
								// @ts-ignore
								beforeDraw: ({ tooltip, ctx, chartArea }) => {
									// @ts-ignore
									if (tooltip._active && tooltip._active.length) {
										ctx.save();
										// @ts-ignore
										const activePoint = tooltip._active[0];
										ctx.beginPath();
										ctx.moveTo(activePoint.element.x, chartArea.top);
										ctx.lineTo(activePoint.element.x, activePoint.element.y);
										ctx.lineWidth = 1;
										ctx.strokeStyle = theme.colors.grey4;
										ctx.stroke();
										ctx.restore();

										ctx.beginPath();
										ctx.moveTo(activePoint.element.x, activePoint.element.y);
										ctx.lineTo(activePoint.element.x, chartArea.bottom);
										ctx.lineWidth = 1;
										ctx.strokeStyle = theme.colors.grey4;
										ctx.stroke();
										ctx.restore();
									}
								}
							}
						]
					}}
					aria-label={t('aria.chart')}
				/>
			);
		}
	};

	return (
		<>
			<ChartContainer flash={flash} ref={chartRef}>
				{renderChart()}
			</ChartContainer>
			{isLoading || !fxRate || fxRate.dataSet.length === 0 ? (
				<EmptyState>
					<EmptyLine height={60} />
				</EmptyState>
			) : (
				<ChartRangeSelector
					dataSet={fxRate?.dataSet}
					chartRef={chartRef}
					onChange={setDataSet}
				/>
			)}
		</>
	);
});

CurrencyRateChart.displayName = 'CurrencyRateChart';
