import { isLocalhost } from './utils/dom/window';

const config = {
	head: {
		defaultTitle: 'HSBC Currency Zone',
		titleTemplate: '%s - HSBC Currency Zone',
		meta: [
			{
				name: 'description',
				content:
					'Check, track and chart foreign exchange (FX) rates. Stay on top of the market with insights and HSBC Global Research.'
			},
			{ name: 'robots', content: 'noindex, nofollow' }
		]
	},
	brightcove: {
		url: 'https://players.brightcove.net',
		accountId: '1311491902001',
		playlistId: '1805634502603502368',
		player: 'KQkXl4lQd',
		embed: 'default'
	},
	eloqua: {
		url: 'https://s268905678.t.eloqua.com/e/f2',
		formName: 'FXTestFormSB4',
		siteID: '268905678'
	},
	apiPrefix: isLocalhost() ? 'http://localhost:8085/currency-zone/v1' : '/currency-zone/v1',
	regex: {
		hsbcEmailDomains:
			/(.*@.*\.hsbc$|.*@hsbc\.com$|.*@.*\.hsbc\.com$|.*@hsbcib\.com$|.*@.*\.hsbcib\.com$)/
	},
	podcasts: {
		gbm: [
			{
				name: 'The Macro Brief',
				link: 'https://www.gbm.hsbc.com/en-gb/feed/global-research/podcasts/macro-viewpoint'
			},
			{
				name: 'Under the Banyan Tree',
				link: 'https://www.gbm.hsbc.com/en-gb/feed/global-research/podcasts/macro-viewpoint'
			}
		],
		streamingPlatforms: [
			{
				link: 'https://podcasts.apple.com/hk/podcast/the-macro-brief-by-hsbc-global-research/id1690393154?l=en-GB',
				accessKey: 'a',
				srcImg: 'https://www.gbm.hsbc.com/-/jssmedia/media/gbm-global/images/campaign/apple.png?h=80&amp;iar=0&amp;w=333&amp;hash=DA19790F3D058BC241F58F6CFBA6735C',
				width: '222',
				height: '54',
				alt: 'Listen on Apple Podcasts'
			},
			{
				link: 'https://open.spotify.com/show/7fud1Dmg6ZHMPl4aPgkon0?si=UZF6QiHjS0WFLZRH2kTH6A',
				accessKey: 's',
				srcImg: 'https://www.gbm.hsbc.com/-/jssmedia/media/gbm-global/images/campaign/spotify.png?h=80&amp;iar=0&amp;w=333&amp;hash=A67ED795293C0AFB8DAD094CCEBB8DB2',
				width: '222',
				height: '54',
				alt: 'Listen on Spotify'
			}
		]
	}
};

export default config;
