import Decimal from 'decimal.js-light';

export const formatCurrency = (amount: string, currencyCode: string, withCcyCodePostfix = true) => {
	const amountF = parseFloat(amount);
	const currencyFormat = new Intl.NumberFormat('en-GB', {
		style: 'currency',
		// @ts-ignore
		numberingSystem: 'latn',
		currency: currencyCode,
		currencyDisplay: 'code'
	}).format(amountF);

	if (withCcyCodePostfix) {
		return currencyFormat.replace(/^([A-Z]{3})\s*(.+)$/, '$2 $1');
	}
	return currencyFormat;
};

//see number.toLocaleString(..), minimumFractionDigits, maximumFractionDigits
export const formatAmount = (value: number | Decimal, dp = 2): string => {
	const chars = [...value.toFixed(dp)];
	function getIntegerChars(): string[] {
		const integerEndPos = chars.indexOf('.') !== -1 ? chars.indexOf('.') - 1 : chars.length - 1;
		const integer = [];
		for (let i = integerEndPos; i >= 0; i--) {
			integer.push(chars[i]);
			if (i > 0 && (integerEndPos - i + 1) % 3 === 0) {
				integer.push(',');
			}
		}
		return integer.reverse();
	}

	function getFractionChars(): string[] {
		return chars.slice(chars.length - dp, chars.length);
	}

	const result = getIntegerChars();
	if (dp > 0) {
		const fractionChars = getFractionChars();
		result.push('.');
		result.push(...fractionChars);
	}
	return result.join('');
};
