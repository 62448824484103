import React from 'react';
import { Route } from 'react-router-dom';

interface StatusProps {
	code: number;
	children: React.ReactNode;
}

declare module 'react-router' {
	interface StaticContext {
		status: number;
	}
}

export const Status = ({ code, children }: StatusProps) => (
	<Route
		render={({ staticContext }) => {
			if (staticContext) {
				staticContext.status = code;
			}

			return children;
		}}
	/>
);
