import React, { useState } from 'react';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { MenuItem } from 'types';
import { event } from 'utils/logger';
import { styled } from 'utils/styles';
import { SendEvent } from 'utils/logging/types';
import { ChevronThickIcon } from 'components/icons';
import { ExternalLink, Flex } from 'components/common';

interface BurgerMenuProps {
	menuItems: MenuItem[];
	sendEvent?: SendEvent;
}

const BurgerMenuNav = styled.nav`
	background-color: #1e1e1e;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	left: 0;
	height: calc(100vh - 71px);
	overflow: hidden;
	overflow-y: auto;
	position: absolute;
	top: 100%;
	width: 100%;
	-webkit-overflow-scrolling: touch;
`;

const BurgerMenuItem = styled.button<Pick<MenuItem, 'isActive'> & LayoutProps>`
	display: flex;
	color: #fff;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	font-weight: ${({ isActive, theme }) =>
		isActive ? theme.fontWeight.bold : theme.fontWeight.light};
	line-height: 1.2;
	padding: 20px 0;
	position: relative;
	background: transparent;
	border: none;
	border-bottom: 1px solid #494949;
	width: 100%;
	text-align: left;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}

	svg {
		fill: white;
	}

	${layout}
`;

const BurgerMenuItemLink = BurgerMenuItem.withComponent(ExternalLink);

const BurgerMenuList = styled.ul<SpaceProps>`
	display: inline-block;
	padding: 23px 28px 46px;
	width: 100%;
	${space}
`;

const BurgerMenuListItem = styled.li<SpaceProps & LayoutProps>`
	display: block;
	width: 100%;
	${space}
	${layout}
`;

const BurgerSubMenu = (props: BurgerMenuProps) => {
	const { menuItems, sendEvent = event } = props;

	const handleClick = ({ onClick, trackingEvent }: MenuItem) => {
		if (onClick) {
			onClick();
		}

		if (trackingEvent) {
			sendEvent(trackingEvent);
		}
	};

	return (
		<BurgerMenuList padding="none">
			{menuItems.map(menuItem => (
				<BurgerMenuListItem
					key={menuItem.label}
					marginLeft={43}
					width="auto"
					role="none presentation"
				>
					<BurgerMenuItem
						isActive={menuItem.isActive}
						onClick={() => handleClick(menuItem)}
					>
						{menuItem.label}
					</BurgerMenuItem>
				</BurgerMenuListItem>
			))}
		</BurgerMenuList>
	);
};

const RotatedChevronThickIcon = styled(ChevronThickIcon)<{ open: boolean }>`
	transform: rotate(${({ open }) => (open ? '270deg' : '0deg')});
`;

export const BurgerMenu = (props: BurgerMenuProps) => {
	const { menuItems, sendEvent = event } = props;
	const [openSubMenu, setOpenSubMenu] = useState<null | string>(null);

	const handleClick = ({ submenu, onClick, trackingEvent, key, href }: MenuItem) => {
		if (submenu) {
			setOpenSubMenu(prev => (typeof prev === 'string' ? null : key || null));
		} else if (onClick) {
			onClick();
		}

		if (trackingEvent && !href) {
			sendEvent(trackingEvent);
		}
	};

	return (
		<BurgerMenuNav>
			<BurgerMenuList>
				{menuItems.map(menuItem => {
					const BurgerItem = menuItem.href ? BurgerMenuItemLink : BurgerMenuItem;

					return (
						<BurgerMenuListItem key={menuItem.key} role="none presentation">
							<BurgerItem
								href={menuItem.href}
								trackingEvent={menuItem.trackingEvent}
								onClick={() => handleClick(menuItem)}
							>
								<Flex alignItems="center" gridGap="small">
									{menuItem.icon}
									<span>{menuItem.label}</span>
								</Flex>
								<RotatedChevronThickIcon open={openSubMenu === menuItem.key} />
							</BurgerItem>
							{openSubMenu === menuItem.key && menuItem.submenu && (
								<BurgerSubMenu menuItems={menuItem.submenu} sendEvent={sendEvent} />
							)}
						</BurgerMenuListItem>
					);
				})}
			</BurgerMenuList>
		</BurgerMenuNav>
	);
};
