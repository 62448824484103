import React from 'react';
import { SVG, SVGProps } from './SVG';

export const ArrowDownIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<polygon points="2 10.85 9 17.85 16 10.85 16 9.15 9.6 15.55 9.6 0 8.4 0 8.4 15.55 2 9.15 2 10.85" />
		</g>
	</SVG>
);
