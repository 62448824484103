import React from 'react';
import { SVG, SVGProps } from './SVG';

export const GlobalIcon = (props: SVGProps) => (
	<SVG focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect width="18" height="18" />
			<path
				className="cls-2"
				d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM6.85,1.51A10.28,10.28,0,0,0,4,8.4H1.23A7.8,7.8,0,0,1,6.85,1.51ZM1.23,9.6H4a10.28,10.28,0,0,0,2.82,6.89A7.8,7.8,0,0,1,1.23,9.6ZM8.4,16.22C6.64,15,5.39,12.55,5.23,9.6H8.4Zm0-7.82H5.23C5.39,5.45,6.64,3,8.4,1.78Zm8.37,0H14a10.25,10.25,0,0,0-2.81-6.88A7.79,7.79,0,0,1,16.77,8.4ZM9.6,16.22V9.6h3.17C12.61,12.55,11.36,15,9.6,16.22Zm0-7.82V1.78c1.76,1.18,3,3.67,3.17,6.62Zm1.56,8.08A10.25,10.25,0,0,0,14,9.6h2.8A7.79,7.79,0,0,1,11.16,16.48Z"
			/>
		</g>
	</SVG>
);
