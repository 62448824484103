import React from 'react';
import { styled, typography } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { formatDateTime } from 'utils/date';
import { space, SpaceProps } from 'styled-system';
import { ClientOnly } from './ClientOnly';
import { DelayDisclaimer } from './DelayDisclaimer';
import { Flex } from './Grid';

interface TimeProps extends SpaceProps {
	ts: number;
	className?: string;
}

const StyledTimestamp = styled(Flex)(typography.s6, space);

export const Time = (props: TimeProps) => {
	const { ts, className, ...rest } = props;
	const { t, i18n } = useTranslation();

	return (
		<ClientOnly>
			<StyledTimestamp gridGap={6} flexWrap="wrap" className={className} {...rest}>
				{t('labels.asOf', { date: formatDateTime(ts, i18n.language) })}
				<DelayDisclaimer />
			</StyledTimestamp>
		</ClientOnly>
	);
};
