import { SpaceProps, space } from 'styled-system';
import { css, styled } from 'utils/styles';

export const Label = styled.label<SpaceProps>(
	({ theme: { fontSize, space } }) => css`
		display: block;
		font-size: ${fontSize.medium};
		margin-bottom: ${space.xsmall};
	`,
	space
);
