import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Header, Page } from 'components/common';

export const BrowserUnsupported = () => (
	<Page>
		<Helmet title="Browser not supported" />
		<div>
			<div>
				<Header>
					<h1>Your browser is not supported</h1>
				</Header>
				<p>
					HSBC Currency Zone requires a modern browser to deliver the best experience. Use
					one of the following supported browsers:
				</p>
				<ul>
					<li>Google Chrome 54+</li>
					<li>Microsoft Edge 40+</li>
					<li>Mozilla Firefox 70+</li>
					<li>Opera 58+</li>
					<li>Safari 9+</li>
				</ul>
			</div>
		</div>
	</Page>
);

export default BrowserUnsupported;
