import { HeaderContext, Range } from 'types';

export const messageTypes = {
	info: 'Info',
	warning: 'Warning',
	event: 'Event',
	error: 'Error'
};

const ACTIONS = {
	SET_FROM_CCY: 'SET_FROM_CCY',
	SET_ROW_AS_MAIN_RATES: 'SET_ROW_AS_MAIN_RATES',
	SET_TO_CCY: 'SET_TO_CCY',
	INVERT_RATE: 'INVERT_RATE',
	REMOVE_CCYPAIR: 'REMOVE_CCYPAIR',
	ADD_CCYPAIR: 'ADD_CCYPAIR',
	SET_AMOUNT: 'SET_AMOUNT',
	PICK_CCYPAIR_TO_LOAD: 'PICK_CCYPAIR_TO_LOAD',
	SET_RANGE_FOR_MAIN_CHART: 'SET_RANGE_FOR_MAIN_CHART',
	NAVIGATE_TO_: 'NAVIGATE_TO_',
	CANCEL_PAGE_LEAVE: 'CANCEL_PAGE_LEAVE',
	TRY_TO_LEAVE_AND_NAVIGATE_TO_: 'TRY_TO_LEAVE_AND_NAVIGATE_TO_',
	TOGGLE_TABLE_VIEW: 'TOGGLE_TABLE_VIEW',
	CLICK_ON_PDF_ATTACHMENT: 'CLICK_ON_PDF_ATTACHMENT'
};

const LIFECYCLE = {
	APP_LOAD: 'APP_LOAD'
};

export interface TealiumEvent {
	event_category: string;
	event_action: string;
	event_content: string;
}

export const PREDEFINED_EVENTS = {
	CLICK_ON_LOGO: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home:header:home'
	},
	CLICK_ON_PERSONAL_REFFERAL: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home:header:personal referral launch'
	},
	CLICK_ON_CONTACT_US: (context: HeaderContext) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: header: uk: ${context}: contact us`
	}),
	LOG_ON_PERSONAL_ONLINE_BANKING: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: personal: log on: online banking'
	},
	LOG_ON_BUSINESS_ONLINE_BANKING: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: log on: online banking'
	},
	LOG_ON_BUSINESS_HSBC_NET: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: log on: hsbc net'
	},
	LOG_ON_BUSINESS_HSBC_KINETIC: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: log on: hsbc kinetic'
	},
	LOG_ON_BUSINESS_HSBC_OPENING_PORTAL: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: log on: opening portal'
	},
	LOG_ON_BUSINESS_CUSTOMER_INFORMATION_REVIEW: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: log on: customer information review'
	},
	CLICK_ON_PERSONAL_CURRENT_ACCOUNTS: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: personal: current accounts: referral'
	},
	CLICK_ON_PERSONAL_CURRENCY_ACCOUNT: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: personal: internation services: referral'
	},
	CLICK_ON_PERSONAL_TRAVEL_MONEY: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: personal: travel money: referral'
	},
	CLICK_ON_PERSONAL_BANK_ACCOUNT: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: personal: hsbc expat: referral'
	},
	CLICK_ON_PERSONAL_HSBC_UK: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: personal: hsbc uk: referral'
	},
	CLICK_ON_BUSINESS_ACCOUNTS: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: accounts: referral'
	},
	CLICK_ON_BUSINESS_KINETIC: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: kinetic: referral'
	},
	CLICK_ON_BUSINESS_GLOBAL_WALLET: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: global wallet: referral'
	},
	CLICK_ON_BUSINESS_HSBC_UK: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: header: uk: business: hsbc uk: referral'
	},
	CLICK_ON_BUSINESS_REFFERAL: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home:header:business referral launch'
	},
	INPUT_CONVERT_AMOUNT: (amount: string | number) => ({
		event_category: 'tool',
		event_action: 'input amount',
		event_content: `home:convert:amount:${amount}`
	}),
	SELECT_FROM_CCY: (ccy: string) => ({
		event_category: 'content',
		event_action: 'dropdown selected',
		event_content: `home: convert:from currency:${ccy}`
	}),
	SELECT_TO_CCY: (ccy: string) => ({
		event_category: 'content',
		event_action: 'dropdown selected',
		event_content: `home: convert:to currency: ${ccy}`
	}),
	SWITCH_QUOTE: (ccyPair: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: convert: switch quote: ${ccyPair}`
	}),
	CHANGE_TIME_RANGE: (range: Range) => {
		const rangeForEvent = {
			[Range.DAY]: '24h',
			[Range.WEEK]: '7d',
			[Range.MONTH]: '30d',
			[Range.YEAR]: '12m',
			[Range.TWO_YEARS]: '2y'
		};
		return {
			event_category: 'content',
			event_action: 'button click',
			event_content: `home: chart: ${rangeForEvent[range]} view`
		};
	},
	PICK_VIEW: (view: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: chart:${view.toLowerCase()} view`
	}),
	RANGE_SELECT_CHANGE: {
		event_category: 'tool',
		event_action: 'range slider select',
		event_content: 'home: chart:range slider'
	},
	REAM_MORE: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: disclaimer:read more'
	},
	TERMS_AND_CONDITIONS: {
		event_category: 'content',
		event_action: 'onsite',
		event_content: 'home: disclaimer:terms and conditions'
	},
	INPUT_AMOUNT_IN_FAV: (amount: string | number) => ({
		event_category: 'tool',
		event_action: 'input amount',
		event_content: `home:watchlist:amount:${amount}`
	}),
	ADD_TO_FAV: (ccyPair: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: watchlist: add currency pair:${ccyPair} `
	}),
	ADD_TO_FAV_FROM_CCY: (ccy: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: watchlist: from currency: ${ccy}`
	}),
	ADD_TO_FAV_TO_CCY: (ccy: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: watchlist: to currency:${ccy}`
	}),
	REMOVE_FAV_CCY: (ccyPair: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: watchlist: remove currency pair: ${ccyPair}`
	}),
	SELECT_FAV_CCY: (ccyPair: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: watchlist: launch full view: ${ccyPair}`
	}),
	SWITCH_QUOTE_FAV: (ccyPair: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `home: convert: switch quote watchlist: ${ccyPair}`
	}),
	READ_FX_TRENDS: {
		event_category: 'content',
		event_action: 'onsite',
		event_content: 'home: research: fx trends'
	},
	READ_MACROVIEW_PODCAST: {
		event_category: 'content',
		event_action: 'offsite',
		event_content: 'home: research: the macro viewpoint podcast'
	},
	READ_GR_LIVE_INSIGHTS: {
		event_category: 'content',
		event_action: 'offsite',
		event_content: 'home: research: global research live insights'
	},
	JUMP_TO_RESEARCH: {
		event_category: 'content',
		event_action: 'button click',
		event_content: 'home: research: jump to research'
	},
	FOOTER_NAVIGATE_TO: (pageLabel: string, eventAction?: 'offsite' | 'onsite') => {
		return {
			event_category: 'content',
			event_action: eventAction ?? 'offsite',
			event_content: `home: footer: ${pageLabel.toLowerCase()}`
		};
	},
	EXTERNAL_NAVIGATE_MODAL_CONFIRM: (event_content: string) => ({
		event_category: 'content',
		event_action: 'offsite',
		event_content: `${event_content} :exit link :confirm`
	}),
	EXTERNAL_NAVIGATE_MODAL_CANCEL: (event_content: string) => ({
		event_category: 'content',
		event_action: 'button click',
		event_content: `${event_content} :exit link :cancel`
	})
};

export type TrackingEvent = keyof typeof PREDEFINED_EVENTS;
export type SendEvent = (eventName: string | TealiumEvent, data?: unknown) => void;

export const EVENTS = {
	ACTIONS,
	LIFECYCLE,
	PREDEFINED_EVENTS
};
