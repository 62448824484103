import React from 'react';
import { styled } from 'utils/styles';
import { ExternalLink } from '../common';
import { ChevronIcon } from '../icons';

interface FxPodcastProps {
	link: string;
	name: string;
}

const PodcastLink = styled(ExternalLink)`
	text-decoration: none;
	color: inherit;
	padding: 6px 0;

	svg {
		transition: transform 0.5s;
		transform: rotate(270deg);
	}

	&:hover {
		text-decoration: underline;
	}
`;

export const FxPodcast = (props: FxPodcastProps) => {
	const { link, name } = props;

	return (
		<PodcastLink href={link}>
			{name} <ChevronIcon size="tiny" aria-hidden />
		</PodcastLink>
	);
};
