import React from 'react';
import { space } from 'styled-system';
import { styled, typography } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { SendEvent } from 'utils/logging/types';
import { NavBarMenuItem } from 'types';
import { StyledButton, Flex } from 'components/common';
import { isEmpty } from 'lodash';
import { NewWindow } from '../icons';

interface NavGroupPromoProps {
	sendEvent: SendEvent;
	menuItem?: NavBarMenuItem;
}

const PromoImage = styled.div<{ src: string }>`
	background-image: url('${({ src }) => src}');
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	aspect-ratio: 16 / 9;
`;

const PromoKicker = styled.h3`
	margin: ${({ theme: { space } }) => `${space.large} 0 ${space.small}`};
	color: #929292;
	letter-spacing: 2px;
	${typography.s5};
`;

const PromoText = styled.p(typography.s5, space);
const PromoCaption = styled.span(typography.s7, space);
const LinkButton = StyledButton.withComponent('a');

export const NavGroupPromo = (props: NavGroupPromoProps) => {
	const { sendEvent, menuItem } = props;
	const { t } = useTranslation();

	return (
		<Flex flex={1} flexDirection="column">
			{menuItem && (
				<>
					<PromoImage src={menuItem.imgUrl} />
					<PromoKicker>{menuItem.title}</PromoKicker>
					<PromoText my="small">{menuItem.paragraph1}</PromoText>
					{menuItem.paragraph2 && <PromoText my="small">{menuItem.paragraph2}</PromoText>}
					<Flex justifyContent="center" my="small">
						<LinkButton
							size="small"
							href={menuItem.link.href}
							target="_blank"
							onClick={() => sendEvent(menuItem.trackingEvent)}
						>
							<NewWindow size="small" mr="small" />
							{menuItem.link.label}
						</LinkButton>
					</Flex>
					{!isEmpty(menuItem.caption) && (
						<PromoCaption my="small">{menuItem.caption}</PromoCaption>
					)}
					<PromoCaption my="small">
						<NewWindow size="small" mr="small" fill="white" />
						{t('header.externalLinkCaption')}
					</PromoCaption>
				</>
			)}
		</Flex>
	);
};
