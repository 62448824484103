import React from 'react';
import { SVG, SVGProps } from './SVG';

export const FilterDownIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" className="cls-1" width="18" height="18" />
			<polygon className="cls-2" points="0 0 9 13.5 18 0 0 0" />
		</g>
	</SVG>
);
