import { SpaceProps, space } from 'styled-system';
import { styled } from 'utils/styles';

export const ErrorMessage = styled.p<SpaceProps>`
	color: ${({ theme }) => theme.colors.rag.red};
	font-size: ${({ theme }) => theme.fontSize.xsmall};
	margin: 0;

	${space}
`;
