import { log } from './logger';
import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

const http = axios.create({ responseType: 'json', timeout: 15000, withCredentials: true });
http.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
axiosRetry(http, { retries: 3 });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function responseErrorInterceptor(error: any) {
	if (error && error.config && error.config.url && error.config.startTime) {
		log(`Response failed ${error}`, error);
	}
	return Promise.reject(error);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function responseSuccessInterceptor(response: any) {
	// log(`Received success response from ${response.config.url}`, response);
	return response;
}

function createAxiosCancelSource() {
	return axios.CancelToken.source();
}

export { http, createAxiosCancelSource };

export type HttpRequestConfig = AxiosRequestConfig;
