import React, { ReactNode } from 'react';
import { flex, FlexProps, layout, LayoutProps } from 'styled-system';
import { css, styled, typography } from 'utils/styles';
import { HelpIcon } from '../icons';
import { Tooltip } from '../common';

const StyledResearchSection = styled.section<FlexProps & LayoutProps>(
	({ theme: { space } }) => css`
		margin-bottom: ${space.xlarge};
		width: 100%;
		& > p {
			text-align: justify;
		}
	`,
	flex,
	layout
);

const SectionHeading = styled.h3(
	({ theme: { colors, fontWeight } }) => css`
		${typography.s4}
		font-weight: ${fontWeight.normal};
		position: relative;
		padding-left: 15px;
		margin-top: 0;

		&::after {
			content: '';
			width: 3px;
			height: 100%;
			background: ${colors.redHSBC};
			position: absolute;
			top: 0;
			left: 0;
		}
	`
);

interface ResearchSectionProps extends FlexProps, LayoutProps {
	heading: string;
	children: ReactNode;
	tooltip?: string;
	tooltipAriaLabel?: string;
}

export const ResearchSection = (props: ResearchSectionProps) => {
	const { heading, children, tooltip, tooltipAriaLabel, ...researchSection } = props;

	return (
		<StyledResearchSection {...researchSection}>
			<SectionHeading>
				{heading}
				{tooltip && (
					<Tooltip content={tooltip} ariaLabel={tooltipAriaLabel}>
						<HelpIcon size="small" marginLeft="xsmall" />
					</Tooltip>
				)}
			</SectionHeading>
			{children}
		</StyledResearchSection>
	);
};
