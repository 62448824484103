import React from 'react';
import { SVG, SVGProps } from './SVG';

export const ErrorIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M17.89,15.8,9.69,1.68a.8.8,0,0,0-1.38,0L.11,15.8A.8.8,0,0,0,.8,17H17.2A.8.8,0,0,0,17.89,15.8ZM8.1,5H9.9v6H8.1ZM9,14.83a1.25,1.25,0,1,1,1.25-1.25A1.25,1.25,0,0,1,9,14.83Z" />
		</g>
	</SVG>
);
