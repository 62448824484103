import { Language } from './i18next';
import { keyBy, mapValues } from 'lodash';
import { Day } from 'types';

export const isWeekend = () => {
	const SUNDAY = 0;
	const SATURDAY = 6;
	const today = new Date().getDay();

	return today === SATURDAY || today === SUNDAY;
};

export const formatDate = (date: string, locale: string = Language.ENGLISH) =>
	new Date(date).toLocaleString(locale, {
		day: 'numeric',
		month: 'short',
		year: 'numeric'
	});

export const formatDateTime = (time: number, locale: string = Language.ENGLISH) => {
	const dateParts = new Intl.DateTimeFormat(locale, {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: false,
		day: 'numeric',
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		timeZoneName: 'short'
	}).formatToParts(new Date(time));
	const date = mapValues(keyBy(dateParts, 'type'), 'value');

	return `${date.hour}:${date.minute}:${date.second}, ${date.weekday} ${date.month} ${date.day} ${date.year} ${date.timeZoneName}`;
};

export const formatTime = (time: number, locale: string = Language.ENGLISH) => {
	const dateParts = new Intl.DateTimeFormat(locale, {
		hour: 'numeric',
		minute: 'numeric',
		timeZoneName: 'short'
	}).formatToParts(new Date(time));

	const date = mapValues(keyBy(dateParts, 'type'), 'value');

	return `${date.hour}:${date.minute} ${date.timeZoneName}`;
};

export const getDateOfLastDay = (day: Day): Date => {
	const today = new Date();
	today.setDate(today.getDate() - day);
	return new Date(today.setDate(today.getDate() - today.getDay() + day));
};
