import React from 'react';
import { Modal, ModalFooter, ModalHeading } from 'components/modals';
import { Button } from 'components/common';
import { useTranslation } from 'utils/i18next';

interface RateAlertModalSubmittedProps {
	onClose: () => void;
}
export const RateAlertModalSubmitted = (props: RateAlertModalSubmittedProps) => {
	const { onClose } = props;
	const { t } = useTranslation();

	return (
		<Modal maxWidth={'850px'}>
			<ModalHeading onClose={onClose}>{t('rateAlert.submitted.heading')}</ModalHeading>
			<p>{t('rateAlert.submitted.confirmEmail')}</p>
			<p>{t('rateAlert.submitted.emailNotification')}</p>
			<ModalFooter>
				<Button size="small" onClick={onClose}>
					{t('labels.close')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
