import React from 'react';
import { isNull } from 'lodash';
import { HSBCTheme, styled } from 'utils/styles';

interface PercentageChangeProps {
	change: number | null;
	as?: React.ElementType;
	className?: string;
	children?: React.ReactNode;
}

const getColor = (change: number | null, theme: HSBCTheme): string => {
	if (isNull(change) || change === 0) {
		return theme.colors.typography;
	} else if (change > 0) {
		return theme.colors.rag.green;
	} else {
		return theme.colors.red2;
	}
};

export const formatChange = (change: number | null): string => {
	if (change === null) {
		return 'n/a';
	}

	const fixedChange = change.toFixed(2);
	if (change === 0) {
		return ` ${fixedChange}%`;
	} else if (change > 0) {
		return `+${fixedChange}%`;
	} else {
		return `${fixedChange}%`;
	}
};

const StyledSpan = styled.span<PercentageChangeProps>`
	align-items: center;
	white-space: pre;
	color: ${({ change, theme }) => getColor(change, theme)};

	& > svg {
		fill: ${({ change, theme }) => getColor(change, theme)};
		margin-right: 4px;
	}
`;

export const Change = (props: PercentageChangeProps) => {
	const { change, children, className, ...aria } = props;

	return (
		<StyledSpan change={change} className={className} {...aria}>
			{children}
			{formatChange(change)}
		</StyledSpan>
	);
};
