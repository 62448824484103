import React from 'react';
import { Accordion } from 'components/common';

export const FxTrendsAccordions = () => (
	<>
		<Accordion summary="Explanation of tactical views">
			<p>
				Note: ^DXY = US Dollar Index, is an index (or measure) of the value of the USD
				against major global currencies, including the EUR, JPY, GBP, CAD, SEK and CHF.
				Source: HSBC
			</p>
			<p>
				<b>Table of tactical views where a currency pair is referenced </b>(e.g. USD/JPY):
				An up (
				<span className="info-icon info-icon--small up">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
						focusable="false"
						className="fill v3"
						viewBox="0 0 18 18"
					>
						<path opacity=".25" fill="none" d="M0 0h18v18H0z"></path>
						<path d="M16 7.153L9 .151 2 7.153v1.696l6.4-6.4V18h1.2V2.449l6.4 6.4z"></path>
					</svg>
				</span>
				) / down (
				<span className="info-icon info-icon--small down">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
						focusable="false"
						className="fill v3"
						viewBox="0 0 18 18"
					>
						<path opacity=".25" fill="none" d="M18 18H0V0h18z"></path>
						<path d="M2 10.847l7 7.002 7-7.002V9.151l-6.4 6.4V0H8.4v15.551L2 9.151z"></path>
					</svg>
				</span>
				) / sideways (
				<span className="info-icon info-icon--small ">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
						focusable="false"
						className="fill v3"
						viewBox="0 0 18 18"
					>
						<path opacity=".25" fill="none" d="M0 18V0h18v18z"></path>
						<path d="M10.847 2l7.002 7-7.002 7H9.151l6.4-6.4H0V8.4h15.551L9.151 2z"></path>
					</svg>
				</span>
				) arrow indicates that the first currency quoted in the pair is expected by HSBC
				Global Research to appreciate/depreciate/track sideways against the second currency
				quoted over the coming weeks. For example, an up arrow against EUR/USD means that
				the EUR is expected to appreciate against the USD over the coming weeks. The arrows
				under the “current” represent our current views, while those under “previous”
				represent our views in the last month’s report.
			</p>
		</Accordion>
		<Accordion summary="Glossary of terms">
			<p>
				<b>Dovish </b>: Dovish refers to an economic outlook which generally supports low
				interest rates as a means of encouraging growth within the economy.
			</p>
			<p>
				<b>Hawkish</b>: Hawkish is typically used to describe monetary policy which favours
				higher interest rates, and tighter monetary controls to keep inflation in check.
			</p>
			<p>
				<b>MoM / YoY</b>: Month on month / Year on year.
			</p>
			<p>
				<b>PMI</b>: Purchasing Managers Index (PMI) is an indicator of economic health of
				the manufacturing sector (&gt;50 represents expansion vs. the previous month).
			</p>
			<p>
				<b>IMM data</b>: International Monetary Market (IMM) is a division of the Chicago
				Mercantile Exchange (CME) that deals with the trading of currencies and interest
				rate futures and options and the IMM data is part of the Commitments of Traders
				(COT) reports published by the U.S. Commodity Futures Trading Commission (CFTC). The
				IMM data provides a breakdown of each Tuesday’s open futures positions on the IMM.
				Speculative positions are a trader’s non-commercial positions (i.e. not for hedging
				purposes).
			</p>
			<p>
				<b>G10</b>: G10 refers to the most heavily traded, liquid currencies in the world:
				USD, EUR, JPY, GBP, CHF, AUD, NZD, CAD, NOK, and SEK.
			</p>
			<p>
				<b>Fed / FOMC</b>: Federal Reserve System (US’s Central Bank) / Federal Open Market
				Committee.
			</p>
			<p>
				<b>ECB</b>: European Central Bank (Eurozone’s Central Bank).
			</p>
			<p>
				<b>BoE</b>: Bank of England (UK’s Central Bank).
			</p>
			<p>
				<b>BoJ </b>: Bank of Japan (Japan’s Central Bank).
			</p>
			<p>
				<b>BoC</b>: Bank of Canada (Canada’s Central Bank).
			</p>
			<p>
				<b>RBA</b>: Reserve Bank of Australia (Australia’s Central Bank).
			</p>
			<p>
				<b>RBNZ</b>: Reserve Bank of New Zealand (New Zealand’s Central Bank).
			</p>
			<p>
				<b>SNB</b>: Swiss National Bank (Switzerland’s Central Bank).
			</p>
		</Accordion>
		<Accordion summary="Disclosure Appendix">
			<p>
				This publication has been prepared by HSBC Bank plc (“HBEU”) which is part of the
				HSBC Group. It is intended for information purposes only and is not intended for
				further distribution whether through the press or via other means. Nothing in this
				publication constitutes or is to be construed as an offer to buy or sell or a
				solicitation of an offer to buy or sell the securities or other investment products
				mentioned in it and/or to participate in any trading strategy. Information in this
				publication is general and should not be construed as investment advice or guidance
				as it has been prepared without taking account of the objectives, financial
				situation or needs of any particular investor. Investors should, accordingly, before
				acting on any information contained herein, consider the appropriateness of the
				information having regard to their objectives, financial situation and needs and
				should, if necessary, seek professional investment and/or tax advice.
			</p>
			<p>
				Certain investment products mentioned in this publication may not be eligible for
				sale in some countries or territories and may not be suitable for all types of
				investors. Investors should consult with their HSBC representative regarding the
				suitability of any investment products mentioned taking into account their specific
				investment objectives, financial situation and/or particular needs before making a
				commitment to purchase any investment products. The value of and the income produced
				by investment products mentioned in this publication may fluctuate so an investor
				may get back less than originally invested. Certain high-volatility investments can
				be subject to sudden and/or large falls in value that could equal or exceed the
				amount invested. Value and income from investment products may be adversely affected
				by exchange rates, interest rates, or other factors. Past performance of a
				particular investment product is not indicative of future results. When an
				investment is denominated in a currency other than the local currency of an
				investor, changes in the exchange rates may have an adverse effect on the value,
				price or income of that investment. Where there is no recognised market for an
				investment, it may be difficult for an investor to sell the investment or to obtain
				reliable information about its value or the extent of the risk associated with it.
			</p>
			<p>
				This publication may contain forward-looking statements which are, by their nature,
				subject to significant risks and uncertainties. Any such statements are projections
				and are used for illustration purpose only. Customers are reminded that there can be
				no assurance that economic conditions described herein will remain in the future.
				Actual results may differ materially from the forecasts and/or estimates. No
				assurance is given that expectations reflected in any forward-looking statements
				will prove to have been correct or come to fruition, and you are cautioned not to
				place undue reliance on such statements. No obligation is undertaken to publicly
				update or revise any forward-looking statements contained in this publication or in
				any other related publication whether as a result of new information, future events
				or otherwise.
			</p>
			<p>
				This publication is distributed by HBEU, its affiliates and its associated entities
				(together, the “HBEU entities”) to their customers. No HBEU entity is responsible
				for any loss, damage or other consequence of any kind that may be incurred or
				suffered as a result of, arising from, or relating to any use or reliance on this
				publication. No HBEU entity gives any guarantee as to the accuracy, timeliness or
				completeness of this publication. Whether, or in what time frame, an update of any
				information contained herein will be published is not determined in advance. You
				should consult your own professional advisor if you have any questions regarding the
				content of this publication.
			</p>
			<p>
				HBEU entities and their respective officers and/or employees may have interests in
				any products referred to in this publication by acting in various roles including as
				distributor, holder of principal positions, adviser or lender. HBEU entities and
				their respective officers and employees may receive fees, brokerage or commissions
				for acting in those capacities. In addition, HBEU entities and their respective
				officers and/or employees may buy or sell products as principal or agent and may
				effect transactions which are not consistent with the information set out in this
				publication.
			</p>
			<h4>Additional disclosures</h4>
			<ol className="list">
				<li>This publication is dated as at 24 January 2025.</li>
				<li>
					All market data included in this publication is dated as at close 23 January
					2025, unless a different date and/or a specific time of day is indicated in the
					publication.
				</li>
				<li>
					HBEU has procedures in place to identify and manage any potential conflicts of
					interest that arise in connection with its research business. HBEU’s analysts
					and its other staff who are involved in the preparation and dissemination of
					research operate and have a management reporting line independent of HBEU’s
					investment business. Information barrier procedures are in place between the
					investment banking, principal trading and research businesses to ensure that any
					confidential and/or price sensitive information is handled in an appropriate
					manner.
				</li>
				<li>
					Recipients of this publication are not permitted to use, for reference, any data
					in this publication for the purpose of (i) determining the interest payable, or
					other sums due, under loan agreements or under other financial contracts or
					instruments, (ii) determining the price at which a financial instrument may be
					bought, sold, traded or redeemed, or the value of a financial instrument, and/or
					(iii) measuring the performance of a financial instrument.
				</li>
			</ol>
		</Accordion>
		<Accordion summary="Disclaimers">
			<h4>Africa:</h4>
			<p>
				1 In South Africa, this publication is distributed through HBEU’s registered branch
				in South Africa being HSBC Bank plc Johannesburg Branch, Registration Number
				2003/004613/10 (Financial Services Provider Number: 43940) of 1 Mutual Place, 107
				Rivonia Road, Sandton, 2196.
			</p>
			<h4>Americas:</h4>
			<p>
				1 In Bermuda, this publication is distributed by HSBC Bank Bermuda Limited of 37
				Front Street, Hamilton, Bermuda, which is licensed to conduct Banking and Investment
				Business by the Bermuda Monetary Authority. 2 In the United States, this document is
				distributed by HSBC Securities (USA) Inc. ("HSI") to its customers. HSI is a member
				of the HSBC Group, the NYSE and FINRA. 3 In Canada, this material is distributed by
				HSBC Bank Canada and its affiliates.
			</p>
			<h4>Asia Pacific:</h4>
			<p>
				1 In Australia, this publication has been distributed by HSBC Bank Australia Limited
				(ABN 48 006434 162, AFSL No. 232595) only, for the general information of its
				clients. Any reference in this publication to other HSBC Group entities shall not be
				taken to mean that such entities has a presence in or are licensed in Australia, or
				that such entities are offering to do business and/or enter into transactions with
				persons located in Australia. 2 In Bangladesh, this publication is distributed by
				The Hongkong and Shanghai Banking Corporation Limited (“HBAP”), Bangladesh branch. 3
				In mainland China, this publication is distributed by HSBC Bank (China) Company
				Limited (“HBCN”) to its customers. 4 In Hong Kong, this publication is distributed
				by HBAP to its customers for general reference and information purposes only. 5 In
				India, this publication is distributed by HBAP, India branch, to its customers for
				general reference and information purposes only. 6 In New Zealand, this publication
				is distributed by HBAP, incorporated in the Hong Kong SAR, acting through its New
				Zealand branch. 7 In Singapore, this publication is distributed by HBAP, Singapore
				branch (“HBAP, Singapore branch”) to institutional investors or other persons
				specified in Sections 274 and 304 of the Securities and Futures Act (Chapter 289)
				(“SFA”) and accredited investors and other persons in accordance with the conditions
				specified in Sections 275 and 305 of the SFA. For recipients which are not
				institutional investors, accredited investors or expert investors as defined in the
				SFA, this is distributed pursuant to Regulation 32C of the Financial Advisers
				Regulations (“FAR”). HBAP, Singapore branch, accepts legal responsibility for the
				contents of the publication pursuant to Regulation 32C(1)(d) of the FAR. This
				publication is not a prospectus as defined in the SFA. HBAP, Singapore branch, is
				regulated by the Monetary Authority of Singapore. Recipients should contact an HBAP,
				Singapore branch representative in respect of any matters arising from or in
				connection with this publication and refer to the contact details at
				www.business.hsbc.com.sg. 8 In Sri Lanka, this publication is distributed by HBAP,
				Sri Lanka branch to its customers.
			</p>
			<h4>Europe:</h4>
			<p>
				1 For clients of HSBC Continental Europe and HSBC Continental Europe branches, this
				publication is distributed by HSBC Continental Europe. HSBC Continental Europe is an
				« Etablissement de crédit et prestataire de services d’investissement» authorized by
				the « Autorité de Contrôle Prudentiel et de Résolution » (ACPR) and the European
				Central Bank (ECB). It is regulated by the « Autorité des Marchés Financiers »
				(AMF), the ACPR and the ECB. 2 In Malta, this publication is distributed by HBEU and
				is being made accessible to customers of HSBC Bank Malta p.l.c. (“HBMT”). HBMT is
				registered in Malta with company number C-3177 and is licenced to conduct investment
				business by the Malta Financial Services Authority. 3 In Switzerland this
				publication is distributed by HBEU to its customers. 4 In the UK and CIIOM, this
				publication is distributed by HBEU to its customers and by HSBC Bank UK plc (“HBUK”)
				to its customers. HBEU is registered in England and Wales (company number: 14259),
				registered office: 8 Canada Square, London, E14 5HQ, UK. HBUK is registered in
				England and Wales (company number: 09928412), registered office: 1 Centenary Square,
				Birmingham B1 1HQ, UK. HBEU is authorised by the Prudential Regulation Authority and
				regulated by the Financial Conduct Authority and the Prudential Regulation Authority
				(Financial Services Register number: 114216). HBUK is authorised by the Prudential
				Regulation Authority and regulated by the Financial Conduct Authority and the
				Prudential Regulation Authority (Financial Services Register number: 765112).
			</p>
			<h4>Middle East:</h4>
			<p>
				1 In Algeria, Bahrain, Kuwait, Qatar, the United Arab Emirates (the “UAE”) and the
				Dubai International Financial Centre (the “DIFC”), this publication is distributed
				by HSBC Bank Middle East Limited (“HBME”) to its customers. HBME is registered in
				the DIFC with company registration number 2199 and its registered office at Level 1,
				Building No. 8, Gate Village, DIFC, PO Box 66, Dubai, DIFC and is lead regulated by
				the Dubai Financial Services Authority. HBME operates in Algeria through its Algeria
				branch at Algeria Business Center, Pins Maritimes, El Mohammadia, 16212 Algiers,
				Algeria (commercial licence number: 07 C 0974997), which is regulated by the Central
				Bank of Algeria (Banque d’Algérie) and lead regulated by the DFSA. HBME operates in
				Bahrain through its branch at PO Box 57, Building 2505, Road 2832, Seef 428, Manama,
				Kingdom of Bahrain (commercial registration number 330-1), which is regulated by the
				Central Bank of Bahrain and lead regulated by the DFSA. HBME operates in Kuwait
				through its branch at Kharafi Tower, Qibla Area, Hamad Al-Saqr Street, PO Box 1683,
				Safat 13017, Kuwait (Ministry of Commerce and Industry branch licence number
				SP2005/4), which is regulated by the Central Bank of Kuwait, the Capital Markets
				Authority for licenced Securities Activities and lead regulated by the DFSA. HBME
				operates in Qatar through its branch at PO Box 57, Doha, Qatar (trade licence number
				6374), which is regulated by the Qatar Central Bank and lead regulated by the DFSA.
				HBME operates in Dubai through its branch at HSBC Tower, Downtown, PO Box 66, Dubai,
				UAE (Chamber of Commerce and Industry branch licence number 617987), which is
				regulated by the Central Bank of the UAE and lead regulated by the DFSA. 2 In Egypt,
				this publication is distributed by HSBC Bank Egypt SAE to its customers. HSBC Bank
				SAE is registered in Egypt (commercial registration number: 218992) with registered
				office: 306 Cornish El Niel, HSBC Bank Egypt SAE, Maadi, Cairo, Egypt. Regulated by
				the Central Bank of Egypt. 3 In Turkey, this publication is distributed by HSBC Bank
				A.S. to its customers. HSBC Bank A.S. is registered in Turkey (commercial register
				number 268376) with registered office: Esentepe Mah. Büyükdere Cad. No. 128, Şişli
				34394, Istanbul, Turkey. Regulated by the Banking Regulatory and Supervisory Agency.
				4 In Oman, this publication is distributed by HSBC Bank Oman SAOG to its customers.
				HSBC Bank Oman SAOG is registered in Oman (commercial registration number:
				1/08084/9) with registered office: Head Office Building, PO Box 1727, Seeb, PC 111,
				Sultanate of Oman. Regulated by the Central Bank of Oman and the Capital Market
				Authority of Oman. 5 In the Kingdom of Saudi Arabia, this publication is distributed
				by The Saudi British Bank to its customers. The Saudi British Bank is registered in
				the Kingdom of Saudi Arabia (commercial registration number: 1010025779), registered
				office: Head Office, PO Box 9084, Riyadh 11413, Kingdom of Saudi Arabia. Regulated
				by the Saudi Arabian Monetary Authority.
			</p>
			<p>
				© Copyright 2024. HSBC Bank plc, ALL RIGHTS RESERVED. No part of this publication
				may be reproduced, stored in a retrieval system, or transmitted in any form or by
				any means, whether electronic, mechanical, photocopying, recording or otherwise,
				without the prior written permission of HSBC Bank plc.
			</p>
		</Accordion>
	</>
);
