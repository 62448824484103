import React from 'react';
import { event } from 'utils/logger';
import { Trans, useTranslation } from 'utils/i18next';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { Disclaimer, ButtonLink, DelayDisclaimer } from 'components/common';

interface MidRateDisclaimerProps {
	expanded: boolean;
	onClick: () => void;
	sendEvent?: SendEvent;
}

export const MidRateDisclaimer = (props: MidRateDisclaimerProps) => {
	const { expanded, onClick, sendEvent = event } = props;
	const { t } = useTranslation();

	return (
		<Disclaimer id="mid-rate-disclaimer">
			<p>
				<Trans
					i18nKey="disclaimers.midRate.excerpt"
					values={{
						expandLabel: t(expanded ? 'labels.readLess' : 'labels.readMore')
					}}
				>
					This site provides HSBC's <b>mid-market rates</b> with a <DelayDisclaimer />
					These rates are indicative and are for informational purposes only. They are not
					available to customers and do not reflect tradeable prices on past or future
					transactions.
					<ButtonLink
						id="mid-rate-disclaimer-btn"
						aria-expanded={expanded}
						aria-controls="mid-rate-disclaimer-content"
						onClick={() => {
							onClick();
							sendEvent(EVENTS.PREDEFINED_EVENTS.REAM_MORE);
						}}
					>
						Read more
					</ButtonLink>
				</Trans>
			</p>
			{expanded && (
				<div id="mid-rate-disclaimer-content" aria-labelledby="mid-rate-disclaimer-btn">
					<h3>{t('disclaimers.midRate.question1')}</h3>
					<p>{t('disclaimers.midRate.answer1')}</p>
					<h3>{t('disclaimers.midRate.question2')}</h3>
					<p>{t('disclaimers.midRate.answer2')}</p>
				</div>
			)}
		</Disclaimer>
	);
};
