import React, { useState } from 'react';
import { css, styled, typography } from 'utils/styles';

interface TooltipProps {
	children: React.ReactNode;
	content: string;
	ariaLabel?: string;
	testid?: string;
}

const TooltipContainer = styled.span<{ isOpen: boolean }>(
	({ isOpen }) => css`
		position: relative;
		display: inline-block;

		& > span {
			transform: scale(${isOpen ? 1 : 0});
			bottom: ${isOpen ? '101%' : 0};
		}
	`
);

const TooltipButton = styled.button(
	({ theme: { colors } }) => css`
		display: inline-block;
		padding: 0;
		background-color: transparent;
		text-decoration: none;
		border: none;
		position: relative;
		cursor: pointer;
		align-items: center;
		vertical-align: top;

		&:hover,
		&:focus {
			color: ${colors.black};

			svg {
				fill: ${colors.black};
			}
		}

		${typography.s6}
	`
);

const TooltipContent = styled.span(
	({ theme: { colors, space } }) => css`
		text-align: justify;
		display: block;
		position: absolute;
		background-color: ${colors.white};
		padding: ${space.small} ${space.medium} ${space.medium} ${space.medium};
		border: 1px solid #c9d0d5;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		left: 0;
		width: 300px;
		transition:
			transform ease-out 150ms,
			bottom ease-out 150ms;
		bottom: 0;
		transform: scale(0);

		${typography.s6}
	`
);

export const TooltipText = styled.span`
	border-bottom: 1px dotted ${({ theme }) => theme.colors.typography};
`;

export const Tooltip = (props: TooltipProps) => {
	const { children, content, ariaLabel, testid = 'tooltip' } = props;
	const [isOpen, setIsOpen] = useState(false);

	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);

	return (
		<TooltipContainer
			data-testid={`${testid}-container`}
			isOpen={isOpen}
			onMouseEnter={open}
			onMouseLeave={close}
		>
			<TooltipButton
				data-testid={`${testid}-button`}
				aria-label={ariaLabel}
				onBlur={close}
				onFocus={open}
				onClick={open}
				onKeyDown={e => e.key === 'Escape' && close()}
			>
				{children}
			</TooltipButton>
			<TooltipContent data-testid={`${testid}-content`} role="tooltip" hidden={!isOpen}>
				<span role="status">{content}</span>
			</TooltipContent>
		</TooltipContainer>
	);
};
