import React from 'react';
import { scrollTo } from 'utils';
import { useTranslation } from 'utils/i18next';
import { Button } from 'components/common';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { event } from 'utils/logger';
import { getStaticFileURL } from 'utils/url';
import { mq, styled, typography } from 'utils/styles';
import { Flex } from './Grid';

const StyledSection = styled.section`
	display: flex;
	justify-content: space-between;

	${mq({
		flexDirection: ['column', 'row', 'row']
	})}

	p {
		text-align: justify;
	}
`;

const FlexImage = styled(Flex)`
	position: relative;
	padding-top: 66.45%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const Heading = styled.h1`
	margin: 16px 0;
	${typography.s1}
`;

interface HeroProps {
	sendEvent?: SendEvent;
}
export const Hero = (props: HeroProps) => {
	const { t } = useTranslation();
	const { sendEvent = event } = props;

	return (
		<StyledSection data-testid="banner-primary">
			<Flex
				alignItems="center"
				flexDirection={['column', 'row']}
				justifyContent="center"
				gridGap={[20, 40, 40, 150]}
				px="medium"
				flex={1}
			>
				<Flex flex={[1, 1, 4]} flexDirection="column" alignItems="flex-start">
					<Heading>{t('hero.title')}</Heading>
					<p>{t('hero.heading')}</p>
					<Button
						size="small"
						variant="tertiary"
						onClick={() => {
							scrollTo('research-articles');
							sendEvent(EVENTS.PREDEFINED_EVENTS.JUMP_TO_RESEARCH);
						}}
					>
						{t('hero.goToResearch')}
					</Button>
				</Flex>
				<Flex
					flex={[1, 1, 3]}
					justifyContent="flex-end"
					alignSelf="flex-start"
					width="100%"
				>
					<FlexImage flex={1}>
						<img
							src={getStaticFileURL('assets/banner-girl.png')}
							alt="The girl with elegant red jacket on red banner"
						/>
					</FlexImage>
				</Flex>
			</Flex>
		</StyledSection>
	);
};
