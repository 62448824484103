import { css, mq, styled, typography } from 'utils/styles';

export const Section = styled.section(
	({ theme: { space, fontWeight } }) => css`
		padding: 0 ${space.medium};

		${mq({
			marginTop: [space.large, space.xxlarge],
			marginBottom: [space.xxlarge, 64]
		})}

		h2 {
			margin: 0;
			${typography.s3}
			font-weight: ${fontWeight.normal};
		}
	`
);
