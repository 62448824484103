import React from 'react';
import { SVG, SVGProps } from './SVG';

export const HelpIcon = (props: SVGProps) => (
	<SVG focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect width="18" height="18" />
			<path d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM9,16.8A7.8,7.8,0,1,1,16.8,9,7.81,7.81,0,0,1,9,16.8Zm0-4.3a1,1,0,1,0,1,1A1,1,0,0,0,9,12.5Zm.6-9A2.84,2.84,0,0,0,6.16,6.32h1.2A1.66,1.66,0,0,1,8,5a1.7,1.7,0,0,1,1.4-.33A1.66,1.66,0,0,1,10.62,6a1.64,1.64,0,0,1-.46,1.44L9,8.63A1.89,1.89,0,0,0,8.4,10v1H9.6V10a.68.68,0,0,1,.26-.53L11,8.33a2.8,2.8,0,0,0,.79-2.49A2.83,2.83,0,0,0,9.6,3.54Z" />
		</g>
	</SVG>
);
