import React from 'react';
import { useField } from 'formik';
import { Field, ErrorMessage, Checkbox, CheckboxProps } from 'components/form';

interface CheckboxFieldProps extends CheckboxProps {
	name: string;
}

export const CheckboxField = (props: CheckboxFieldProps) => {
	const [field, meta] = useField({ name: props.name, type: 'checkbox' });
	const isError = !!(meta.touched && meta.error);

	return (
		<Field>
			<Checkbox {...field} {...props} isError={isError} />
			{isError ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
		</Field>
	);
};
