import React from 'react';
import { styled } from 'utils/styles';
import { Trans } from 'utils/i18next';
import { Disclaimer, ButtonLink } from 'components/common';

interface MidRateSimpleDisclaimerProps {
	onClick: () => void;
}

const StyledDisclaimer = styled(Disclaimer)`
	margin: 0;
	p {
		margin: 4px 0;
	}
`;

export const MidRateSimpleDisclaimer = (props: MidRateSimpleDisclaimerProps) => {
	const { onClick } = props;

	return (
		<StyledDisclaimer>
			<p>
				<Trans i18nKey="disclaimers.midRateSimple">
					<b>Please note:</b> Rates available to customers will be subject to additional
					costs.
					<ButtonLink onClick={onClick}>Read more</ButtonLink>
				</Trans>
			</p>
		</StyledDisclaimer>
	);
};
