import React, { useState } from 'react';
import { typography, TypographyProps } from 'styled-system';
import { FxRateMap } from 'types';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { event } from 'utils/logger';
import { useTranslation } from 'utils/i18next';
import { AmountInput } from 'components/form';
import { ChartIcon, DeleteIcon } from 'components/icons';
import { Button, Loader, CurrencySymbol, CurrencyPair, Change } from 'components/common';
import { useBreakpoint } from 'hooks';
import { LiveExchangeRateChart } from './LiveExchangeRateChart';
import { disabledCurrencies } from 'common/currencies';
import { formatAmount, formatCurrency } from 'utils/formatters';
import { calcCurrentRateChange, Decimal, invertCcyPair, splitCcyPair } from 'utils/currency';
import { styled, css, mq, flashAnimation } from 'utils/styles';

interface LiveExchangeRatesTableProps {
	flash: boolean;
	ccyPairs: string[];
	fxRates: FxRateMap;
	rowOnClick: (ccyPair: string) => void;
	invertBtnOnClick: (ccyPair: string) => void;
	deleteBtnOnClick: (ccyPair: string) => void;
	loadingState: string;
	sendEvent?: SendEvent;
}

const TableScrollWrapper = styled.div`
	overflow-x: auto;
	position: relative;
	margin: ${({ theme }) => theme.space.large} 0;
`;

const Table = styled.table(
	({ theme: { colors } }) => css`
		border-collapse: collapse;
		width: 100%;

		canvas {
			height: 50px !important;

			${mq({
				width: ['150px !important', '150px !important', '300px !important']
			})}
		}

		& thead th:first-of-type,
		& tbody td:first-of-type {
			position: sticky;
			z-index: 2;
			left: 0;
			background-color: inherit;
			box-shadow: inset -2px 0px 0 0 ${colors.grey1};
		}
		& thead th:last-of-type,
		& tbody td:last-of-type {
			width: 60px;
			padding: 0 10px;
		}
	`
);

const TableHeaderRow = styled.tr`
	height: 80px;
	background-color: ${({ theme }) => theme.colors.white};
	border-bottom: 6px solid ${({ theme }) => theme.colors.grey1};
`;

const TableBodyRow = styled.tr<{ flash: boolean }>`
	position: relative;
	border-bottom: 2px solid ${({ theme }) => theme.colors.grey1};
	background-color: ${({ theme }) => theme.colors.white};
	height: 70px;

	${flashAnimation}

	&:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.colors.grey1};
	}
`;

const TableData = styled.td<TypographyProps>(
	({ theme: { fontWeight } }) => css`
		font-weight: ${fontWeight.bold};
		white-space: nowrap;

		${mq({
			padding: [`0 8px`, '0 12px']
		})}
	`,
	typography
);

const TableHeader = styled(TableData.withComponent('th'))`
	text-align: center;

	span {
		white-space: nowrap;
	}

	${mq({
		whiteSpace: ['pre-wrap', 'pre-wrap', 'nowrap']
	})}
	${typography}
`;

const StyledLabel = styled.label`
	display: block;
`;

const StyledAmountInput = styled(AmountInput)`
	display: block;
	height: 30px;
	width: 75%;
	float: right;
`;

export const LiveExchangeRatesTable = (props: LiveExchangeRatesTableProps) => {
	const {
		flash,
		ccyPairs,
		fxRates,
		loadingState,
		rowOnClick,
		invertBtnOnClick,
		deleteBtnOnClick,
		sendEvent = event
	} = props;
	const [amount, setAmount] = useState<number>(1);
	const [, setAmountError] = useState<boolean>(false);
	const { t } = useTranslation();
	const { bp } = useBreakpoint();

	return (
		<TableScrollWrapper>
			<Table>
				<thead>
					<TableHeaderRow data-testid="live-exchange-rates-header">
						<TableHeader>{t('labels.ccyPair')}</TableHeader>
						<TableHeader>{t('labels.rate')}</TableHeader>
						<TableHeader>
							<span>{`% ${t('labels.change')}`}</span> ({t('timeRange.desktop.day')})
						</TableHeader>
						<TableHeader>{`${t('labels.chart')} (${t(
							'timeRange.desktop.day'
						)})`}</TableHeader>
						<TableHeader textAlign="right">
							<StyledLabel htmlFor="live-exchange-rates-header-amount-input">
								{t('labels.amount')}
							</StyledLabel>
							<StyledAmountInput
								mt="4px"
								id="live-exchange-rates-header-amount-input"
								name="live-exchange-rates-header-amount-input"
								data-testid="live-exchange-rates-header-amount-input"
								padding="small"
								placeholder={t('labels.amount')}
								autoComplete="off"
								value={amount}
								onChange={amount => {
									setAmount(amount);
									sendEvent(EVENTS.PREDEFINED_EVENTS.INPUT_AMOUNT_IN_FAV(amount));
								}}
								onFocus={e => e.target.select()}
								onValidate={err => setAmountError(err)}
							/>
						</TableHeader>
						<TableHeader />
					</TableHeaderRow>
				</thead>
				<tbody style={{ position: 'relative' }}>
					{ccyPairs.map(ccyPair => {
						const rate = fxRates[ccyPair];
						const [from, to] = splitCcyPair(ccyPair);
						const rateChange = rate ? calcCurrentRateChange(rate) : null;
						const showInvertBtn =
							ccyPairs.findIndex(_ccyPair => _ccyPair === invertCcyPair(ccyPair)) ===
							-1;

						const handleRowClick = (
							e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
						) => {
							rowOnClick(ccyPair);
							e.stopPropagation();
						};
						const handleInvertBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
							invertBtnOnClick(ccyPair);
							e.stopPropagation();
						};
						const handleDeleteBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
							deleteBtnOnClick(ccyPair);
							e.stopPropagation();
						};

						return (
							<TableBodyRow
								key={ccyPair}
								data-testid={`live-exchange-rates-row-${ccyPair}`}
								onClick={handleRowClick}
								flash={flash}
							>
								<TableData>
									{bp === 'mobile' || bp === 'tablet' ? (
										<b>
											{from}/{to}
										</b>
									) : (
										<CurrencyPair
											ccyPair={ccyPair}
											onSwitch={handleInvertBtnClick}
											switchDisabled={
												!showInvertBtn ||
												(disabledCurrencies[to] || []).includes(from)
											}
										/>
									)}
								</TableData>
								<TableData textAlign="right">
									{!rate?.latest?.open
										? t('labels.na')
										: formatAmount(rate.latest.open, rate.decimalPlaces)}
								</TableData>
								<TableData textAlign="center">
									<Change change={rateChange} />
								</TableData>
								<TableData>
									{rate ? (
										<LiveExchangeRateChart
											id={`live-exchange-rates-row-chart-${rate.ccyPair}`}
											dataSet={rate.dataSet}
											type={
												rateChange !== null && rateChange < 0
													? 'negative'
													: 'positive'
											}
										/>
									) : (
										t('labels.na')
									)}
								</TableData>
								<TableData textAlign="right">
									{rate?.latest?.open ? (
										<>
											<CurrencySymbol
												ccyCode={to}
												fontWeight="normal"
												width={46}
												marginRight="4px"
											/>
											{formatCurrency(
												new Decimal(rate.latest?.open)
													.times(new Decimal(amount))
													.toString(),
												to,
												false
											)}
										</>
									) : (
										t('labels.na')
									)}
								</TableData>
								<TableData>
									<Button
										data-testid="live-exchange-rates-row-chart-btn"
										variant="icon"
										aria-label={`Show ${ccyPair} conversion data`}
										onClick={handleRowClick}
									>
										<ChartIcon />
									</Button>
									<Button
										data-testid="live-exchange-rates-row-remove-btn"
										variant="icon"
										aria-label={t('aria.remove')}
										onClick={handleDeleteBtnClick}
									>
										<DeleteIcon />
									</Button>
								</TableData>
							</TableBodyRow>
						);
					})}
				</tbody>
			</Table>
			{loadingState && <Loader />}
		</TableScrollWrapper>
	);
};
