import React from 'react';
import config from 'config';
import { LayoutProps } from 'styled-system';
import { useTranslation } from 'utils/i18next';
import { styled } from 'utils/styles';
import { ResearchSection } from './ResearchSection';

const { url, accountId, playlistId, player, embed } = config.brightcove;

const StyledBrightcovePlayer = styled.iframe`
	border: none;
	width: 100%;
	aspect-ratio: 16/9;
	object-fit: contain;
`;

export const FxVideo = (props: LayoutProps) => {
	const { t } = useTranslation('research');

	return (
		<ResearchSection heading={t('video.heading')} {...props}>
			<StyledBrightcovePlayer
				src={`${url}/${accountId}/${player}_${embed}/index.html?playlistId=${playlistId}`}
				title={t('video.heading')}
				allow="encrypted-media"
				allowFullScreen
			/>
		</ResearchSection>
	);
};
