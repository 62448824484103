import React from 'react';
import { SVG, SVGProps } from './SVG';

export const DeleteIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M15.5,2H12V1.5A1.51,1.51,0,0,0,10.5,0h-3A1.51,1.51,0,0,0,6,1.5V2H2.5A1.5,1.5,0,0,0,1,3.5V6H2V18H16V6h1V3.5A1.5,1.5,0,0,0,15.5,2ZM7.2,1.5a.31.31,0,0,1,.3-.3h3a.31.31,0,0,1,.3.3V2H7.2Zm7.6,15.3H3.2V6H14.8Zm1-12H2.2V3.5a.31.31,0,0,1,.3-.3h13a.3.3,0,0,1,.3.3ZM7.2,8H6v7H7.2ZM12,8H10.8v7H12Z" />
		</g>
	</SVG>
);
