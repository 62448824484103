import React, { useEffect, useState } from 'react';
import { Range } from 'types';
import { debounce } from 'lodash';
import { useTranslation } from 'utils/i18next';
import { styled, visuallyHidden } from 'utils/styles';
import { RadioButton, RadioGroup } from './form';
import { useBreakpoint } from '../hooks';

interface CurrencyRateRangeProps {
	name: string;
	value: Range | null;
	onChange: (value: Range) => void;
}

const HiddenLegend = styled.legend(visuallyHidden);

export const CurrencyRateRange = React.memo(function CurrencyRateRange(
	props: CurrencyRateRangeProps
) {
	const { value, onChange, name } = props;
	const { t } = useTranslation();
	const { bp } = useBreakpoint();
	const [view, setView] = useState<'mobile' | 'desktop'>('mobile');

	useEffect(() => {
		if (bp === 'mobile' || bp === 'tablet') {
			setView('mobile');
		} else {
			setView('desktop');
		}
	}, [bp]);

	const handleChange = debounce(onChange, 100);
	const ranges = [
		{ label: t(`timeRange.${view}.day`), value: Range.DAY },
		{ label: t(`timeRange.${view}.week`), value: Range.WEEK },
		{ label: t(`timeRange.${view}.month`), value: Range.MONTH },
		{ label: t(`timeRange.${view}.year`), value: Range.YEAR },
		{ label: t(`timeRange.${view}.2y`), value: Range.TWO_YEARS }
	];

	return (
		<RadioGroup>
			<HiddenLegend>{t('timeRangeLegend')}</HiddenLegend>
			{ranges.map(range => (
				<RadioButton
					id={`${name}-${range.value}`}
					label={range.label}
					key={range.value}
					value={range.value}
					onChange={() => handleChange(range.value)}
					checked={value === range.value}
				/>
			))}
		</RadioGroup>
	);
});
