import React from 'react';
import { Grid } from 'components/common';
import { Helmet } from 'react-helmet-async';
import { mq, styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { FxRate, QueryParams } from 'types';
import { CurrencyZone } from '../CurrencyZone';

interface CalculatorProps {
	rate?: FxRate;
	query?: QueryParams;
}

const Heading = styled.h1`
	font-weight: 300;
	padding: 0 12px;

	${mq({
		margin: ['24px 0 0', '48px 0 0'],
		fontSize: ['1.75rem', '2rem', '2.5rem'],
		lineHeight: ['28px', '34px', '40px', '50px']
	})};
`;

export const Calculator = (props: CalculatorProps) => {
	const { t } = useTranslation();

	return (
		<Grid>
			<Helmet title="Calculator" />
			<Heading>{t('calculator.heading')}</Heading>
			<CurrencyZone {...props} />
		</Grid>
	);
};
