import { css, styled, typography } from 'utils/styles';

export const Disclaimer = styled.div(
	({ theme: { space, colors, fontWeight } }) => css`
		color: ${colors.typography};
		margin: ${space.large} 0;

		p {
			${typography.s6}
		}

		h2,
		h3 {
			${typography.s5}
			font-weight: ${fontWeight.bold};
		}
	`
);
