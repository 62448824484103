import React from 'react';
import { DataView } from 'types';
import { styled, visuallyHidden } from 'utils';
import { useTranslation } from 'utils/i18next';
import { ViewOption, ViewOptions } from './common';

interface CurrencyRateViewOptionsProps {
	view: DataView;
	setView: (view: DataView) => void;
}

const HiddenLegend = styled.legend(visuallyHidden);

export const CurrencyRateViewOptions = (props: CurrencyRateViewOptionsProps) => {
	const { view, setView } = props;
	const { t } = useTranslation();

	return (
		<ViewOptions flex={[1, 0]}>
			<HiddenLegend>{t('labels.view') + ':'}</HiddenLegend>
			<ViewOption aria-pressed={view === 'CHART'} onClick={() => setView('CHART')}>
				{t('labels.chart')}
			</ViewOption>
			<ViewOption aria-pressed={view === 'TABLE'} onClick={() => setView('TABLE')}>
				{t('labels.table')}
			</ViewOption>
		</ViewOptions>
	);
};
