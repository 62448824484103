import React, { useCallback, useState } from 'react';
import { Currency, FormState } from 'types';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { event } from 'utils/logger';
import { useTranslation } from 'utils/i18next';
import { css, mq, styled } from 'utils/styles';
import { SwitchIcon } from 'components/icons';
import { AmountInput, CurrencySelect, ErrorMessage } from 'components/form';
import { Button } from 'components/common';
import { SelectOption, SingleValue } from './form/Select';

interface CurrencyRateFormProps {
	state: FormState;
	sendEvent?: SendEvent;
	onChange: (formState: Partial<FormState>) => void;
}

const StyledForm = styled.form(
	({ theme: { space } }) => css`
		display: grid;
		gap: 4px 8px;

		${mq({
			gridAutoFlow: 'column',
			gridTemplateColumns: [
				'1fr 2fr',
				'minmax(100px, 1fr) minmax(100px, 2fr) auto minmax(100px, 2fr)',
				'minmax(100px, 2fr) minmax(100px, 3fr) auto minmax(100px, 3fr)',
				'minmax(100px, 2fr) minmax(100px, 3fr) auto minmax(100px, 3fr) 3fr'
			],
			gridTemplateRows: ['repeat(6, auto)', 'auto auto 15px'],
			marginTop: [space.large],
			marginBottom: [space.large, space.none]
		})}
	`
);

const FlipCcyButton = styled(Button)(
	mq({
		height: '44px',
		gridColumn: ['1/2', 'auto'],
		gridRow: ['5/6', 'auto']
	})
);

const FromCcyLabel = styled.label(
	mq({
		gridColumn: ['2/3', 'auto']
	})
);

const FromCcySelect = styled(CurrencySelect)(
	mq({
		gridColumn: ['2/3', 'auto']
	})
);

export const CurrencyRateForm = (props: CurrencyRateFormProps) => {
	const { state, onChange, sendEvent = event } = props;
	const [amountError, setAmountError] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleCcyFromChange = useCallback(
		(option: SingleValue<SelectOption<Currency>>) => {
			if (option) {
				onChange({ ccyFrom: option.value });
				sendEvent(EVENTS.PREDEFINED_EVENTS.SELECT_FROM_CCY(option.value));
			}
		},
		[onChange, sendEvent]
	);

	const handleCcyToChange = useCallback(
		(option: SingleValue<SelectOption<Currency>>) => {
			if (option) {
				onChange({ ccyTo: option.value });
				sendEvent(EVENTS.PREDEFINED_EVENTS.SELECT_TO_CCY(option.value));
			}
		},
		[onChange, sendEvent]
	);

	return (
		<StyledForm data-testid="currency-rate-form">
			<label htmlFor="currency-rate-form-amount-input">{t('labels.amount')}</label>
			<AmountInput
				id="currency-rate-form-amount-input"
				name="currency-rate-form-amount-input"
				data-testid="currency-rate-form-amount-input"
				placeholder={t('labels.amount')}
				autoComplete="off"
				value={state.amount}
				onChange={amount => {
					if (`${amount}` !== `${state.amount}`) {
						sendEvent(EVENTS.PREDEFINED_EVENTS.INPUT_CONVERT_AMOUNT(amount));
					}
					onChange({ amount });
				}}
				onValidate={err => setAmountError(err)}
			/>
			<ErrorMessage>{amountError && t('errors.invalidNumber')}</ErrorMessage>
			<FromCcyLabel htmlFor="currency-rate-form-ccy-from-select">
				{t('labels.ccyFrom')}
			</FromCcyLabel>
			<FromCcySelect
				name="currency-rate-form-ccy-from-select"
				id="currency-rate-form-ccy-from-select-instance"
				instanceId="currency-rate-form-ccy-from-select-instance"
				inputId="currency-rate-form-ccy-from-select"
				placeholder={t('labels.ccyChooseFrom')}
				value={state.ccyFrom}
				excludeValue={state.ccyTo}
				onChange={handleCcyFromChange}
			/>
			<div />
			<div />
			<FlipCcyButton
				size="small"
				variant="tertiary"
				data-testid="currency-rate-form-invert-button"
				aria-label={t('aria.invertCcys')}
				onClick={() => {
					sendEvent(
						EVENTS.PREDEFINED_EVENTS.SWITCH_QUOTE(`${state.ccyTo}${state.ccyFrom}`)
					);
					onChange({
						ccyFrom: state.ccyTo,
						ccyTo: state.ccyFrom
					});
				}}
			>
				<SwitchIcon verticalAlign="middle" />
			</FlipCcyButton>
			<div />
			<label htmlFor="currency-rate-form-ccy-to-select">{t('labels.ccyTo')}</label>
			<CurrencySelect
				name="currency-rate-form-ccy-to-select"
				id="currency-rate-form-ccy-to-select-instance"
				instanceId="currency-rate-form-ccy-to-select-instance"
				inputId="currency-rate-form-ccy-to-select"
				placeholder={t('labels.ccyChooseTo')}
				value={state.ccyTo}
				excludeValue={state.ccyFrom}
				onChange={handleCcyToChange}
			/>
			<div />
		</StyledForm>
	);
};
