import { SVGAttributes } from 'react';
import { css, styled } from 'utils/styles';
import { space, SpaceProps, verticalAlign, VerticalAlignProps } from 'styled-system';

type Size = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
type IconSize = {
	[i in Size]: string;
};

const sizeMap: IconSize = {
	tiny: '12px',
	small: '14px',
	medium: '20px',
	large: '24px',
	xlarge: '40px'
};

export interface SVGProps extends SVGAttributes<SVGElement>, SpaceProps, VerticalAlignProps {
	size?: Size;
}

export const SVG = styled.svg<SVGProps>(
	({ theme: { colors }, fill, size = 'medium' }) => css`
		width: ${sizeMap[size]};
		height: ${sizeMap[size]};
		transition: fill 0.25s;
		fill: ${fill || colors.grey5};

		rect {
			fill: none;
		}
	`,
	space,
	verticalAlign
);
