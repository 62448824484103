import React, { SelectHTMLAttributes } from 'react';
import { styled, css, mq } from 'utils/styles';
import { ChevronIcon } from 'components/icons';

export interface DropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
	isError?: boolean;
}

const DropdownContainer = styled.div`
	position: relative;
	display: inline-block;
	${mq({
		width: ['100%', 'auto']
	})}
`;

const DropdownSelect = styled.select<DropdownProps>(
	({ theme: { colors, fontSize }, isError }) => css`
		width: 100%;
		padding: 9px 50px 9px 12px;
		line-height: 1.5;
		font-size: ${fontSize.medium};
		background-color: ${isError ? colors.rag.redTint : 'none'};
		border: 1px solid ${isError ? colors.rag.red : colors.grey5};
		cursor: pointer;
		white-space: nowrap;
		text-overflow: ellipsis;
		appearance: none;
	`
);

const DropdownIndicator = styled.div`
	width: 36px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 0;
	pointer-events: none;
`;

export const Dropdown = (props: DropdownProps) => {
	const { children, ...selectProps } = props;

	return (
		<DropdownContainer>
			<DropdownSelect {...selectProps}>{children}</DropdownSelect>
			<DropdownIndicator>
				<ChevronIcon size="small" />
			</DropdownIndicator>
		</DropdownContainer>
	);
};
