import React from 'react';
import { styled } from 'utils/styles';
import { SVG, SVGProps } from './SVG';

const StyledSVG = styled(SVG)`
	// transpose the icon as the original has wrong direction
	transform: scaleY(-1) rotate3d(0, 0, 1, -90deg);
`;

export const SwitchIcon = (props: SVGProps) => (
	<StyledSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" y="-0.43" width="18" height="18" />
			<path d="M5,2.57H3.8V15L0,11.24v1.69l4.4,4.4L9,12.73V11L5,15Zm8.6-.77L9,6.4V8.1l4-4V16.57h1.2V4.1L18,7.9V6.2Z" />
		</g>
	</StyledSVG>
);
