import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ExternalLink, Header, Page } from 'components/common';

export const PrivacyGeneralUsers = () => (
	<Page>
		<Helmet title="Privacy General Users" />
		<Header>
			<h1>Website Privacy Notice</h1>
		</Header>
		<div>
			<h2>Your privacy</h2>
			<p>
				Your privacy is important to us. This notice (Privacy Notice) applies to personal
				information we collect from you when you use this site. If you're an HSBC customer,
				or otherwise have a relationship with us, we may also have provided you with a
				separate privacy notice setting out how we use your personal information, which will
				also apply. You can also find that privacy notice by visiting the website for the
				product or services we provide you (for example,{' '}
				<ExternalLink href="https://www.hsbc.co.uk/">www.hsbc.co.uk</ExternalLink> or{' '}
				<ExternalLink href="https://www.gbm.hsbc.com/">www.gbm.hsbc.com</ExternalLink>) or
				by contacting your customer service team.
			</p>
			<p>
				Some of the links on this website may lead to HSBC or non-HSBC websites with their
				own privacy notices, which may be different to this notice, and you should read
				those notices carefully.
			</p>
			<p>
				For the purposes of this Privacy Notice, the data controller is HSBC Bank plc with
				its address at 8 Canada Square, London E14 5HQ, UK. We've also set out contact
				details towards the end of this notice.
			</p>

			<h2>Information we may collect from you</h2>
			<p>We may collect and process the following information about you:</p>
			<ul>
				<li>
					Information that you provide to us, such as when you fill out a contact or web
					form, or if you register to receive alerts or updates
				</li>
				<li>
					Information that we obtain or learn, such as information about the browser or
					device you use to access this site, how you use this site and the pages you
					visit, traffic and location data
				</li>
			</ul>
			<p>
				We may also ask you for information if you experience problems when using this site.
				We may also ask you to complete surveys for research purposes, although you don't
				have to respond to these.
			</p>
			<p>
				If we have an existing relationship with you, and we are able to identify you from
				information obtained or provided by your use of the site, we may associate those
				sets of information, for example to enable us to respond to a query you have
				submitted.
			</p>

			<h2>How we use your information</h2>
			<p>
				We'll only use your information where we have your consent or we have another lawful
				reason for using it. Unless we say otherwise below, we'll use your personal
				information on the basis that it's within our legitimate interests in operating and
				maintaining the site, and providing you with site functionality and related
				services. We use information provided or obtained via this site to:
			</p>
			<ul>
				<li>
					respond to your queries and reports (for example, if you've asked a question or
					submitted a report via the site)
				</li>
				<li>
					provide you with information, products or services that you request from us or
					which we feel may interest you, where you have consented to be contacted for
					such purposes
				</li>
				<li>
					carry out our obligations from any contracts entered into between you and us
				</li>
				<li>allow you to participate in any interactive features of the site</li>
				<li>notify you about changes to the site</li>
				<li>
					ensure site content is presented in the most effective manner for the device
					you're accessing it from
				</li>
				<li>
					undertake data analytics to learn more about how you and others interact with
					this site and with our advertising
				</li>
				<li>detect and prevent misuse or abuse of this site or our services</li>
			</ul>
			<p>
				We also use information to meet our compliance obligations, to comply with other
				laws and regulations and to share with regulators and other authorities that HSBC
				Group companies are subject to. This may include using it to help detect or prevent
				crime (including terrorism financing, money laundering and other financial crimes).
				We'll only do this on the basis that it's needed to comply with a legal obligation,
				and it's in our legitimate interests and that of others or to prevent or detect
				unlawful acts.
			</p>

			<h2>Who we'll share your information with</h2>
			<p>
				We may share your information in order to provide you with products or services
				you've requested (for example, if they're not provided by the HSBC entity operating
				this site), if we have a legitimate interest in doing so (e.g. to manage risk,
				verify your identity, to combat fraud, abuse of our site or services), or where
				you've agreed to us doing so.{' '}
			</p>
			<p>
				We may share your information with others including other HSBC group companies and
				any of our service providers, anyone else whose products and services you've
				requested, anyone who we're under an obligation to disclose information to or where
				it's in the public interest, (for example to prevent or detect fraud, abuse of our
				site or services).{' '}
			</p>

			<h2>IP addresses</h2>
			<p>
				We may collect information about your computer (or mobile device), including where
				available your IP address, operating system and browser type, for system
				administration or for our own commercial purposes. This is statistical data about
				our users' browsing actions and patterns, and does not identify any individual.{' '}
			</p>

			<h2>Cookies</h2>
			<p>
				We use cookies and similar technologies to distinguish you from other users of this
				site, to improve your experience when accessing this site, and to improve the site.
				Detailed information on the cookies we use and the purposes for which we use them
				are set out in our <Link to="/cookie-notice">Cookie Notice</Link>.{' '}
			</p>

			<h2>How long we'll keep your information</h2>
			<p>
				We keep your information in line with our data retention policy. For example, we'll
				normally keep core banking data for a period of seven years from the end of our
				relationship with a client. This enables us to comply with legal and regulatory
				requirements or use it where we need to for our legitimate purposes, e.g. to help us
				respond to queries or complaints, fighting fraud and financial crime, responding to
				requests from regulators, etc. We may need to retain your information for a longer
				period, again, where we need the information to comply with regulatory or legal
				requirements or where we may need it for our legitimate purposes. If we don't need
				to retain information for this period of time, we may destroy, delete or anonymise
				it more promptly.{' '}
			</p>

			<h2>Transferring your information overseas</h2>
			<p>
				Your information may be transferred to and stored in locations outside the European
				Economic Area (EEA), including countries that may not have the same level of
				protection for personal information. When we do this, we'll ensure it has an
				appropriate level of protection and that the transfer is lawful.{' '}
			</p>
			<p>
				You can obtain more details of the protection given to your information when it's
				transferred outside the EEA by contacting us using the details in the ‘More details
				about your information' section below.{' '}
			</p>

			<h2>Your rights</h2>
			<p>
				You have a number of rights in relation to the information that we hold about you.
				These rights include:{' '}
			</p>
			<ul>
				<li>
					the right to access information we hold about you and to obtain information
					about how we process it;
				</li>
				<li>
					in some circumstances, the right to withdraw your consent to our processing of
					your information, which you can do at any time. We may continue to process your
					information if we have another legitimate reason for doing so;
				</li>
				<li>
					in some circumstances, the right to receive certain information you have
					provided to us in an electronic format and / or request that we transmit it to a
					third party;
				</li>
				<li>
					the right to request that we rectify your information if it's inaccurate or
					incomplete;
				</li>
				<li>
					in some circumstances, the right to request that we erase your information. We
					may continue to retain your information if we're entitled or required to retain
					it;
				</li>
				<li>
					the right to object to, and to request that we restrict, our processing of your
					information in some circumstances. Again, there may be situations where you
					object to, or ask us to restrict, our processing of your information but we're
					entitled to continue processing your information and / or to refuse that
					request.
				</li>
			</ul>
			<p>
				You can exercise your rights by contacting us using the details set out in the ‘More
				details about your information' section below. You also have a right to complain to
				the UK Information Commissioner's Office by visiting{' '}
				<ExternalLink href="https://www.ico.org.uk/">www.ico.org.uk</ExternalLink>, or to
				the data protection regulator in the country where you live or work.{' '}
			</p>

			<h2>How we keep your information secure</h2>
			<p>
				We use a range of measures to keep your information safe and secure which may
				include encryption and other forms of security. We require our staff and any third
				parties who carry out any work on our behalf to comply with appropriate compliance
				standards including obligations to protect any information and applying appropriate
				measures for the use and transfer of information.{' '}
			</p>

			<h2>More details about your information</h2>
			<p>
				If you'd like further information on anything we've said in this Privacy Notice, or
				to contact our Data Protection Officer, contact us at P.O. Box 6201, Coventry CV3
				9HW addressed ‘for the attention of the DPO'.{' '}
			</p>
			<p>
				This Privacy Notice may be updated from time to time, and you'll always be able to
				find the most recent version on this site.
			</p>
			<p>Last updated: May 2018</p>
		</div>
	</Page>
);

export default PrivacyGeneralUsers;
