const createQueue = transformer => loggerFunction => {
	let currentlySending = false;
	let flushPending = false;
	const messageQueue = [];
	const variance = Math.floor(Math.random() * 5000);
	const flushTheQueue = () => {
		if (!messageQueue.length) {
			return;
		}
		const messages = messageQueue.slice(0);
		const payload = typeof transformer === 'function' ? transformer(messages) : messages;
		currentlySending = true;
		messageQueue.length = 0;
		loggerFunction(payload)
			.then(
				() => {
					currentlySending = false;
					flushTheQueue();
				},
				() => {
					throw new Error();
				}
			)
			.catch(() => {
				messageQueue.unshift(messages);
				setTimeout(flushTheQueue, 30000);
			});
	};

	const tryFlush = () => {
		if (!flushPending) {
			flushPending = true;
			setTimeout(() => {
				if (!currentlySending) {
					flushTheQueue();
				}
				flushPending = false;
			}, 10000 + variance);
		}
	};

	const addToQueue = data => {
		messageQueue.push(data);
		tryFlush();
	};

	return addToQueue;
};

export const batchToStrings = createQueue(arr => arr.join('\n'));

export const batch = createQueue();
