import React from 'react';
import { FxRate } from 'types';
import { head } from 'lodash';
import { styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { formatAmount } from 'utils/formatters';
import { calcCurrentRateChange } from 'utils/currency';
import { ArrowDownIcon, ArrowUpIcon } from './icons';
import { EmptyBox, EmptyState, Change } from './common';

interface CurrencyRateChartLegendProps {
	isLoading: boolean;
	fxRate: FxRate | null;
}

const DescriptionList = styled.dl`
	white-space: nowrap;
	overflow-x: auto;
	width: 100%;
	padding-bottom: ${({ theme }) => theme.space.small};
`;

const Box = styled.div`
	padding: 0 ${({ theme }) => theme.space.medium};
	border-left: 1px solid;
	min-width: 80px;
	display: inline-block;
`;

const Term = styled.dt`
	font-size: ${({ theme }) => theme.fontSize.small};
	margin-bottom: ${({ theme }) => theme.space.medium};
`;

const Description = styled.dd`
	margin: 0;
`;

const ChangeDescription = styled(Change)`
	margin: 0;
`;

export const CurrencyRateChartLegend = (props: CurrencyRateChartLegendProps) => {
	const { isLoading, fxRate } = props;
	const { t } = useTranslation();

	if (isLoading || !fxRate || fxRate.dataSet.length === 0) {
		return (
			<EmptyState
				gridGap="medium"
				justifyContent="flex-start"
				flexDirection="row"
				flexWrap="wrap"
				width={342}
				height={65}
			>
				<EmptyBox flex={1} />
				<EmptyBox flex={1} />
				<EmptyBox flex={1} />
				<EmptyBox flex={1} />
			</EmptyState>
		);
	}

	const rateChange = calcCurrentRateChange(fxRate);
	const firstRate = head(fxRate.dataSet.filter(({ open }) => open))?.open;

	return (
		<DescriptionList data-testid="chart-legend">
			<Box>
				<Term id="chart-legend-start-rate">{t('labels.start')}</Term>
				<Description aria-labelledby="chart-legend-start-rate">
					{firstRate ? formatAmount(firstRate, fxRate.decimalPlaces) : 'n/a'}
				</Description>
			</Box>
			<Box>
				<Term id="chart-legend-lowest-rate">{t('labels.low')}</Term>
				<Description aria-labelledby="chart-legend-lowest-rate">
					{fxRate.low ? formatAmount(fxRate.low, fxRate.decimalPlaces) : 'n/a'}
				</Description>
			</Box>
			<Box>
				<Term id="chart-legend-highest-rate">{t('labels.high')}</Term>
				<Description aria-labelledby="chart-legend-highest-rate">
					{fxRate.high ? formatAmount(fxRate.high, fxRate.decimalPlaces) : 'n/a'}
				</Description>
			</Box>
			<Box style={{ minWidth: 102 }}>
				<Term id="chart-legend-percentage-change">{t('labels.change')}</Term>
				<ChangeDescription
					as="dd"
					change={rateChange}
					aria-labelledby="chart-legend-percentage-change"
				>
					{rateChange !== null && rateChange > 0 && <ArrowUpIcon size="small" />}
					{rateChange !== null && rateChange < 0 && <ArrowDownIcon size="small" />}
				</ChangeDescription>
			</Box>
		</DescriptionList>
	);
};
