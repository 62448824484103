import React from 'react';
import { Link } from 'react-router-dom';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { event } from 'utils/logger';
import { css, styled, typography } from 'utils/styles';
import { space, SpaceProps } from 'styled-system';
import { Accordion } from 'components/common';

interface RateAlertModalAccordionsProps {
	sendEvent?: SendEvent;
}

const Accordions = styled.div<SpaceProps>(space);

const StyledDisclaimer = styled.div(
	({ theme: { space, colors } }) => css`
		${typography.s6}
		margin: ${space.small} 0;

		a {
			color: ${colors.typography};
		}
	`
);

const StyledAsterisk = styled.div(typography.s7, space);

const StyledList = styled.div`
	${typography.s6}
	text-align: justify;
	margin: 0.75rem 0 0.75rem -20px;
`;

export const RateAlertAccordions = (props: RateAlertModalAccordionsProps) => {
	const { sendEvent = event } = props;

	return (
		<Accordions mb="large">
			<Accordion summary="Summary of Rate Alert Terms of Service">
				<StyledDisclaimer>
					<p>
						By creating an alert, you consent to receive email communications from HSBC
						associated with the rate alert.
					</p>
					<p>
						The rate alert will be triggered if HSBC’s mid-market rate reaches the
						specified level. HSBC’s mid-market rate is non-executable and should be used
						for informational purposes only. The tradeable prices offered to you by HSBC
						will differ to the mid-market rate.
					</p>
					<p>
						Whilst we have taken steps to ensure the accuracy, availability and
						correctness of this rate alerts service, we do not give or make any warranty
						of any kind. Please see{' '}
						<Link
							to="/terms-conditions"
							onClick={() => sendEvent(EVENTS.PREDEFINED_EVENTS.TERMS_AND_CONDITIONS)}
						>
							Full Terms & Conditions
						</Link>{' '}
						for more information.
					</p>
					<p>
						As such, the delivery of rate alerts is on a best efforts basis and there is
						no minimum service level nor guarantee with respect to the delivery of an
						email notification should the rate in question reach the level specified.
					</p>
					<p>
						We reserve the right to cancel your rate alert or stop providing you with
						this service at any time without notice.
					</p>
				</StyledDisclaimer>
			</Accordion>
			<Accordion summary="How do Rate Alerts work?">
				<StyledList>
					<ul>
						<li>
							We will send you an email notification if HSBC’s mid-market rate for the
							currency pair you have selected reaches the level specified*.
						</li>
						<li>You can have a maximum of 20 live rate alerts at any one time.</li>
						<li>
							If your rate alert has not triggered for 6 months then it will expire.
							We will notify you if your alert expires*.
						</li>
						<li>
							You can cancel this rate alert at any time by using the links in the
							emails we send you.
						</li>
						<StyledAsterisk mt={'12px'}>*As per Terms of Service</StyledAsterisk>
					</ul>
				</StyledList>
			</Accordion>
		</Accordions>
	);
};
