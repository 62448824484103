import React from 'react';
import { SVG, SVGProps } from './SVG';

export const YesThick = (props: SVGProps) => (
	<SVG focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<polygon points="5.92 15.94 0.11 10.13 1.38 8.86 5.92 13.4 16.62 2.69 17.89 3.97 5.92 15.94" />
		</g>
	</SVG>
);
