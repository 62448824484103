import Decimal from 'decimal.js-light';
import { head, includes } from 'lodash';
import { Currency, FxRate } from 'types';
import { availableCurrencies, ccyConfig, metalCodes } from 'common/currencies';
import { formatAmount } from './formatters';

export { default as Decimal } from 'decimal.js-light';
export * from 'utils/styles';

Decimal.set({
	precision: 20,
	rounding: Decimal.ROUND_HALF_UP,
	toExpNeg: -7,
	toExpPos: 21
});

export const splitCcyPair = (ccyPair: string): [Currency, Currency] => {
	if (ccyPair.length !== 6) {
		// @ts-ignore todo
		return ['', ''];
	}

	// todo: add typeguard
	const fromCcy = ccyPair.slice(0, 3) as Currency;
	const toCcy = ccyPair.slice(3) as Currency;

	return [fromCcy, toCcy];
};

export const invertCcyPair = (ccyPair: string): string => {
	const [from, to] = splitCcyPair(ccyPair);

	return to + from;
};

export const isCcyValid = (ccy: string): boolean =>
	availableCurrencies.findIndex(availableCcy => availableCcy === ccy) !== -1;

export const isMetal = (currency: Currency) => includes(metalCodes, currency);

export const isRangeValid = (range: string): boolean =>
	['day', 'month', 'week', 'year', '2y'].includes(range);

export const calcCurrentRateChange = (rate: FxRate): number | null => {
	const data = rate.dataSet.map(({ open }) => open).filter(open => open !== null) as number[];
	const firstRate = head(data);
	const lastRate = rate.latest?.open;

	if (firstRate && lastRate) {
		return calcRateChange(firstRate, lastRate);
	}

	return null;
};

export const calcRateChange = (firstRate: number, lastRate: number): number =>
	((lastRate - firstRate) / firstRate) * 100;

export const convertCurrency = (amount: number, fxRate: FxRate): [string, string] => {
	const [, ccyTo] = splitCcyPair(fxRate.ccyPair);
	const config = ccyConfig[ccyTo];

	if (!fxRate.latest || !fxRate.latest.open || !config) {
		return ['', ''];
	}

	const dAmount = new Decimal(amount);
	const rate = new Decimal(fxRate.latest.open);
	const [integer, decimals = ''] = dAmount.times(rate).toString().split('.');

	const result = {
		integer: integer,
		decimals: config.decimals ? decimals.padEnd(config.decimals, '0') : decimals,
		rest: ''
	};

	if (isMetal(ccyTo)) {
		return [
			result.decimals ? result.integer + '.' + result.decimals : result.integer,
			result.rest
		];
	}

	if (config.decimals && result.decimals.length >= config.decimals) {
		result.rest = result.decimals
			.substring(config.decimals, fxRate.decimalPlaces)
			.padEnd(fxRate.decimalPlaces - config.decimals, '0');
		result.decimals = result.decimals.substring(0, config.decimals);
	}

	if (config.decimals === 0 && result.rest) {
		result.rest = '.' + result.rest;
	} else {
		result.decimals = '.' + result.decimals;
	}

	const formattedResult = formatAmount(
		parseFloat(result.integer + result.decimals),
		config.decimals
	);

	return [formattedResult, result.rest];
};
