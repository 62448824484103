import { styled } from 'utils/styles';
import {
	flexbox,
	FlexboxProps,
	grid,
	GridProps,
	layout,
	LayoutProps,
	space,
	SpaceProps
} from 'styled-system';

export const EmptyBox = styled.div<SpaceProps & LayoutProps & FlexboxProps>(space, layout, flexbox);
export const EmptyLine = styled.div<SpaceProps & LayoutProps>(space, layout);
export const EmptyState = styled.div<GridProps & LayoutProps & FlexboxProps & SpaceProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${EmptyLine}, ${EmptyBox} {
		background-color: ${({ theme }) => theme.colors.grey1};
	}

	${grid}
	${layout}
	${flexbox}
	${space}
`;
