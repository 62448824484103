import { log, error } from '../utils/logger';

const { localStorage } = globalThis;

type WrappedItem = {
	item: unknown;
	expiry: number;
};

const wrap = (item: unknown, expireAt: number): WrappedItem => {
	return { item: item, expiry: expireAt };
};

const unwrap = (valueFromLocal: WrappedItem): unknown => {
	return valueFromLocal.hasOwnProperty('item') ? valueFromLocal.item : valueFromLocal;
};

const expireIn1Year = (): number => {
	const expireIn1Year = new Date();
	expireIn1Year.setDate(expireIn1Year.getDate() + 365);
	return expireIn1Year.getTime();
};

export const getItem = (key: string, defaultVal?: unknown): unknown => {
	let valueFromLocal: WrappedItem | undefined;
	try {
		const item = localStorage.getItem(key);
		valueFromLocal = item ? JSON.parse(item) : undefined;
		const now = new Date();
		if (valueFromLocal && now.getTime() > valueFromLocal.expiry) {
			log(
				`Value from local storage has been found, however it expired at '${valueFromLocal.expiry}'. Dropping it.`
			);
			localStorage.removeItem(key);
			valueFromLocal = undefined;
		}
	} catch (e) {
		error(`Could not parse value from local storage for key ${key} returning default value`, e);
		valueFromLocal = undefined;
	}
	return valueFromLocal ? unwrap(valueFromLocal) : defaultVal;
};

export const setItem = (key: string, item: unknown, expireAt: number = expireIn1Year()): void => {
	try {
		item = wrap(item, expireAt);
		localStorage.setItem(key, JSON.stringify(item));
	} catch (e) {
		error(`Could not stringify item ${item}. Won't be set in local storage`);
	}
};

const storageService = { getItem, setItem };

export default storageService;
