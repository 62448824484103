import { useEffect, useState } from 'react';
import { breakpoints } from 'utils';

type Breakpoints = 'mobile' | 'tablet' | 'desktop' | 'desktop_xl' | 'desktop_xxl';

const getCurrentBreakpoint = () => {
	if (globalThis.innerWidth < parseInt(breakpoints[0])) {
		return 'mobile';
	} else if (globalThis.innerWidth < parseInt(breakpoints[1])) {
		return 'tablet';
	} else if (globalThis.innerWidth < parseInt(breakpoints[2])) {
		return 'desktop';
	} else if (globalThis.innerWidth < parseInt(breakpoints[3])) {
		return 'desktop_xl';
	} else {
		return 'desktop_xxl';
	}
};

export const useBreakpoint = () => {
	const [breakpoint, setBreakpoint] = useState<Breakpoints>(getCurrentBreakpoint);

	useEffect(() => {
		const handleResize = () => {
			const bp = getCurrentBreakpoint();
			setBreakpoint(bp);
		};

		globalThis.addEventListener('resize', handleResize);

		return () => {
			globalThis.removeEventListener('resize', handleResize);
		};
	}, []);

	return { bp: breakpoint };
};
