import React from 'react';
import { css, styled, typography } from 'utils/styles';
import { Link } from 'react-router-dom';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { event } from 'utils/logger';
import { Trans } from 'utils/i18next';

interface TermsAndConditionsDisclaimerProps {
	sendEvent?: SendEvent;
}

const StyledDisclaimer = styled.p(
	({ theme: { space, colors } }) => css`
		${typography.s6}
		margin: ${space.small} 0;

		a {
			color: ${colors.typography};
		}
	`
);

export const TermsAndConditionsDisclaimer = (props: TermsAndConditionsDisclaimerProps) => {
	const { sendEvent = event } = props;

	return (
		<StyledDisclaimer>
			<Trans i18nKey="disclaimers.termsAndCondition.excerpt">
				Disclaimer: The information contained on this site is not (and should not be taken
				as) investment advice, an offer to sell or buy currencies, or other instruments, nor
				to enter into any transactions.
				<Link
					to="/terms-conditions"
					onClick={() => sendEvent(EVENTS.PREDEFINED_EVENTS.TERMS_AND_CONDITIONS)}
				>
					Full Terms & Conditions
				</Link>
			</Trans>
		</StyledDisclaimer>
	);
};
