import React from 'react';
import { useTranslation } from 'utils/i18next';
import { Tooltip, TooltipText } from './Tooltip';
import { HelpIcon } from '../icons';

export const DelayDisclaimer = () => {
	const { t } = useTranslation();

	return (
		<Tooltip content={t('tooltips.delay.content')}>
			<TooltipText>{t('tooltips.delay.excerpt')}</TooltipText>
			<HelpIcon size="small" marginLeft="xsmall" />
		</Tooltip>
	);
};
