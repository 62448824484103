import { InputHTMLAttributes } from 'react';
import { space, SpaceProps } from 'styled-system';
import { styled, css, typography } from 'utils/styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, SpaceProps {
	isError?: boolean;
}

export const Input = styled.input<InputProps>(
	({ theme: { colors, fontSize }, isError = false }) => css`
		width: 100%;
		padding: 9px 12px;
		line-height: 1.5;
		font-size: ${fontSize.medium};
		background-color: ${isError ? colors.rag.redTint : 'none'};
		border: 1px solid ${isError ? colors.rag.red : colors.grey5};
		border-radius: 0;
	`,
	space,
	typography.s5
);
