import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'utils/i18next';
import { useBreakpoint } from 'hooks';
import { styled, mq, typography } from 'utils/styles';
import { getStaticFileURL } from 'utils/url';
import { Dropdown, RadioButton, RadioGroup } from 'components/form';
import { Flex, Grid, LinkButton } from 'components/common';
import { NewWindow } from 'components/icons';
import { BankingRelationship, UserLocation } from 'types';
import { BankingSolutions } from './BankingSolutions';
import { countries } from './form-data';

const Heading = styled.h1`
	${mq({
		margin: [[0], ['46px 0 0 0']]
	})};
	${typography.s1}
`;

const FlexImage = styled(Flex)`
	position: relative;
	padding-top: 66.45%;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;
const StyledContactUs = styled.div`
	padding: 12px;
`;

const StyledHeaders = styled.div`
	h2 {
		display: block;
		font-weight: 300;
		margin: 2rem 0 1rem;
		${mq({
			fontSize: [18, 22]
		})};
	}
`;

const DefaultContactBtn = () => {
	const { t } = useTranslation();

	return (
		<>
			<h2>{t('contact.alternative')}</h2>
			<LinkButton
				size="small"
				href="https://www.gbm.hsbc.com/en-gb/products/payments-fx-solutions#contact-us"
			>
				<NewWindow size="small" marginRight="small" verticalAlign="text-top" />
				{t('labels.contact')}
			</LinkButton>
		</>
	);
};

export const ContactUs = () => {
	const [bankingRelationship, setBankingRelationship] = useState<BankingRelationship>();
	const [userLocation, setUserLocation] = useState<UserLocation>();
	const [country, setCountry] = useState<string>();
	const { bp } = useBreakpoint();
	const { t } = useTranslation();

	useEffect(() => {
		switch (country) {
			case 'United Kingdom':
				return setUserLocation('UK');

			case 'Hong Kong':
				return setUserLocation('HK');

			default:
				setUserLocation('OTHER');
		}
	}, [country]);

	const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setCountry(e.target.value);
	};

	const showDefaultContactBtn = bankingRelationship && country && userLocation === 'OTHER';
	const showBankingSolutions =
		bankingRelationship && country && (userLocation === 'UK' || userLocation === 'HK');

	return (
		<Grid>
			<Helmet title="Contact us" />
			<StyledHeaders>
				<Flex
					alignItems="center"
					flexDirection={['column', 'row']}
					justifyContent="center"
					gridGap={[20, 40, 40, 150]}
					px="medium"
					flex={1}
				>
					<Flex flex={[1, 1, 4]} flexDirection="column" alignItems="flex-start">
						<Heading>{t('contact.title')}</Heading>
						{bp === 'mobile' && (
							<Flex
								flex={1}
								justifyContent="flex-end"
								alignSelf="flex-start"
								width="100%"
							>
								<FlexImage flex={1}>
									<img
										src={getStaticFileURL('assets/banner-girl.png')}
										alt="Woman smiling using HSBC Currency Zone Rates Table"
									/>
								</FlexImage>
							</Flex>
						)}
						<h2>{t('contact.bankingRelationship')}</h2>
						<RadioGroup>
							<RadioButton
								id="contact-us-business"
								value="business"
								label={t('contact.business')}
								onChange={() => setBankingRelationship('business')}
								checked={bankingRelationship === 'business'}
							/>
							<RadioButton
								id="contact-us-personal"
								value="personal"
								label={t('contact.personal')}
								onChange={() => setBankingRelationship('personal')}
								checked={bankingRelationship === 'personal'}
							/>
						</RadioGroup>
						<h2>{t('contact.localMarket')}</h2>
						<Dropdown
							id="contact-us-country"
							name="country"
							value={country}
							onChange={handleRegionChange}
						>
							{countries.map(country => (
								<option key={country} value={country}>
									{country || 'Please select'}
								</option>
							))}
						</Dropdown>
					</Flex>
					{bp !== 'mobile' && (
						<Flex
							flex={[1, 1, 3]}
							justifyContent="flex-end"
							alignSelf="flex-start"
							width="100%"
						>
							<FlexImage flex={1}>
								<img
									src={getStaticFileURL('assets/banner-girl.png')}
									alt="Woman smiling using HSBC Currency Zone Rates Table"
								/>
							</FlexImage>
						</Flex>
					)}
				</Flex>
				<StyledContactUs>
					{showDefaultContactBtn && <DefaultContactBtn />}
					{showBankingSolutions && (
						<>
							<BankingSolutions
								userLocation={userLocation}
								bankingRelationship={bankingRelationship}
							/>
							<DefaultContactBtn />
						</>
					)}
				</StyledContactUs>
			</StyledHeaders>
		</Grid>
	);
};
