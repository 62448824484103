import React from 'react';
import { FxRate, Range } from 'types';
import { styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { FilterDownIcon, HelpIcon } from './icons';
import { EmptyLine, EmptyState, Tooltip } from './common';

interface CurrencyRateWidgetProps {
	range: Range | null;
	fxRate: FxRate | null;
	isLoading: boolean;
}

const Title = styled.p`
	margin: 0 0 36px;
`;

const Widget = styled.div`
	display: flex;
	align-items: center;
`;

const Label = styled.span`
	font-size: ${({ theme }) => theme.fontSize.xsmall};
	color: ${({ theme }) => theme.colors.typography};
`;

const Bar = styled.div`
	margin: 0 8px;
	height: 6px;
	flex: 1;
	position: relative;
	background-color: ${({ theme }) => theme.colors.grey1};
`;

const INDICATOR_OFFSET = 4;

const Indicator = styled.div<{ progress: number }>`
	position: absolute;
	bottom: 6px;
	right: ${({ progress }) => 100 - progress - INDICATOR_OFFSET}%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	transition: right 650ms ease-out;

	svg {
		fill: ${({ theme }) => theme.colors.data.blue4};
	}
`;

const Progress = styled.div<{ progress: number }>`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: ${({ progress }) => 100 - progress}%;
	transition: right 650ms ease-out;
	background-color: ${({ theme }) => theme.colors.data.blue4};
`;

const calcProgress = (low: number | null, high: number | null, now?: number | null): number => {
	if (!low || !high) {
		return 0;
	}

	if (!now || now <= low) {
		return 0;
	} else if (now >= high) {
		return 100;
	} else {
		return Math.round(((now - low) / (high - low)) * 100);
	}
};

export const CurrencyRateWidget = (props: CurrencyRateWidgetProps) => {
	const { range, isLoading, fxRate } = props;
	const { t } = useTranslation();

	if (isLoading || !range || !fxRate || fxRate.dataSet.length === 0) {
		return (
			<EmptyState height={109} width={342} pb="small" justifyContent="space-around">
				<EmptyLine height="large" width="50%" />
				<EmptyLine height="medium" width="90%" />
			</EmptyState>
		);
	}

	const progress = calcProgress(fxRate.low, fxRate.high, fxRate.latest?.open);

	return (
		<div data-testid="currency-rate-widget">
			<Title>
				<Tooltip content={t('tooltips.lowHighPoints.content')}>
					<b>
						{t('tooltips.lowHighPoints.excerpt', {
							last: t('labels.last', { count: range === Range.DAY ? 1 : 2 }),
							range: t(`timeRange.desktop.${range}`)
						})}
					</b>
					{` ${fxRate.ccyPair}`}
					<HelpIcon size="small" marginLeft="xsmall" />
				</Tooltip>
			</Title>
			<Widget>
				<Label aria-hidden="true">{t('labels.low')}</Label>
				<Bar>
					<Progress data-testid="currency-rate-widget-progress-bar" progress={progress} />
					<Indicator progress={progress}>
						<Label aria-hidden="true">{t('labels.now')}</Label>
						<FilterDownIcon size="small" />
					</Indicator>
				</Bar>
				<Label aria-hidden="true">{t('labels.high')}</Label>
			</Widget>
		</div>
	);
};
