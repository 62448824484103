import React, { useEffect, useRef } from 'react';
import { styled, useTheme } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { Rate } from 'types';
import { Chart } from './Chart';

interface ChartRangeSelectorProps {
	dataSet?: Rate[];
	chartRef: React.RefObject<HTMLElement>;
	onChange: (dataSet: Rate[]) => void;
}

const ChartPreview = styled.div`
	height: 60px;
	position: relative;

	canvas {
		border: 1px solid ${({ theme }) => theme.colors.grey5};
		position: absolute;
		width: 100% !important;
		height: 100% !important;
	}
`;

const RANGE_STEP = 30;
const RANGE_SEL_OFFSET = 2;
const RANGE_SEL_INIT_LEFT_POS = -RANGE_SEL_OFFSET;
const RANGE_SEL_INIT_RIGHT_POS = -RANGE_SEL_OFFSET;

const RangeSelector = styled.div`
	left: ${RANGE_SEL_INIT_LEFT_POS}px;
	right: ${RANGE_SEL_INIT_RIGHT_POS}px;
	top: 0;
	bottom: 0;
	position: absolute;
	border: 0 solid ${({ theme }) => theme.colors.grey5};
	border-left-width: 3px;
	border-right-width: 3px;
	background: rgba(56, 98, 251, 0.18);
	cursor: move;
`;

const RangeHandler = styled.span`
	position: absolute;
	background: none;
	border: none;
	height: 100%;
	width: 16px;
	cursor: ew-resize;

	&::after {
		content: '||';
		background: white;
		padding: 5px 4px 8px;
		border-radius: 8px;
		position: absolute;
		color: ${({ theme }) => theme.colors.grey5};
		left: 0;
		top: 12px;
		border: 1px solid ${({ theme }) => theme.colors.grey5};
		box-shadow: 0 0 2px ${({ theme }) => theme.colors.grey5};
	}

	&:nth-of-type(1) {
		left: -9px;
	}
	&:nth-of-type(2) {
		right: -9px;
	}
`;

export const ChartRangeSelector = (props: ChartRangeSelectorProps) => {
	const { dataSet = [], onChange, chartRef } = props;
	const theme = useTheme();
	const chartPreviewRef = useRef<HTMLDivElement>(null);
	const rangeRef = useRef<HTMLDivElement>(null);
	const startHandlerRef = useRef<HTMLButtonElement>(null);
	const endHandlerRef = useRef<HTMLButtonElement>(null);
	const { t } = useTranslation();

	useEffect(() => {
		const chart = chartRef.current;
		const startHandler = startHandlerRef.current;
		const endHandler = endHandlerRef.current;
		const range = rangeRef.current;
		const chartPreview = chartPreviewRef.current;

		if (chart && startHandler && endHandler && range && chartPreview) {
			let lastX = 0;
			let rangeLeft = RANGE_SEL_INIT_LEFT_POS;
			let rangeRight = RANGE_SEL_INIT_RIGHT_POS;

			range.style.left = rangeLeft + 'px';
			range.style.right = rangeLeft + 'px';

			const isBtnPressed = (e: MouseEvent) => e.buttons === 1;

			const sliceDataSet = (left: number, right: number) => {
				const min = Math.round(((left < 0 ? 0 : left) / chartPreview.offsetWidth) * 100);
				const max = Math.round(((right < 0 ? 0 : right) / chartPreview.offsetWidth) * 100);

				return dataSet.slice(
					Math.round(dataSet.length * (min / 100)),
					dataSet.length - Math.round(dataSet.length * (max / 100))
				);
			};

			const rangeResize = (e: WheelEvent) => {
				e.preventDefault();

				if (e.deltaY > 0) {
					rangeLeft = Math.max(RANGE_SEL_INIT_LEFT_POS, rangeLeft - RANGE_STEP);
					rangeRight = Math.max(RANGE_SEL_INIT_RIGHT_POS, rangeRight - RANGE_STEP);
				} else {
					rangeLeft = Math.min(
						chartPreview.offsetWidth - rangeRight - 20,
						rangeLeft + RANGE_STEP
					);
					rangeRight = Math.min(
						chartPreview.offsetWidth - rangeLeft - 20,
						rangeRight + RANGE_STEP
					);
				}

				range.style.left = rangeLeft + 'px';
				range.style.right = rangeRight + 'px';
				onChange(sliceDataSet(rangeLeft, rangeRight));
			};

			const startHandlerResize = (pageX: number): void => {
				const distX = pageX - lastX;
				const targetXLeft = range.offsetLeft + distX;
				rangeLeft = Math.max(
					RANGE_SEL_INIT_LEFT_POS,
					Math.min(chartPreview.offsetWidth - rangeRight - RANGE_STEP, targetXLeft)
				);
				range.style.left = rangeLeft + 'px';
				onChange(sliceDataSet(rangeLeft, rangeRight));
				lastX = pageX;
			};

			const endHandlerResize = (pageX: number): void => {
				const maxRight = chartPreview.offsetWidth;
				const distX = pageX - lastX;
				const targetXRight = maxRight - (range.offsetLeft + range.offsetWidth + distX);
				rangeRight = Math.max(
					RANGE_SEL_INIT_RIGHT_POS,
					Math.min(chartPreview.offsetWidth - rangeLeft - RANGE_STEP, targetXRight)
				);
				range.style.right = rangeRight + 'px';
				onChange(sliceDataSet(rangeLeft, rangeRight));
				lastX = pageX;
			};

			const rangeMove = (pageX: number): void => {
				const maxLeft = chartPreview.offsetWidth - range.offsetWidth + RANGE_SEL_OFFSET;
				const maxRight = chartPreview.offsetWidth;
				const distX = pageX - lastX;
				const targetXLeft = range.offsetLeft + distX;
				const targetXRight = maxRight - (range.offsetWidth + targetXLeft);
				rangeLeft = Math.min(maxLeft, Math.max(RANGE_SEL_INIT_LEFT_POS, targetXLeft));
				rangeRight = Math.min(maxLeft, Math.max(RANGE_SEL_INIT_RIGHT_POS, targetXRight));
				range.style.left = rangeLeft + 'px';
				range.style.right = rangeRight + 'px';
				onChange(sliceDataSet(rangeLeft, rangeRight));
				lastX = pageX;
			};

			const rangeMouseMove = (e: MouseEvent): void => {
				if (isBtnPressed(e)) {
					rangeMove(e.pageX);
				} else {
					document.removeEventListener('mousemove', rangeMouseMove);
				}
			};

			const rangeTouchMove = (e: TouchEvent): void => {
				if (e.target === range) {
					rangeMove(e.touches[0].pageX);
				}
			};

			const startHandlerMouseMove = (e: MouseEvent): void => {
				if (isBtnPressed(e)) {
					startHandlerResize(e.pageX);
				} else {
					document.removeEventListener('mousemove', startHandlerMouseMove);
				}
			};

			const startHandlerTouchMove = (e: TouchEvent): void => {
				if (e.target === startHandler) {
					startHandlerResize(e.touches[0].pageX);
				}
			};

			const endHandlerMouseMove = (e: MouseEvent): void => {
				if (isBtnPressed(e)) {
					endHandlerResize(e.pageX);
				} else {
					document.removeEventListener('mousemove', endHandlerMouseMove);
				}
			};

			const endHandlerTouchMove = (e: TouchEvent): void => {
				if (e.target === endHandler) {
					endHandlerResize(e.touches[0].pageX);
				}
			};

			const registerMouseMoveListener = (cb: (e: MouseEvent) => void) => (e: MouseEvent) => {
				e.preventDefault();
				e.stopPropagation();
				lastX = e.pageX;
				document.addEventListener('mousemove', cb);
			};

			const registerTouchMoveListener = (cb: (e: TouchEvent) => void) => (e: TouchEvent) => {
				e.preventDefault();
				e.stopPropagation();
				lastX = e.touches[0]?.pageX || 0;
				document.addEventListener('touchmove', cb);
			};

			const rangeMouseClick = registerMouseMoveListener(rangeMouseMove);
			const rangeTouchStart = registerTouchMoveListener(rangeTouchMove);
			const startHandlerMouseClick = registerMouseMoveListener(startHandlerMouseMove);
			const startHandlerTouchStart = registerTouchMoveListener(startHandlerTouchMove);
			const endHandlerMouseClick = registerMouseMoveListener(endHandlerMouseMove);
			const endHandlerTouchStart = registerTouchMoveListener(endHandlerTouchMove);

			chart.addEventListener('wheel', rangeResize);
			range.addEventListener('mousedown', rangeMouseClick);
			range.addEventListener('touchstart', rangeTouchStart);
			startHandler.addEventListener('mousedown', startHandlerMouseClick);
			startHandler.addEventListener('touchstart', startHandlerTouchStart);
			endHandler.addEventListener('mousedown', endHandlerMouseClick);
			endHandler.addEventListener('touchstart', endHandlerTouchStart);

			return () => {
				chart.removeEventListener('wheel', rangeResize);
				range.removeEventListener('mousedown', rangeMouseClick);
				range.removeEventListener('touchstart', rangeTouchStart);
				startHandler.removeEventListener('mousedown', startHandlerMouseClick);
				startHandler.removeEventListener('touchstart', startHandlerTouchStart);
				endHandler.removeEventListener('mousedown', endHandlerMouseClick);
				endHandler.removeEventListener('touchstart', endHandlerTouchStart);

				document.removeEventListener('mousemove', rangeMouseMove);
				document.removeEventListener('touchmove', rangeTouchMove);
				document.removeEventListener('mousemove', startHandlerMouseMove);
				document.removeEventListener('touchmove', startHandlerTouchMove);
				document.removeEventListener('mousemove', endHandlerMouseMove);
				document.removeEventListener('touchmove', endHandlerTouchMove);
			};
		}
	}, [chartRef, dataSet, onChange]);

	return (
		<ChartPreview data-testid="chart-range-selector" ref={chartPreviewRef}>
			<Chart
				role="img"
				aria-label={t('aria.chart')}
				data-testid="currency-rate-chart-preview"
				config={{
					type: 'line',
					data: {
						labels: dataSet.map((_, idx) => idx),
						datasets: [
							{
								data: dataSet.map(({ open }) => open),
								fill: true,
								borderWidth: 2,
								borderColor: theme.colors.data.blue4,
								backgroundColor: '#469cbe5c',
								tension: 0.4,
								pointHoverBorderWidth: 0,
								pointHoverBackgroundColor: 'transparent',
								spanGaps: true
							}
						]
					},
					options: {
						maintainAspectRatio: false,
						animation: false,
						scales: {
							xAxis: {
								display: false
							},
							yAxis: {
								display: false
							}
						},
						elements: {
							point: {
								radius: 0
							}
						},
						plugins: {
							legend: {
								display: false
							},
							tooltip: {
								enabled: false
							}
						}
					}
				}}
			/>
			<RangeSelector ref={rangeRef}>
				<RangeHandler
					role="button"
					ref={startHandlerRef}
					aria-label={t('aria.pickFromRange')}
				/>
				<RangeHandler
					role="button"
					ref={endHandlerRef}
					aria-label={t('aria.pickToRange')}
				/>
			</RangeSelector>
		</ChartPreview>
	);
};
