import React, { useCallback, useEffect, useState } from 'react';
import { DataView, FormState, FxRate, Range } from 'types';
import {
	Flex,
	MarketCloseDisclaimer,
	MidRateDisclaimer,
	Section,
	TermsAndConditionsDisclaimer,
	Notification,
	NetworkErrorNotification,
	Button,
	FeatureToggle
} from 'components/common';
import { scrollTo } from 'utils';
import { event } from 'utils/logger';
import { isWeekend } from 'utils/date';
import { useTranslation } from 'utils/i18next';
import { flashAnimation, styled } from 'utils/styles';
import { EVENTS, TealiumEvent } from 'utils/logging/types';
import { CurrencyRateForm } from './CurrencyRateForm';
import { CurrencyRateResult } from './CurrencyRateResult';
import { CurrencyRateChart } from './CurrencyRateChart';
import { CurrencyRateChartLegend } from './CurrencyRateChartLegend';
import { CurrencyRateWidget } from './CurrencyRateWidget';
import { CurrencyRateChartTable } from './CurrencyRateChartTable';
import { CurrencyRateRange } from './CurrencyRateRange';
import { CurrencyRateViewOptions } from './CurrencyRateViewOptions';
import { RateAlertModal } from 'components/modals';
import { AlertIcon } from './icons';
import { HiddenForExternalApps } from './common/HiddenForExternalApps';

interface CurrencyRateConverterProps {
	fxRate: FxRate | null;
	formState: FormState;
	rateRange: Range | null;
	isPending: boolean;
	error: string;
	flash: boolean;
	onFormStateChange: (formState: Partial<FormState>) => void;
	onRateRangeChange: (range: Range) => void;
	onErrorCancel: () => void;
	onDataRefresh: () => Promise<void>;
	sendEvent: (eventName: string | TealiumEvent, data: unknown | undefined | null) => void;
}

const FlashyFlex = styled(Flex)(flashAnimation);

export const CurrencyRateConverter = (props: CurrencyRateConverterProps) => {
	const {
		fxRate,
		formState,
		rateRange,
		isPending,
		error,
		flash,
		onFormStateChange,
		onRateRangeChange,
		onErrorCancel,
		onDataRefresh,
		sendEvent = event
	} = props;
	const [dataView, setDataView] = useState<DataView>('CHART');
	const [noDataError, setNoDataError] = useState<string>('');
	const [rateAlertModal, setRateAlertModal] = React.useState<boolean>(false);
	const [isMidRateDisclaimerExpanded, setIsMidRateDisclaimerExpanded] = useState<boolean>(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (!isPending && fxRate?.dataSet.length === 0) {
			setNoDataError(t('errors.dataNotAvailable'));
		} else {
			setNoDataError('');
		}
	}, [isPending, fxRate, t]);

	const handleSetDataView = (view: DataView) => {
		setDataView(view);
		sendEvent(EVENTS.PREDEFINED_EVENTS.PICK_VIEW(view), { view });
	};

	const handleFormChange = useCallback(
		(state: Partial<FormState>) => {
			onRateRangeChange(Range.MONTH);
			onFormStateChange(state);
		},
		[onFormStateChange, onRateRangeChange]
	);

	return (
		<>
			<Section
				id="currency-rate-converter"
				data-testid="currency-rate-converter"
				data-nosnippet
			>
				<CurrencyRateForm
					state={formState}
					sendEvent={sendEvent}
					onChange={handleFormChange}
				/>
				{error && (
					<NetworkErrorNotification onCancel={onErrorCancel} onRefresh={onDataRefresh}>
						{error}
					</NetworkErrorNotification>
				)}
				{noDataError && (
					<Notification type="info" onCancel={() => setNoDataError('')}>
						{noDataError}
					</Notification>
				)}
				<FlashyFlex
					flash={flash}
					flexWrap={['wrap', 'wrap', 'nowrap']}
					justifyContent="space-between"
					gridColumnGap={['large', 'large', 'xxlarge', '200px']}
					gridRowGap="7px"
					mt="large"
					mb="large"
				>
					<CurrencyRateResult
						amount={formState.amount}
						fxRate={fxRate}
						isLoading={isPending}
						expandDisclaimer={() => {
							setIsMidRateDisclaimerExpanded(true);
							scrollTo('mid-rate-disclaimer');
						}}
					/>
					<Flex
						flexDirection="column"
						gridGap="medium"
						margin={['auto', 'none']}
						width={['100%', 'auto']}
					>
						<CurrencyRateWidget
							range={rateRange}
							fxRate={fxRate}
							isLoading={isPending}
						/>
						<CurrencyRateChartLegend fxRate={fxRate} isLoading={isPending} />
					</Flex>
				</FlashyFlex>
				{isWeekend() && <MarketCloseDisclaimer />}
				<Flex justifyContent={['center', 'space-between']} flexWrap="wrap" gridGap="xlarge">
					<CurrencyRateRange
						name="currency-rate-range"
						value={rateRange}
						onChange={onRateRangeChange}
					/>
					<Flex gridGap="small">
						<FeatureToggle
							envs={[
								'currencyzone-internal-uat',
								'currencyzone-external-uat',
								'currencyzone-internal-prd'
							]}
						>
							<HiddenForExternalApps>
								<Button
									variant="primary"
									size="small"
									onClick={() => setRateAlertModal(true)}
								>
									<AlertIcon size="small" mr="small" verticalAlign="middle" />
									Set rate alert
								</Button>
							</HiddenForExternalApps>
						</FeatureToggle>
						<CurrencyRateViewOptions view={dataView} setView={handleSetDataView} />
					</Flex>
				</Flex>
				{dataView === 'CHART' ? (
					<CurrencyRateChart flash={flash} fxRate={fxRate} isLoading={isPending} />
				) : (
					<CurrencyRateChartTable flash={flash} fxRate={fxRate} isLoading={isPending} />
				)}

				<Flex justifyContent="space-between" gridGap="xlarge">
					<div>
						<MidRateDisclaimer
							expanded={isMidRateDisclaimerExpanded}
							onClick={() => setIsMidRateDisclaimerExpanded(expanded => !expanded)}
						/>
						<TermsAndConditionsDisclaimer />
					</div>
				</Flex>
			</Section>
			<FeatureToggle
				envs={[
					'currencyzone-internal-uat',
					'currencyzone-external-uat',
					'currencyzone-internal-prd'
				]}
			>
				{rateAlertModal && fxRate && rateRange && (
					<RateAlertModal
						onClose={() => setRateAlertModal(false)}
						fxRate={fxRate}
						rateRange={rateRange}
					/>
				)}
			</FeatureToggle>
		</>
	);
};
