import React, { CanvasHTMLAttributes } from 'react';
import { Rate } from 'types';
import { useTheme } from 'utils';
import { Chart } from 'components/common';

interface LiveExchangeRateChartProps extends CanvasHTMLAttributes<HTMLCanvasElement> {
	dataSet: Rate[];
	type: 'positive' | 'negative';
}

export const LiveExchangeRateChart = React.memo((props: LiveExchangeRateChartProps) => {
	const { dataSet, type, ...canvasProps } = props;
	const theme = useTheme();

	return (
		<Chart
			{...canvasProps}
			config={{
				type: 'line',
				data: {
					labels: dataSet.map((_, idx) => idx),
					datasets: [
						{
							data: dataSet.map(({ open }) => open),
							fill: false,
							borderWidth: 2,
							borderColor:
								type === 'negative' ? theme.colors.red2 : theme.colors.rag.green,
							tension: 0.4,
							pointHoverBorderWidth: 0,
							pointHoverBackgroundColor: 'transparent',
							spanGaps: true
						}
					]
				},
				options: {
					maintainAspectRatio: false,
					animation: false,
					scales: {
						xAxis: {
							display: false
						},
						yAxis: {
							display: false
						}
					},
					elements: {
						point: {
							radius: 0
						}
					},
					plugins: {
						legend: {
							display: false
						},
						tooltip: {
							enabled: false
						}
					}
				}
			}}
		/>
	);
});

LiveExchangeRateChart.displayName = 'LiveExchangeRateChart';
