import React, { AnchorHTMLAttributes } from 'react';
import { PREDEFINED_EVENTS, SendEvent, TealiumEvent } from 'utils/logging/types';
import { event } from 'utils/logger';
import { useTranslation } from 'utils/i18next';
import { Modal, ModalFooter, ModalHeading } from 'components/modals';
import { LinkButton } from './LinkButton';

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	sendEvent?: SendEvent;
	onClick?: () => void;
	withPopup?: boolean;
	trackingEvent?: TealiumEvent;
}

export const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(
	function ExternalLink(props, ref) {
		const {
			href,
			sendEvent = event,
			children,
			onClick,
			withPopup = true,
			trackingEvent,
			...anchorProps
		} = props;
		const [isModalVisible, setModalVisibility] = React.useState<boolean>(false);
		const { t } = useTranslation();

		const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (trackingEvent) {
				sendEvent(trackingEvent);
			}

			if (onClick) {
				onClick();
			}

			if (withPopup) {
				e.stopPropagation();
				e.preventDefault();
				setModalVisibility(true);
			}
		};

		const handleConfirm = () => {
			if (trackingEvent) {
				sendEvent(
					PREDEFINED_EVENTS.EXTERNAL_NAVIGATE_MODAL_CONFIRM(trackingEvent.event_content)
				);
			}

			setModalVisibility(false);
		};

		const handleCancel = () => {
			if (trackingEvent) {
				sendEvent(
					PREDEFINED_EVENTS.EXTERNAL_NAVIGATE_MODAL_CANCEL(trackingEvent.event_content)
				);
			}

			setModalVisibility(false);
		};

		return (
			<>
				<a ref={ref} href={href} onClick={handleClick} {...anchorProps}>
					{children}
				</a>
				{isModalVisible && (
					<Modal maxWidth={'500px'}>
						<ModalHeading onClose={handleCancel}>
							{t('externalLinkPopup.title')}
						</ModalHeading>
						<p>{t('externalLinkPopup.description')}</p>
						<ModalFooter>
							<LinkButton
								variant="primary"
								href={href}
								target="_blank"
								rel="noreferrer"
								aria-label={t('externalLinkPopup.btnLabel')}
								onClick={handleConfirm}
							>
								{t('externalLinkPopup.btnLabel')}
							</LinkButton>
						</ModalFooter>
					</Modal>
				)}
			</>
		);
	}
);
