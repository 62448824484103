import React from 'react';
import { SVG, SVGProps } from './SVG';

export const RefreshIcon = (props: SVGProps) => (
	<SVG focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path
				className="cls-2"
				d="M12.2,7H18V1.2L16.8,0V4.54A9,9,0,1,0,18,9H16.8a7.84,7.84,0,1,1-.7-3.2H11Z"
			/>
		</g>
	</SVG>
);
