import React from 'react';
import { SVG, SVGProps } from './SVG';

export const ChevronIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<polygon points="17 4.2 9 12.19 1 4.2 1 5.89 9 13.89 17 5.89 17 4.2" />
		</g>
	</SVG>
);
