import React from 'react';
import { useTranslation } from 'utils/i18next';
import { Flex } from 'components/common';
import { GlobalIcon, YesThick } from 'components/icons';
import { components, OptionProps, Select, SelectOption, SingleValueProps } from './Select';

interface LanguageSelectProps {
	withBorder?: boolean;
}

const SingleValue = (props: SingleValueProps<SelectOption, false>) => (
	<components.SingleValue {...props}>
		<Flex gridGap="small" alignItems="center">
			<GlobalIcon size="small" />
			{props.data.label}
		</Flex>
	</components.SingleValue>
);

const Option = (props: OptionProps<SelectOption, false>) => (
	<components.Option {...props}>
		<Flex gridGap="large" justifyContent="space-between" alignItems="center">
			{props.label}
			{props.isSelected && <YesThick size="small" />}
		</Flex>
	</components.Option>
);

export const LanguageSelect = (props: LanguageSelectProps) => {
	const { withBorder = true } = props;
	const { i18n, t } = useTranslation();

	const languageOptions: SelectOption[] = [
		{ value: 'en-GB', label: t('languages.en') },
		{ value: 'es', label: t('languages.es') },
		{ value: 'fr', label: t('languages.fr') },
		{ value: 'tr', label: t('languages.tr') },
		{ value: 'zh-HK', label: t('languages.zh-HK') },
		{ value: 'zh-CN', label: t('languages.zh-CN') }
	];
	const getSelectedLanguage = () => languageOptions.find(({ value }) => value === i18n.language);

	return (
		<Select
			name="language"
			placeholder="Choose language"
			instanceId="select-language-instance"
			value={getSelectedLanguage()}
			options={languageOptions}
			onChange={value => value && i18n.changeLanguage(value.value)}
			menuPlacement="auto"
			styles={
				withBorder
					? {}
					: {
							control: (provided, state) => ({
								...provided,
								border: 'none',
								height: '100%',
								boxShadow: 'none',
								cursor: 'pointer',
								outline: state.isFocused ? '1px solid auto' : 'none',
								outlineStyle: state.isFocused ? 'auto' : 'none',
								backgroundColor: 'none'
							}),
							indicatorSeparator: () => ({
								display: 'none'
							})
						}
			}
			components={{
				SingleValue,
				Option
			}}
		/>
	);
};
