import { breakpoints, styled } from 'utils/styles';
import {
	flexbox,
	grid,
	space,
	SpaceProps,
	FlexboxProps,
	GridProps,
	layout,
	LayoutProps
} from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

export const Grid = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: ${breakpoints[breakpoints.length - 1]};
`;

interface FlexProps extends FlexboxProps, GridProps, SpaceProps, LayoutProps {}

export const Flex = styled('div', { shouldForwardProp })<FlexProps>`
	display: flex;
	${flexbox};
	${grid};
	${space};
	${layout};
`;
