import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ExternalLink, Header, Page } from 'components/common';

export const Privacy = () => (
	<Page>
		<Helmet title="Privacy" />
		<Header>
			<h1>Privacy policy</h1>
		</Header>
		<div>
			<p>
				For general users of this website: please{' '}
				<Link to="/privacy-policy-general-users">click here</Link> to view the Privacy
				Policy in relation to the data collected and used by this website.
			</p>
			<p>
				For clients (and connected persons of clients) of HSBC Global Banking and Markets:
				please{' '}
				<ExternalLink href={'https://www.gbm.hsbc.com/privacy-policy-connected-parties'}>
					click here
				</ExternalLink>{' '}
				to view the Privacy Notice for HSBC Bank plc, as defined by the General Data
				Protection Regulation (GDPR) (EU) 2016/679.
			</p>
		</div>
	</Page>
);

export default Privacy;
