import React, { InputHTMLAttributes } from 'react';
import { css, mq, styled } from 'utils/styles';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
}

export const RadioGroup = styled.fieldset`
	padding: 0;
	border: none;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
`;

const StyledRadioButton = styled.div(
	({ theme: { fontSize, fontWeight, colors } }) => css`
		display: inline-block;

		input[type='radio'] {
			opacity: 0;
			position: absolute;
		}

		input[type='radio'] + label {
			cursor: pointer;
			display: inline-block;
			font-weight: ${fontWeight.bold};
			border-radius: 18px;
			white-space: nowrap;
			border: 1px solid ${colors.grey5};
			line-height: 1.4;
			padding: 8px 12px;
			background: none;

			&:hover {
				background: ${colors.grey1};
			}

			${mq({
				fontSize: [fontSize.xsmall, fontSize.small]
			})}
		}

		input[type='radio']:focus + label {
			outline: 1px solid;
		}

		input[type='radio']:checked + label {
			background: ${colors.grey2};
			text-decoration: underline;
		}
	`
);

export const RadioButton = ({ id, label, ...props }: RadioButtonProps) => (
	<StyledRadioButton>
		<input type="radio" id={id} {...props} />
		<label htmlFor={id}>{label}</label>
	</StyledRadioButton>
);
