import React from 'react';
import { useTranslation } from 'utils/i18next';
import { RefreshIcon } from 'components/icons';
import { Notification, NotificationProps } from './Notification';
import { Button } from './Button';
import { Flex } from './Grid';

interface NetworkErrorNotificationProps extends NotificationProps {
	onRefresh: () => Promise<void>;
}

export const NetworkErrorNotification = (props: NetworkErrorNotificationProps) => {
	const { children, onRefresh, ...notificationProps } = props;
	const { t } = useTranslation();

	return (
		<Notification type="error" {...notificationProps}>
			<Flex
				data-testid="network-err-notification-container"
				justifyContent="space-between"
				alignItems="center"
				flex={1}
			>
				{children}
				<Button
					lineHeight={1.5}
					textAlign="center"
					variant="icon"
					onClick={onRefresh}
					aria-label={t('aria.refreshData')}
				>
					<RefreshIcon verticalAlign="middle"></RefreshIcon>
				</Button>
			</Flex>
		</Notification>
	);
};
