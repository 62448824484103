import React from 'react';
import { FxRate } from 'types';
import { Trans, useTranslation } from 'utils/i18next';
import { calcRateChange } from 'utils/currency';
import { formatTime } from 'utils/date';
import { Change } from 'components/common/Change';

interface RateAlertDiffProps {
	fxRate: FxRate;
	rateAlert: string;
	className?: string;
	testId?: string;
}

const Diff = ({ fxRate, rateAlert }: RateAlertDiffProps) => {
	const currentRate = fxRate.latest?.open;
	const rates = !!(currentRate && rateAlert);

	return rates ? <Change change={calcRateChange(currentRate, parseFloat(rateAlert))} /> : '--';
};

const CurrentRate = ({ fxRate, rateAlert }: RateAlertDiffProps) => {
	const { t } = useTranslation();
	const ts = fxRate.latest?.timestamp;
	const currentRate = fxRate.latest?.open;
	const rates = !!(currentRate && rateAlert);

	return (
		<>
			{rates && ts && formatTime(ts) + ': '}
			<b>{rates ? currentRate : t('labels.na')}</b>
		</>
	);
};

export const RateAlertDiff = ({ className, testId, ...props }: RateAlertDiffProps) => (
	<div className={className} data-testid={testId}>
		<Trans i18nKey="rateAlert.modal.diff">
			This alert is <Diff {...props} /> away from the rate as of
			<CurrentRate {...props} />
		</Trans>
	</div>
);
