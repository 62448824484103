import React from 'react';
import { css, mq, styled } from 'utils/styles';

interface TagProps {
	children: string;
}

const StyledTag = styled.span(
	({ theme: { colors, fontSize } }) => css`
		background-color: ${colors.rag.blue};
		color: ${colors.white};
		font-size: ${fontSize.xsmall};
		padding: 4px;
		border-radius: 4px;
		margin-top: 5px;
		margin-left: 10px;
		display: block;
		float: right;

		${mq({
			fontSize: [10, 12],
			lineHeight: ['8px', '10px', '12px', '14px']
		})};
	`
);

export const Tag = ({ children }: TagProps) => <StyledTag>{children}</StyledTag>;
