import React from 'react';
import { SVG, SVGProps } from './SVG';

export const MenuIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g id="menu">
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M1,17H17V15.8H1ZM1,1V2.2H17V1ZM1,9.6H17V8.4H1Z" />
		</g>
	</SVG>
);
