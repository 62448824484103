import React from 'react';
import config from 'config';
import { SendEvent } from 'utils/logging/types';
import { useTranslation } from 'utils/i18next';
import { ExternalLink } from 'components/common';
import { FxPodcast } from './FxPodcast';
import { ResearchSection } from './ResearchSection';
import { css, styled } from '../../utils/styles';

const PodcastsList = styled.ul(
	({ theme: { space } }) => css`
		margin: ${space.large} 0;
		padding: 0;
		list-style: none;
	`
);

const PodcastsListItem = styled.li(
	({ theme: { space } }) => css`
		margin: ${space.medium} 0;
	`
);

interface FxPodcastsProps {
	sendEvent?: SendEvent;
}

export const FxPodcasts = (props: FxPodcastsProps) => {
	// todo: add events tracking
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { sendEvent } = props;
	const { t } = useTranslation('research');

	return (
		<ResearchSection heading={t('podcasts.heading')}>
			<p>{t('podcasts.description')} </p>
			<PodcastsList>
				{config.podcasts.gbm.map(({ link, name }) => (
					<PodcastsListItem key={name}>
						<FxPodcast link={link} name={name} />
					</PodcastsListItem>
				))}
			</PodcastsList>
			<PodcastsList>
				{config.podcasts.streamingPlatforms.map(
					({ link, accessKey, srcImg, width, height, alt }) => (
						<li key={link}>
							<ExternalLink href={link} accessKey={accessKey}>
								<img alt={alt} width={width} height={height} src={srcImg} />
							</ExternalLink>
						</li>
					)
				)}
			</PodcastsList>
		</ResearchSection>
	);
};
