import React, { useEffect, useState } from 'react';
import { styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';

interface LoaderProps {
	textDelay?: number;
}

const StyledLoader = styled.div`  
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 5;

    div {
      border: 4px solid ${({ theme }) => theme.colors.background};
      border-radius: 50%;
      border-top: 4px solid ${({ theme }) => theme.colors.data.blue4};
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
    }
	
	span {
		margin-top: ${({ theme }) => theme.space.medium};
		font-size: ${({ theme }) => theme.fontSize.small};
	}

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
`;

export const Loader = (props: LoaderProps) => {
	const { textDelay } = props;
	const [showText, setShowText] = useState<boolean>(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (textDelay) {
			const timeout = setTimeout(() => setShowText(true), textDelay);
			return () => clearTimeout(timeout);
		}
	});

	return (
		<StyledLoader>
			<div />
			{showText && <span>{t('loadingText')}</span>}
		</StyledLoader>
	);
};
