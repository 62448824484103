import React, { useEffect, useState } from 'react';
import { CurrencySelect } from 'components/form';
import { Flex } from 'components/common';
import { styled, visuallyHidden } from 'utils/styles';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { event } from 'utils/logger';
import { useTranslation } from 'utils/i18next';
import { Currency } from 'types';

interface LiveExchangeRatesNewRowProps {
	addRate: (ccyPair: string) => void;
	sendEvent?: SendEvent;
}

const StyledFlex = styled(Flex)`
	& > div {
		min-width: 250px;
	}
`;

const HiddenLabel = styled.label(visuallyHidden);

export const LiveExchangeRatesNewRow = (props: LiveExchangeRatesNewRowProps) => {
	const { addRate, sendEvent = event } = props;
	const [ccyFrom, setCcyFrom] = useState<Currency | null>(null);
	const [ccyTo, setCcyTo] = useState<Currency | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (ccyFrom && ccyTo) {
			addRate(ccyFrom + ccyTo);
			sendEvent(EVENTS.PREDEFINED_EVENTS.ADD_TO_FAV(ccyFrom + ccyTo));
		}
	}, [addRate, ccyFrom, ccyTo, sendEvent]);

	return (
		<StyledFlex
			data-testid="live-exchange-rates-new-row"
			px={['none', 'medium']}
			py="medium"
			flexDirection={['column', 'row']}
			gridGap={1}
			flex={1}
		>
			<HiddenLabel htmlFor="live-exchange-rates-new-row-ccy-from-select">
				{t('labels.ccyChooseFrom')}
			</HiddenLabel>
			<CurrencySelect
				name="live-exchange-rates-new-row-ccy-from-select"
				inputId="live-exchange-rates-new-row-ccy-from-select"
				placeholder={t('labels.ccyChooseFrom')}
				type="ccyFrom"
				value={ccyFrom}
				excludeValue={ccyTo}
				onChange={e => {
					e && setCcyFrom(e.value);
					e && sendEvent(EVENTS.PREDEFINED_EVENTS.ADD_TO_FAV_FROM_CCY(e.value));
				}}
			/>
			<HiddenLabel htmlFor="live-exchange-rates-new-row-ccy-to-select">
				{t('labels.ccyChooseTo')}
			</HiddenLabel>
			<CurrencySelect
				name="live-exchange-rates-new-row-ccy-to-select"
				inputId="live-exchange-rates-new-row-ccy-to-select"
				placeholder={t('labels.ccyChooseTo')}
				type="ccyTo"
				value={ccyTo}
				excludeValue={ccyFrom}
				onChange={e => {
					e && setCcyTo(e.value);
					e && sendEvent(EVENTS.PREDEFINED_EVENTS.ADD_TO_FAV_TO_CCY(e.value));
				}}
			/>
		</StyledFlex>
	);
};
