import React from 'react';
import { SVG, SVGProps } from './SVG';

export const NewWindow = (props: SVGProps) => (
	<SVG focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M15.8,15.8H2.2V2.2H9V1H1V17H17V9H15.8ZM10.46,8.39,15.8,3.05V7.86L17,6.66V1H11.34l-1.2,1.2H15L9.61,7.54Z" />
		</g>
	</SVG>
);
