import React from 'react';
import { SVG, SVGProps } from './SVG';

export const CloseIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<polygon points="16.42 2.42 15.58 1.58 9 8.15 2.42 1.58 1.58 2.42 8.15 9 1.58 15.58 2.42 16.42 9 9.85 15.58 16.42 16.42 15.58 9.85 9 16.42 2.42" />
		</g>
	</SVG>
);
